@font-face {
    font-family: "Saans TRIAL";
    src: url("../../assets/font/SaansTRIAL-Bold.eot");
    src: local("Saans TRIAL Bold"), local("SaansTRIAL-Bold"), url("../../assets/font/SaansTRIAL-Bold.eot?#iefix") format("embedded-opentype"),
        url("../../assets/font/SaansTRIAL-Bold.woff2") format("woff2"), url("../../assets/font/SaansTRIAL-Bold.woff") format("woff"),
        url("../../assets/font/SaansTRIAL-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Saans TRIAL";
    src: url("../../assets/font/SaansTRIAL-SemiBold.eot");
    src: local("Saans TRIAL SemiBold"), local("SaansTRIAL-SemiBold"),
        url("../../assets/font/SaansTRIAL-SemiBold.eot?#iefix") format("embedded-opentype"),
        url("../../assets/font/SaansTRIAL-SemiBold.woff2") format("woff2"), url("../../assets/font/SaansTRIAL-SemiBold.woff") format("woff"),
        url("../../assets/font/SaansTRIAL-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Saans TRIAL";
    src: url("../../assets/font/SaansTRIAL-Medium.eot");
    src: local("Saans TRIAL Medium"), local("SaansTRIAL-Medium"), url("../../assets/font/SaansTRIAL-Medium.eot?#iefix") format("embedded-opentype"),
        url("../../assets/font/SaansTRIAL-Medium.woff2") format("woff2"), url("../../assets/font/SaansTRIAL-Medium.woff") format("woff"),
        url("../../assets/font/SaansTRIAL-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Saans TRIAL";
    src: url("../../assets/font/SaansTRIAL-Regular.eot");
    src: local("Saans TRIAL Regular"), local("SaansTRIAL-Regular"), url("../../assets/font/SaansTRIAL-Regular.eot?#iefix") format("embedded-opentype"),
        url("../../assets/font/SaansTRIAL-Regular.woff2") format("woff2"), url("../../assets/font/SaansTRIAL-Regular.woff") format("woff"),
        url("../../assets/font/SaansTRIAL-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Mardoto";
    src: url("../../assets/font/Mardoto-Medium.woff2") format("woff2"), url("../../assets/font/Mardoto-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

.service-cards_container {
    max-width: 1920px;
    margin: 0 70px;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #d9d9d9;
    font-family: "Saans TRIAL";
}
.service-cards {
    display: flex;
    flex-direction: column;
    max-width: 60%;
}

.service-cards-header,
.service-cards-header_icons {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.service-cards-header {
    margin-bottom: 40px;
}

.service-cards-header span {
    font-size: 21px;
    font-weight: 600;
    line-height: 1.135;
    margin-right: 10px;
}

.service-cards-header_icons svg {
    animation: rotatePauseSvg 2.5s infinite;
}

@keyframes rotatePauseSvg {
    0% {
        transform: rotate(0);
    }
    20% {
        transform: rotate(0);
    }
    40% {
        transform: rotate(-90deg);
    }
    60% {
        transform: rotate(-90deg);
    }
    80% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(0);
    }
}

.service-cards_container.arm-language .service-cards-title h1{
    font-size: 64px;
    line-height: 1;
}

.service-cards-title {
    margin-bottom: 70px;
}
.service-cards-title h1 {
    font-size: 72px;
    font-weight: 500;
    line-height: 1.135;
}

@media (min-width: 576px) and (max-width: 1920px) {
    .service-cards_container {
        max-width: 100vw;
        margin: 0 3.65vw;
    }

    .service-cards-header {
        margin-bottom: 2.08vw;
    }

    .service-cards-header span {
        font-size: 1.09vw;
        margin-right: 0.52vw;
    }

    .service-cards-header_icons svg {
        width: 0.63vw;
        height: 0.63vw;
    }

    .service-cards-title {
        margin-bottom: 3.65vw;
    }

    .service-cards-title h1 {
        font-size: 3.75vw;
    }


    .service-cards_container.arm-language .service-cards-title h1{
        font-size: 3.3vw;
    }
}

@media (max-width: 576px) {
    .service-cards_container {
        max-width: 139.81vw; /* 576px */
        margin: 0 4.85vw; /* 20px */
        display: flex;
        flex-direction: column;
        border-bottom: 0.24vw solid #d9d9d9; /* 1px */
    }

    .service-cards {
        display: flex;
        flex-direction: column;
        max-width: 100%;
    }

    .service-cards-header,
    .service-cards-header_icons {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
    }

    .service-cards-header {
        margin-bottom: 7.28vw; /* 30px */
    }

    .service-cards-header span {
        font-size: 4.37vw; /* 18px */
        font-weight: 600;
        line-height: 4.96vw; /* 20.43px */
        text-align: left;
        margin-right: 2.43vw; /* 10px */
    }

    .service-cards-header_icons svg {
        width: 2.43vw; /* 10px */
        height: 2.43vw; /* 10px */
        animation: rotatePauseSvg 2.5s infinite;
    }

    @keyframes rotatePauseSvg {
        0% {
            transform: rotate(0);
        }
        20% {
            transform: rotate(0);
        }
        40% {
            transform: rotate(-90deg);
        }
        60% {
            transform: rotate(-90deg);
        }
        80% {
            transform: rotate(0);
        }
        100% {
            transform: rotate(0);
        }
    }

    .service-cards-title {
        margin-bottom: 9.71vw; /* 40px */
    }

    .service-cards-title h1 {
        font-size: 10.19vw; /* 42px */
        font-weight: 500;
        line-height: 11.57vw; /* 47.68px */
        text-align: left;
    }

    .service-cards_container.arm-language .service-cards-title h1{
        font-size: 7.9vw;
    }

}
