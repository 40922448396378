@font-face {
    font-family: "Saans TRIAL";
    src: url("../../assets/font/SaansTRIAL-Bold.eot");
    src: local("Saans TRIAL Bold"), local("SaansTRIAL-Bold"), url("../../assets/font/SaansTRIAL-Bold.eot?#iefix") format("embedded-opentype"),
        url("../../assets/font/SaansTRIAL-Bold.woff2") format("woff2"), url("../../assets/font/SaansTRIAL-Bold.woff") format("woff"),
        url("../../assets/font/SaansTRIAL-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Saans TRIAL";
    src: url("../../assets/font/SaansTRIAL-SemiBold.eot");
    src: local("Saans TRIAL SemiBold"), local("SaansTRIAL-SemiBold"),
        url("../../assets/font/SaansTRIAL-SemiBold.eot?#iefix") format("embedded-opentype"),
        url("../../assets/font/SaansTRIAL-SemiBold.woff2") format("woff2"), url("../../assets/font/SaansTRIAL-SemiBold.woff") format("woff"),
        url("../../assets/font/SaansTRIAL-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Saans TRIAL";
    src: url("../../assets/font/SaansTRIAL-Medium.eot");
    src: local("Saans TRIAL Medium"), local("SaansTRIAL-Medium"), url("../../assets/font/SaansTRIAL-Medium.eot?#iefix") format("embedded-opentype"),
        url("../../assets/font/SaansTRIAL-Medium.woff2") format("woff2"), url("../../assets/font/SaansTRIAL-Medium.woff") format("woff"),
        url("../../assets/font/SaansTRIAL-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Saans TRIAL";
    src: url("../../assets/font/SaansTRIAL-Regular.eot");
    src: local("Saans TRIAL Regular"), local("SaansTRIAL-Regular"), url("../../assets/font/SaansTRIAL-Regular.eot?#iefix") format("embedded-opentype"),
        url("../../assets/font/SaansTRIAL-Regular.woff2") format("woff2"), url("../../assets/font/SaansTRIAL-Regular.woff") format("woff"),
        url("../../assets/font/SaansTRIAL-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Mardoto";
    src: url("../../assets/font/Mardoto-Medium.woff2") format("woff2"), url("../../assets/font/Mardoto-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

.vacancy-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.vacancy-modal {
    background: #f2f2f2;
    border-radius: 30px;
    width: 100%;
    max-width: 823px;
    max-height: 754px;
    overflow-y: auto;
    padding: 0;
    position: relative;
}

.vacancy_modal-content {
    padding: 0 112px 0 60px;
}

.empty_div {
    height: 100px;
    background-color: #f2f2f2;
    width: 100%;
    position: sticky;
    top: 0;
}
.empty_div-footer {
    height: 100px;
    background-color: #f2f2f2;
    width: 100%;
    position: sticky;
    bottom: 0;
}

.vacancy-modal {
    scrollbar-width: none;
    -ms-overflow-style: none;
}
.vacancy-modal::-webkit-scrollbar {
    display: none;
}

.vacancy-modal-close {
    position: absolute;
    top: 60px;
    right: 60px;
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
}

.vacancy-modal-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
}

.vacancy-modal-header span {
    font-size: 14px;
    font-weight: 500;
    line-height: 15.89px;
    text-align: left;
    /**/
    padding: 6px 12px;
    border: 1px solid black;
    border-radius: 30px;
    margin-right: 10px;
}

.vacancy-modal h2 {
    font-size: 48px;
    font-weight: 500;
    line-height: 1.135;
    text-align: left;
    margin-bottom: 10px;
}

.vacancy-modal p {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.135;
    text-align: left;
}

.vacancy-modal h3 {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.135;
    text-align: left;
    margin-bottom: 15px;
}

.vacancy-modal ul {
    list-style: disc;
    margin-left: 15px;
}

.vacancy-modal ul li {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.135;
    text-align: left;
    margin-bottom: 10px;
}

.vacancy-modal__section {
    margin-bottom: 30px;
}

.vacancy-modal__section:last-child {
    margin-bottom: 0;
}

.vacancy_modal-content ul {
    margin-bottom: 30px;
}

.vacancy-modal_footer {
    font-size: 24px;
    font-weight: 500;
    line-height: 1.135;
    text-align: left;
    display: inline-block;
}

.vacancy-modal_footer a {
    font-size: 24px;
    font-weight: 500;
    line-height: 1.135;
    text-align: left;
    text-decoration: underline;
    text-underline-offset: 5px;
    color: #000;
}

@media (min-width: 576px) and (max-width: 1920px) {
    .vacancy-modal-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
    }

    .vacancy-modal {
        background: #f2f2f2;
        border-radius: 1.56vw;
        width: 100%;
        max-width: 42.86vw;
        max-height: 39.27vw;
        overflow-y: auto;
        padding: 0;
        position: relative;
    }

    .vacancy_modal-content {
        padding: 0 5.83vw 0 3.125vw;
    }

    .empty_div {
        height: 5.2vw;
        background-color: #f2f2f2;
        width: 100%;
        position: sticky;
        top: 0;
    }
    .empty_div-footer {
        height: 5.2vw;
        background-color: #f2f2f2;
        width: 100%;
        position: sticky;
        bottom: 0;
    }

    .vacancy-modal {
        scrollbar-width: none;
        -ms-overflow-style: none;
    }
    .vacancy-modal::-webkit-scrollbar {
        display: none;
    }

    .vacancy-modal-close {
        position: absolute;
        top: 3.125vw;
        right: 3.125vw;
        background: transparent;
        border: none;
        outline: none;
        cursor: pointer;
    }

    .vacancy-modal-header {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 1vw;
    }

    .vacancy-modal-header span {
        font-size: 0.72vw;
        font-weight: 500;
        line-height: 1.135;
        text-align: left;
        /**/
        padding: 0.31vw 0.625vw;
        border: 0.05vw solid black;
        border-radius: 1.56vw;
        margin-right: 0.52vw;
    }

    .vacancy-modal h2 {
        font-size: 2.5vw;
        font-weight: 500;
        line-height: 1.135;
        text-align: left;
        margin-bottom: 0.52vw;
    }

    .vacancy-modal p {
        font-size: 0.83vw;
        font-weight: 500;
        line-height: 1.135;
        text-align: left;
    }

    .vacancy-modal h3 {
        font-size: 0.83vw;
        font-weight: 600;
        line-height: 1.135;
        text-align: left;
        margin-bottom: 0.78vw;
    }

    .vacancy-modal ul {
        list-style: disc;
        margin-left: 0.78vw;
    }

    .vacancy-modal ul li {
        font-size: 0.83vw;
        font-weight: 500;
        line-height: 1.135;
        text-align: left;
        margin-bottom: 0.52vw;
    }

    .vacancy-modal__section,
    .vacancy_modal-content ul {
        margin-bottom: 1.56vw;
    }

    .vacancy-modal__section:last-child {
        margin-bottom: 0;
    }

    .vacancy-modal_footer {
        font-size: 1.25vw;
        font-weight: 500;
        line-height: 1.135;
        text-align: left;
    }

    .vacancy-modal_footer a {
        font-size: 1.25vw;
        font-weight: 500;
        line-height: 1.135;
        text-align: left;
        text-decoration: underline;
        text-underline-offset: 0.26vw;
        color: #000;
    }

    .vacancy-modal-close,
    .vacancy-modal-close svg {
        width: 1.66vw;
        height: 1.66vw;
    }
}

@media (max-width: 576px) {
    .vacancy-modal-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
    }

    .vacancy-modal {
        background: #f2f2f2;
        border-radius: 2.42vw;
        width: 100%;
        max-width: 90%;
        max-height: 165.04vw;
        overflow-y: auto;
        padding: 0;
        position: relative;
    }

    .vacancy_modal-content {
        padding: 0 7.28vw;
    }

    .empty_div {
        height: 24.27vw;
        background-color: #f2f2f2;
        width: 100%;
        position: sticky;
        top: 0;
    }
    .empty_div-footer {
        height: 24.27vw;
        background-color: #f2f2f2;
        width: 100%;
        position: sticky;
        bottom: 0;
    }

    .vacancy-modal {
        scrollbar-width: none;
        -ms-overflow-style: none;
    }
    .vacancy-modal::-webkit-scrollbar {
        display: none;
    }

    .vacancy-modal-close {
        position: absolute;
        top: 7.28vw;
        right: 7.28vw;
        background: transparent;
        border: none;
        outline: none;
        cursor: pointer;
    }

    .vacancy-modal-header {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 4.85vw;
    }

    .vacancy-modal-header span {
        font-size: 2.91vw;
        font-weight: 500;
        line-height: 1.135;
        text-align: left;
        /**/
        padding: 1.45vw 2.9vw;
        border: 0.24vw solid black;
        border-radius: 7.28vw;
        margin-right: 1.21vw;
    }

    .vacancy-modal h2 {
        font-size: 7.76vw;
        font-weight: 500;
        line-height: 1.135;
        text-align: left;
        margin-bottom: 2.42vw;
    }

    .vacancy-modal p {
        font-size: 3.88vw;
        font-weight: 500;
        line-height: 1.135;
        text-align: left;
    }

    .vacancy-modal h3 {
        font-size: 3.88vw;
        font-weight: 600;
        line-height: 1.135;
        text-align: left;
        margin-bottom: 3.6vw;
    }

    .vacancy-modal ul {
        list-style: disc;
        margin-left: 3.6vw;
    }

    .vacancy-modal ul li {
        font-size: 3.88vw;
        font-weight: 500;
        line-height: 1.135;
        text-align: left;
        margin-bottom: 10px;
    }

    .vacancy-modal__section,
    .vacancy_modal-content ul {
        margin-bottom: 7.28vw;
    }

    .vacancy-modal__section:last-child {
        margin-bottom: 0;
    }

    .vacancy-modal_footer {
        font-size: 5.82vw;
        font-weight: 500;
        line-height: 1.135;
        text-align: left;
    }

    .vacancy-modal_footer a {
        font-size: 5.82vw;
        font-weight: 500;
        line-height: 1.135;
        text-align: left;
        text-decoration: underline;
        text-underline-offset: 1.21vw;
        color: #000;
    }

    .vacancy-modal-close,
    .vacancy-modal-close svg {
        width: 4.36vw;
        height: 4.36vw;
    }
}
