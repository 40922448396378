@font-face {
    font-family: "Saans TRIAL";
    src: url("../../assets/font/SaansTRIAL-Bold.eot");
    src: local("Saans TRIAL Bold"), local("SaansTRIAL-Bold"), url("../../assets/font/SaansTRIAL-Bold.eot?#iefix") format("embedded-opentype"),
        url("../../assets/font/SaansTRIAL-Bold.woff2") format("woff2"), url("../../assets/font/SaansTRIAL-Bold.woff") format("woff"),
        url("../../assets/font/SaansTRIAL-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Saans TRIAL";
    src: url("../../assets/font/SaansTRIAL-SemiBold.eot");
    src: local("Saans TRIAL SemiBold"), local("SaansTRIAL-SemiBold"),
        url("../../assets/font/SaansTRIAL-SemiBold.eot?#iefix") format("embedded-opentype"),
        url("../../assets/font/SaansTRIAL-SemiBold.woff2") format("woff2"), url("../../assets/font/SaansTRIAL-SemiBold.woff") format("woff"),
        url("../../assets/font/SaansTRIAL-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Saans TRIAL";
    src: url("../../assets/font/SaansTRIAL-Medium.eot");
    src: local("Saans TRIAL Medium"), local("SaansTRIAL-Medium"), url("../../assets/font/SaansTRIAL-Medium.eot?#iefix") format("embedded-opentype"),
        url("../../assets/font/SaansTRIAL-Medium.woff2") format("woff2"), url("../../assets/font/SaansTRIAL-Medium.woff") format("woff"),
        url("../../assets/font/SaansTRIAL-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Saans TRIAL";
    src: url("../../assets/font/SaansTRIAL-Regular.eot");
    src: local("Saans TRIAL Regular"), local("SaansTRIAL-Regular"), url("../../assets/font/SaansTRIAL-Regular.eot?#iefix") format("embedded-opentype"),
        url("../../assets/font/SaansTRIAL-Regular.woff2") format("woff2"), url("../../assets/font/SaansTRIAL-Regular.woff") format("woff"),
        url("../../assets/font/SaansTRIAL-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Mardoto";
    src: url("../../assets/font/Mardoto-Medium.woff2") format("woff2"), url("../../assets/font/Mardoto-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}


.share {
    margin: 0 70px 160px 70px;
    max-width: 1920px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    font-family: "Saans TRIAL";
}

.share-header,
.share-header_icons {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.share-header {
    margin-bottom: 40px;
}

.share-header span {
    font-size: 21px;
    font-weight: 600;
    line-height: 1.135;
    margin-right: 10px;
}

.share-header_icons svg {
    animation: rotatePauseSvg 2.5s infinite;
}

@keyframes rotatePauseSvg {
    0% {
        transform: rotate(0);
    }
    20% {
        transform: rotate(0);
    }
    40% {
        transform: rotate(-90deg);
    }
    60% {
        transform: rotate(-90deg);
    }
    80% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(0);
    }
}

.share-title {
    max-width: 60%;
    margin-bottom: 20px;
}
.share-title h1 {
    font-size: 72px;
    font-weight: 500;
    line-height: 1.135;
}

.share-slider {
    max-width: 100%;
    overflow: hidden;
    padding: 40px 0 0 0;
}

.share-pagination_container {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: flex-end;
}

.swiper-pagination-bullet {
    width: 8px;
    height: 8px;
    background-color: #6a6a6d;
    opacity: 1;
    cursor: pointer;
    transition: all 0.3s ease;
}

.swiper-pagination-bullet-active {
    width: 35px;
    height: 8px;
    background-color: #000;
    border-radius: 50px;
}

.shareholder-card {
    display: flex;
    flex-direction: column;
    max-width: 280px;
}

.shareholder-card_img {
    margin-bottom: 10px;
    width: 100%;
    height: 184px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f7f7f7;
    border: none;
    border-radius: 10px;
}

.shareholder-card img {
    display: block;
    object-fit: contain;
}

.shareholder-card_desc {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    height: 128px;
    padding: 20px;
    background-color: #f7f7f7;
    border: none;
    border-radius: 10px;
}

.shareholder-card h3 {
    font-size: 18px;
    line-height: 1.135;
    font-weight: 500;
    margin-bottom: 10px;
}

.shareholder-card p {
    font-size: 16px;
    line-height: 1.135;
    font-weight: 400;
}

.shareholder-group {
    display: flex;
    justify-content: flex-start;
}

.shareholder-card {
    margin-right: 25px;
}

.shareholder-card:last-child {
    margin-right: 0;
}

.share-slider_mobile {
    display: none;
}

@media (min-width: 576px) and (max-width: 1920px) {
    .share {
        margin: 0 3.65vw 8.33vw 3.65vw;
        max-width: 100vw;
    }

    .share-header {
        margin-bottom: 2.08vw;
    }

    .share-header span {
        font-size: 1.09vw;
        margin-right: 0.52vw;
    }

    .share-title {
        margin-bottom: 1.04vw;
    }
    .share-title h1 {
        font-size: 3.75vw;
    }

    .share-slider {
        padding: 2.08vw 0 0 0;
    }

    .swiper-pagination-bullet {
        width: 0.42vw;
        height: 0.42vw;
    }

    .swiper-pagination-bullet-active {
        width: 1.82vw;
        height: 0.42vw;
        border-radius: 2.6vw;
    }

    .shareholder-card {
        max-width: 14.58vw;
    }

    .shareholder-card_img {
        margin-bottom: 0.52vw;
        height: 9.58vw;
        border-radius: 0.52vw;
    }

    .shareholder-card img {
        width: 50%;
    }

    .shareholder-card_desc {
        height: 6.67vw;
        padding: 1.04vw;
        border-radius: 0.52vw;
    }

    .shareholder-card h3 {
        font-size: 0.94vw;
        margin-bottom: 0.52vw;
    }

    .shareholder-card p {
        font-size: 0.83vw;
    }

    .shareholder-card {
        margin-right: 1.3vw;
    }

    .shareholder-card:last-child {
        margin-right: 0;
    }

    .share-header_icons svg {
        width: 0.62vw;
        height: 0.62vw;
    }
}

.share-navigation{
    display: none;
}

@media (max-width: 576px) {
    .share-slider,
    .share-pagination_container {
        display: none;
    }

    .share-slider_mobile {
        display: block;
    }

    .mySwiperShare {
        display: block;
        width: 100%;
        height: 100%;
    }

    .mySwiperShare .swiper-slide {
        display: flex;
        width: 100%;
    }

    /**/

    .share-navigation {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin: 0 0 4.85vw 0;
    }

    .share-control {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .share-control button:first-child {
        margin-right: 3.64vw;
    }

    .share-control button {
        position: relative;
        width: 7.76vw;
        height: 7.76vw;
        border: 0.24vw solid #000;
        border-radius: 50%;
        overflow: hidden;
        cursor: pointer;
        transition: transform 0.3s ease;
        display: flex;
        align-items: center;
        justify-content: center;
        background: transparent;
    }

    .share-control button svg {
        width: 3.39vw;
        height: 3.39vw;
    }

    .share-control button::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: #d9d9d9;
        top: 100%; /* Start below the button */
        left: 0;
        transition: top 0.5s ease;
        z-index: 0;
    }

    .share-control button:hover::before {
        top: 0;
    }

    .svg-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        transition: transform 0.5s ease;
    }

    .share-control button.share-prev:hover .svg-wrapper {
        transform: translateX(-3.85vw);
    }
    .share-control button.share-prev .svg-wrapper {
        transform: translateX(3.85vw);
    }

    .share-control button.share-next:hover .svg-wrapper {
        transform: translateX(3.85vw);
    }

    .share-control button.share-next .svg-wrapper {
        transform: translateX(-3.85vw);
    }

    .share-control button.share-prev .svg-wrapper:first-child {
        margin-right: 3.85vw;
    }
    .share-control button.share-next .svg-wrapper:last-child {
        margin-left: 3.85vw;
    }

    /**/

    .share {
        margin: 0 4.85vw 24.27vw 4.85vw;
        max-width: 576px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }

    .share-header,
    .share-header_icons {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
    }

    .share-header {
        margin-bottom: 7.28vw;
    }

    .share-header span {
        font-size: 4.36vw;
        font-weight: 600;
        line-height: 1.135;
        margin-right: 2.42vw;
    }

    .share-header_icons svg {
        width: 2.42vw;
        height: 2.2vw;
        animation: rotatePauseSvg 2.5s infinite;
    }

    @keyframes rotatePauseSvg {
        0% {
            transform: rotate(0);
        }
        20% {
            transform: rotate(0);
        }
        40% {
            transform: rotate(-90deg);
        }
        60% {
            transform: rotate(-90deg);
        }
        80% {
            transform: rotate(0);
        }
        100% {
            transform: rotate(0);
        }
    }

    .share-title {
        max-width: 100%;
        margin-bottom: 9.7vw;
    }
    .share-title h1 {
        font-size: 10.19vw;
        font-weight: 500;
        line-height: 1.135;
    }

    .share-slider {
        max-width: 100%;
        overflow: hidden;
        padding: 0 0 0 0;
    }

    .shareholder-card {
        display: flex;
        flex-direction: column;
        max-width: 67.96vw;
    }

    .shareholder-card_img {
        margin-bottom: 2.42vw;
        width: 100%;
        height: 184px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f7f7f7;
        border: none;
        border-radius: 2.42vw;
    }

    .shareholder-card img {
        display: block;
        object-fit: contain;
    }

    .shareholder-card_desc {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 100%;
        height: 31.06vw;
        padding: 4.85vw;
        background-color: #f7f7f7;
        border: none;
        border-radius: 2.42vw;
    }

    .shareholder-card h3 {
        font-size: 4.36vw;
        font-weight: 500;
        line-height: 1.135;
        margin-bottom: 2.42vw;
    }

    .shareholder-card p {
        font-size: 3.88vw;
        font-weight: 400;
        line-height: 1.135;
    }

    .shareholder-group {
        display: flex;
        justify-content: flex-start;
    }

    .shareholder-card {
        margin-right: 0;
    }

    .shareholder-card:last-child {
        margin-right: 0;
    }
}
