@font-face {
    font-family: "Saans TRIAL";
    src: url("../../assets/font/SaansTRIAL-Bold.eot");
    src: local("Saans TRIAL Bold"), local("SaansTRIAL-Bold"), url("../../assets/font/SaansTRIAL-Bold.eot?#iefix") format("embedded-opentype"),
        url("../../assets/font/SaansTRIAL-Bold.woff2") format("woff2"), url("../../assets/font/SaansTRIAL-Bold.woff") format("woff"),
        url("../../assets/font/SaansTRIAL-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Saans TRIAL";
    src: url("../../assets/font/SaansTRIAL-SemiBold.eot");
    src: local("Saans TRIAL SemiBold"), local("SaansTRIAL-SemiBold"), url("../../assets/font/SaansTRIAL-SemiBold.eot?#iefix") format("embedded-opentype"),
        url("../../assets/font/SaansTRIAL-SemiBold.woff2") format("woff2"), url("../../assets/font/SaansTRIAL-SemiBold.woff") format("woff"),
        url("../../assets/font/SaansTRIAL-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Saans TRIAL";
    src: url("../../assets/font/SaansTRIAL-Medium.eot");
    src: local("Saans TRIAL Medium"), local("SaansTRIAL-Medium"), url("../../assets/font/SaansTRIAL-Medium.eot?#iefix") format("embedded-opentype"),
        url("../../assets/font/SaansTRIAL-Medium.woff2") format("woff2"), url("../../assets/font/SaansTRIAL-Medium.woff") format("woff"),
        url("../../assets/font/SaansTRIAL-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Saans TRIAL";
    src: url("../../assets/font/SaansTRIAL-Regular.eot");
    src: local("Saans TRIAL Regular"), local("SaansTRIAL-Regular"), url("../../assets/font/SaansTRIAL-Regular.eot?#iefix") format("embedded-opentype"),
        url("../../assets/font/SaansTRIAL-Regular.woff2") format("woff2"), url("../../assets/font/SaansTRIAL-Regular.woff") format("woff"),
        url("../../assets/font/SaansTRIAL-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Mardoto";
    src: url("../../assets/font/Mardoto-Medium.woff2") format("woff2"), url("../../assets/font/Mardoto-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

.structure {
    margin: 200px 70px 0 70px;
    max-width: 1920px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    font-family: "Saans TRIAL";

    padding-bottom: 70px;
}

.structure-header,
.structure-header_icons {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.structure-header {
    margin-bottom: 40px;
}

.structure-header span {
    font-size: 21px;
    font-weight: 600;
    line-height: 1.135;
    margin-right: 10px;
}

.structure-header_icons svg {
    animation: rotatePauseSvg 2.5s infinite;
}

@keyframes rotatePauseSvg {
    0% {
        transform: rotate(0);
    }
    20% {
        transform: rotate(0);
    }
    40% {
        transform: rotate(-90deg);
    }
    60% {
        transform: rotate(-90deg);
    }
    80% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(0);
    }
}

.structure-title {
    max-width: 60%;
    margin-bottom: 70px;
}
.structure-title h1 {
    font-size: 72px;
    font-weight: 500;
    line-height: 1.135;
}

/**/
.structure-preview {
    width: 100%;
    height: auto;
    margin: 0 auto;
    display: block;
}

.structure-preview img {
    width: 100%;
    height: auto;
    object-fit: contain;
}

/**/

.structure-download {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.structure-download a {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /**/
    font-size: 21px;
    font-weight: 700;
    line-height: 23.84px;
    text-align: left;
    text-decoration-line: underline;
    text-decoration-style: solid;
    color: #000;
}

.structure-download a svg {
    width: 18px;
    height: 18px;
    margin-left: 5px;
}

@media (min-width: 576px) and (max-width: 1920px) {
    .structure {
        margin: 10.4vw 3.65vw 0 3.65vw;
        max-width: 100vw;
        padding-bottom: 3.64vw;
        border-bottom: 0.05vw solid #d9d9d9;
    }

    .structure-header {
        margin-bottom: 2.08vw;
    }

    .structure-header span {
        font-size: 1.09vw;
        margin-right: 0.52vw;
    }

    .structure-header_icons svg {
        width: 0.625vw;
        height: 0.625vw;
        animation: rotatePauseSvg 2.5s infinite;
    }

    .structure-title {
        margin-bottom: 3.64vw;
    }
    .structure-title h1 {
        font-size: 3.75vw;
    }

    /**/

    .structure-download a {
        font-size: 1.09vw;
        line-height: 1.135;
    }

    .structure-download a svg {
        width: 0.937vw;
        height: 0.937vw;
        margin-left: 2.6vw;
    }
}

@media (max-width: 576px) {
    .structure {
        margin: 24.27vw 4.85vw 0 4.85vw;
        max-width: 576px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding-bottom: 14.56vw;
        border-bottom: 0.24vw solid #d9d9d9;
    }

    .structure-header,
    .structure-header_icons {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
    }

    .structure-header {
        margin-bottom: 7.28vw;
    }

    .structure-header span {
        font-size: 4.36vw;
        font-weight: 600;
        line-height: 1.135;
        margin-right: 2.42vw;
    }

    .structure-header_icons svg {
        width: 2.42vw;
        height: 2.2vw;
        animation: rotatePauseSvg 2.5s infinite;
    }

    @keyframes rotatePauseSvg {
        0% {
            transform: rotate(0);
        }
        20% {
            transform: rotate(0);
        }
        40% {
            transform: rotate(-90deg);
        }
        60% {
            transform: rotate(-90deg);
        }
        80% {
            transform: rotate(0);
        }
        100% {
            transform: rotate(0);
        }
    }

    .structure-title {
        max-width: 100%;
        margin-bottom: 9.7vw;
    }
    .structure-title h1 {
        font-size: 6.94vw;
        font-weight: 500;
        line-height: 1.135;
    }

    /**/

    .structure-download a {
        font-size: 3.5vw;
        line-height: 1.135;
    }

    .structure-download a svg {
        width: 4vw;
        height: 4vw;
        margin-left: 1.2vw;
    }
}
