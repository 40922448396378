@font-face {
    font-family: "Saans TRIAL";
    src: url("../../assets/font/SaansTRIAL-Bold.eot");
    src: local("Saans TRIAL Bold"), local("SaansTRIAL-Bold"), url("../../assets/font/SaansTRIAL-Bold.eot?#iefix") format("embedded-opentype"),
        url("../../assets/font/SaansTRIAL-Bold.woff2") format("woff2"), url("../../assets/font/SaansTRIAL-Bold.woff") format("woff"),
        url("../../assets/font/SaansTRIAL-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Saans TRIAL";
    src: url("../../assets/font/SaansTRIAL-SemiBold.eot");
    src: local("Saans TRIAL SemiBold"), local("SaansTRIAL-SemiBold"),
        url("../../assets/font/SaansTRIAL-SemiBold.eot?#iefix") format("embedded-opentype"),
        url("../../assets/font/SaansTRIAL-SemiBold.woff2") format("woff2"), url("../../assets/font/SaansTRIAL-SemiBold.woff") format("woff"),
        url("../../assets/font/SaansTRIAL-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Saans TRIAL";
    src: url("../../assets/font/SaansTRIAL-Medium.eot");
    src: local("Saans TRIAL Medium"), local("SaansTRIAL-Medium"), url("../../assets/font/SaansTRIAL-Medium.eot?#iefix") format("embedded-opentype"),
        url("../../assets/font/SaansTRIAL-Medium.woff2") format("woff2"), url("../../assets/font/SaansTRIAL-Medium.woff") format("woff"),
        url("../../assets/font/SaansTRIAL-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Saans TRIAL";
    src: url("../../assets/font/SaansTRIAL-Regular.eot");
    src: local("Saans TRIAL Regular"), local("SaansTRIAL-Regular"), url("../../assets/font/SaansTRIAL-Regular.eot?#iefix") format("embedded-opentype"),
        url("../../assets/font/SaansTRIAL-Regular.woff2") format("woff2"), url("../../assets/font/SaansTRIAL-Regular.woff") format("woff"),
        url("../../assets/font/SaansTRIAL-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Mardoto";
    src: url("../../assets/font/Mardoto-Medium.woff2") format("woff2"), url("../../assets/font/Mardoto-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

.tenders {
    margin: 220px 70px 30px 70px;
    padding-bottom: 130px;
    max-width: 1920px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    font-family: "Saans TRIAL";
}

.tenders-header,
.tenders-header_icons {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.tenders-header {
    margin-bottom: 30px;
}

.tenders-header span {
    font-size: 21px;
    font-weight: 600;
    line-height: 1.135;
    margin-right: 10px;
}

.tenders-header_icons svg {
    width: 12px;
    height: 12px;
    animation: rotatePauseSvg 2.5s infinite;
}

@keyframes rotatePauseSvg {
    0% {
        transform: rotate(0);
    }
    20% {
        transform: rotate(0);
    }
    40% {
        transform: rotate(-90deg);
    }
    60% {
        transform: rotate(-90deg);
    }
    80% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(0);
    }
}

.tenders-title {
    max-width: 40%;
    margin-bottom: 20px;
}
.tenders-title h1 {
    font-size: 72px;
    font-weight: 500;
    line-height: 1.135;
    text-align: left;
}

#tenders-pagination_container,
#tenders-pagination_container_second {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 40px;
}

.swiper-pagination-bullet {
    width: 8px;
    height: 8px;
    background-color: #6a6a6d;
    opacity: 1;
    cursor: pointer;
    transition: all 0.3s ease;
}

.swiper-pagination-bullet-active {
    width: 35px;
    height: 8px;
    background-color: #000;
    border-radius: 50px;
}

/*card*/

.tenders-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #f2f2f2;
    border: none;
    border-radius: 10px;
    height: 100%;
    width: 100%;
    padding: 20px;
    cursor: pointer;
}

.tenders-card h3 {
    font-size: 24px;
    font-weight: 500;
    line-height: 1.135;
    text-align: left;
    margin-bottom: 30px;
    max-width: 90%;
}

.tenders-card_description {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 30px;
    max-width: 90%;
}

.tenders-card_description span {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.135;
    text-align: left;
    margin-bottom: 15px;
}

.tenders-card_description p {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.135;
    text-align: left;
    overflow: hidden;
    width: 100%;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}

.tenders-card_info-container {
    max-width: 90%;
}

.tenders-card_info,
.tenders-card_deliver p {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.135;
    text-align: left;
    margin-bottom: 10px;
}

.tenders-card_info span,
.tenders-card_deliver span {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.135;
    text-align: left;
}

.tenders-card_deliver {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.tenders-card_deliver span {
    white-space: nowrap;
    margin-right: 10px;
}

.tenders-tags_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: end;
    width: 100%;
    margin-top: 35px;
}

.tenders-tags_container svg {
    width: 42px;
    height: 42px;
}

.tenders-tags {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.tenders-tag {
    font-size: 14px;
    font-weight: 500;
    line-height: 15.89px;
    text-align: left;
    /**/
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 12px;
    border: 1px solid #000;
    border-radius: 30px;
    /**/
    margin-right: 10px;
}

.tenders-tag:last-child {
    margin-right: 0;
}

/*swiper*/

.tendersSwiper,
.sliderTenders {
    width: 100%;
    height: 100%;
}

.tendersSwiper .swiper-slide,
.sliderTenders .swiper-slide {
    width: 100%;
    height: 100%;
}

.tenders-navigation {
    background-color: #e6e6e6;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px;
    border-radius: 30px;
    max-width: fit-content;
}

.tenders-prev,
.tenders-next {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 15px;
    border-radius: 30px;
    border: none;
    outline: none;

    font-size: 18px;
    font-weight: 500;
    line-height: 1.135;
    color: #8e8e8e;
    background-color: #f2f2f2;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;
    z-index: 1;
}

.tenders-prev {
    margin-right: 10px;
}

.tenders-prev::before,
.tenders-next::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.3s ease;
    z-index: -1;
}

.tenders-prev.swiper-button-disabled::before {
    transform: scaleX(1);
    transform-origin: left;
}

.tenders-next::before {
    transform-origin: right;
}

.tenders-next.swiper-button-disabled::before {
    transform: scaleX(1);
    transform-origin: right;
}

.tenders-prev.swiper-button-disabled,
.tenders-next.swiper-button-disabled {
    color: #fff;
}

.tenders.arm-language .tenders-title {
    max-width: 43%;
}

.tenders-first-navigation,
.tenders-second-navigation {
    display: none;
}

@media (min-width: 576px) and (max-width: 1920px) {
    .tenders {
        margin: 11.45vw 3.64vw 1.56vw 3.64vw;
        padding-bottom: 6.77vw;
    }

    .tenders-header {
        margin-bottom: 1.56vw;
    }

    .tenders-header span {
        font-size: 1.09vw;
        margin-right: 0.52vw;
    }

    .tenders-header_icons svg {
        width: 0.625vw;
        height: 0.625vw;
    }

    .tenders-title {
        margin-bottom: 1.04vw;
    }

    .tenders-title h1 {
        font-size: 3.75vw;
    }

    #tenders-pagination_container,
    #tenders-pagination_container_second {
        margin-bottom: 2.08vw;
    }

    .swiper-pagination-bullet {
        width: 0.41vw;
        height: 0.41vw;
    }

    .swiper-pagination-bullet-active {
        width: 1.82vw;
        height: 0.41vw;
        border-radius: 2.6vw;
    }

    /*card*/

    .tenders-card {
        border-radius: 0.52vw;
        padding: 1.04vw;
    }

    .tenders-card h3 {
        font-size: 1.25vw;
        margin-bottom: 1.56vw;
    }

    .tenders-card_description {
        margin-bottom: 1.56vw;
    }

    .tenders-card_description span {
        font-size: 0.833vw;
        margin-bottom: 0.78vw;
    }

    .tenders-card_description p {
        font-size: 0.833vw;
    }

    .tenders-card_info,
    .tenders-card_deliver p {
        font-size: 0.833vw;
        margin-bottom: 0.52vw;
    }

    .tenders-card_info span,
    .tenders-card_deliver span {
        font-size: 0.833vw;
    }

    .tenders-card_deliver span {
        margin-right: 0.52vw;
    }

    .tenders-tags_container {
        margin-top: 1.822vw;
    }

    .tenders-tags_container svg {
        width: 2.187vw;
        height: 2.187vw;
    }

    .tenders-tag {
        font-size: 0.72vw;
        line-height: 0.82vw;
        padding: 0.26vw 0.625vw;
        border: 0.05vw solid #000;
        border-radius: 1.56vw;
        margin-right: 0.52vw;
    }

    .tenders-tag:last-child {
        margin-right: 0;
    }

    /*swiper*/

    .tenders-navigation {
        padding: 0.26vw;
        border-radius: 1.56vw;
    }

    .tenders-prev,
    .tenders-next {
        padding: 0.41vw 0.78vw;
        border-radius: 1.56vw;
        font-size: 0.9375vw;
    }

    .tenders-prev {
        margin-right: 0.52vw;
    }
}

@media (max-width: 576px) {
    .tenders {
        margin: 24.27vw 4.85vw 0 4.85vw;
        padding-bottom: 14.56vw;
        max-width: 576px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        font-family: "Saans TRIAL";
    }

    .tenders-header,
    .tenders-header_icons {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
    }

    .tenders-header {
        margin-bottom: 7.28vw;
    }

    .tenders-header span {
        font-size: 4.36vw;
        font-weight: 600;
        line-height: 1.135;
        margin-right: 2.42vw;
    }

    .tenders-header_icons svg {
        width: 2.42vw;
        height: 2.42vw;
        animation: rotatePauseSvg 2.5s infinite;
    }

    .tenders.arm-language .tenders-title {
        max-width: 100%;
    }

    @keyframes rotatePauseSvg {
        0% {
            transform: rotate(0);
        }
        20% {
            transform: rotate(0);
        }
        40% {
            transform: rotate(-90deg);
        }
        60% {
            transform: rotate(-90deg);
        }
        80% {
            transform: rotate(0);
        }
        100% {
            transform: rotate(0);
        }
    }

    .tenders-title {
        max-width: 100%;
        margin-bottom: 9.7vw;
    }

    .tenders-title h1 {
        font-size: 10.19vw;
        font-weight: 500;
        line-height: 1.135;
        text-align: left;
    }

    .tenders.arm-language .tenders-title h1 {
        font-size: 7.9vw;
    }

    #tenders-pagination_container,
    #tenders-pagination_container_second {
        display: none;
    }

    .tenders-first-navigation {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin: 0 0 4.85vw 0;
    }

    .tenders-first-control {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .tenders-first-control button:first-child {
        margin-right: 3.64vw;
    }

    .tenders-first-control button {
        position: relative;
        width: 7.76vw;
        height: 7.76vw;
        border: 0.24vw solid #000;
        border-radius: 50%;
        overflow: hidden;
        cursor: pointer;
        transition: transform 0.3s ease;
        display: flex;
        align-items: center;
        justify-content: center;
        background: transparent;
    }

    .tenders-first-control button svg {
        width: 3.39vw;
        height: 3.39vw;
    }

    .tenders-first-control button::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: #d9d9d9;
        top: 100%; /* Start below the button */
        left: 0;
        transition: top 0.5s ease;
        z-index: 0;
    }

    .tenders-first-control button:hover::before {
        top: 0;
    }

    .svg-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        transition: transform 0.5s ease;
    }

    .tenders-first-control button.tenders-first-prev:hover .svg-wrapper {
        transform: translateX(-3.85vw);
    }
    .tenders-first-control button.tenders-first-prev .svg-wrapper {
        transform: translateX(3.85vw);
    }

    .tenders-first-control button.tenders-first-next:hover .svg-wrapper {
        transform: translateX(3.85vw);
    }

    .tenders-first-control button.tenders-first-next .svg-wrapper {
        transform: translateX(-3.85vw);
    }

    .tenders-first-control button.tenders-first-prev .svg-wrapper:first-child {
        margin-right: 3.85vw;
    }
    .tenders-first-control button.tenders-first-next .svg-wrapper:last-child {
        margin-left: 3.85vw;
    }

    .tenders-second-navigation {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin: 0 0 4.85vw 0;
    }

    .tenders-second-control {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .tenders-second-control button:first-child {
        margin-right: 3.64vw;
    }

    .tenders-second-control button {
        position: relative;
        width: 7.76vw;
        height: 7.76vw;
        border: 0.24vw solid #000;
        border-radius: 50%;
        overflow: hidden;
        cursor: pointer;
        transition: transform 0.3s ease;
        display: flex;
        align-items: center;
        justify-content: center;
        background: transparent;
    }

    .tenders-second-control button svg {
        width: 3.39vw;
        height: 3.39vw;
    }

    .tenders-second-control button::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: #d9d9d9;
        top: 100%; /* Start below the button */
        left: 0;
        transition: top 0.5s ease;
        z-index: 0;
    }

    .tenders-second-control button:hover::before {
        top: 0;
    }

    .svg-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        transition: transform 0.5s ease;
    }

    .tenders-second-control button.tenders-second-prev:hover .svg-wrapper {
        transform: translateX(-3.85vw);
    }
    .tenders-second-control button.tenders-second-prev .svg-wrapper {
        transform: translateX(3.85vw);
    }

    .tenders-second-control button.tenders-second-next:hover .svg-wrapper {
        transform: translateX(3.85vw);
    }

    .tenders-second-control button.tenders-second-next .svg-wrapper {
        transform: translateX(-3.85vw);
    }

    .tenders-second-control button.tenders-second-prev .svg-wrapper:first-child {
        margin-right: 3.85vw;
    }
    .tenders-second-control button.tenders-second-next .svg-wrapper:last-child {
        margin-left: 3.85vw;
    }
    /*card*/

    /*card*/

    .tenders-card {
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: #f2f2f2;
        border: none;
        border-radius: 2.42vw;
        height: 100%;
        width: 100%;
        padding: 4.85vw;
        cursor: pointer;
    }

    .tenders-card h3 {
        font-size: 5.09vw;
        font-weight: 500;
        line-height: 1.135;
        text-align: left;
        margin-bottom: 4.85vw;
        max-width: 100%;
    }

    .tenders-card_description {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-bottom: 4.85vw;
        max-width: 100%;
    }

    .tenders-card_description span {
        font-size: 3.88vw;
        font-weight: 500;
        line-height: 1.135;
        text-align: left;
        margin-bottom: 3.64vw;
    }

    .tenders-card_description p {
        font-size: 3.88vw;
        font-weight: 400;
        line-height: 1.135;
        text-align: left;
        overflow: hidden;
        width: 100%;
        display: -webkit-box;
        -webkit-line-clamp: 7;
        -webkit-box-orient: vertical;
    }

    .tenders-card_info-container {
        max-width: 100%;
    }

    .tenders-card_info,
    .tenders-card_deliver p {
        font-size: 3.88vw;
        font-weight: 400;
        line-height: 1.135;
        text-align: left;
        margin-bottom: 2.42vw;
    }

    .tenders-card_info span,
    .tenders-card_deliver span {
        font-size: 3.88vw;
        font-weight: 500;
        line-height: 1.135;
        text-align: left;
    }

    .tenders-card_deliver {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
    }

    .tenders-card_deliver span {
        white-space: nowrap;
        margin-right: 2.42vw;
    }

    .tenders-tags_container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: end;
        width: 100%;
        margin-top: 4.85vw;
    }

    .tenders-tags_container svg {
        width: 7.76vw;
        height: 7.76vw;
    }

    .tenders-tags {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        flex-wrap: wrap;
        align-items: center;
    }

    .tenders-tag {
        font-size: 2.91vw;
        font-weight: 500;
        line-height: 1.135;
        text-align: left;
        /**/
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1.21vw 2.91vw;
        border: 0.24vw solid #000;
        border-radius: 7.28vw;
        /**/
        margin-right: 1.21vw;
    }

    .tenders-tag:first-child {
        margin-bottom: 1.21vw;
        margin-right: 6vw;
    }

    .tenders-tag:last-child {
        margin-right: 0;
    }
    /**/

    .tendersSwiper,
    .sliderTenders {
        width: 100%;
        height: 100%;
    }

    .tendersSwiper .swiper-slide,
    .sliderTenders .swiper-slide {
        width: 100%;
        height: 100%;
    }

    .tenders-navigation {
        background-color: #e6e6e6;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 1.21vw;
        border-radius: 7.28vw;
        max-width: fit-content;
        margin-bottom: 4.85vw;
    }

    .tenders-prev {
        margin-right: 2.42vw;
    }

    .tenders-prev,
    .tenders-next {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1.94vw 3.64vw;
        border-radius: 6.9vw;
        border: none;
        outline: none;

        font-size: 4.36vw;
        font-weight: 500;
        line-height: 1.135;
        color: #8e8e8e;
        background-color: #f2f2f2;
        cursor: pointer;
        transition: all 0.3s ease;
        position: relative;
        overflow: hidden;
        z-index: 1;
    }

    .tenders-prev::before,
    .tenders-next::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #000;
        transform: scaleX(0);
        transform-origin: left;
        transition: transform 0.3s ease;
        z-index: -1;
    }

    .tenders-prev.swiper-button-disabled::before {
        transform: scaleX(1);
        transform-origin: left;
    }

    .tenders-next::before {
        transform-origin: right;
    }

    .tenders-next.swiper-button-disabled::before {
        transform: scaleX(1);
        transform-origin: right;
    }

    .tenders-prev.swiper-button-disabled,
    .tenders-next.swiper-button-disabled {
        color: #fff;
    }
}
