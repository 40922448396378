@font-face {
    font-family: "Saans TRIAL";
    src: url("../../assets/font/SaansTRIAL-Bold.eot");
    src: local("Saans TRIAL Bold"), local("SaansTRIAL-Bold"), url("../../assets/font/SaansTRIAL-Bold.eot?#iefix") format("embedded-opentype"),
        url("../../assets/font/SaansTRIAL-Bold.woff2") format("woff2"), url("../../assets/font/SaansTRIAL-Bold.woff") format("woff"),
        url("../../assets/font/SaansTRIAL-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Saans TRIAL";
    src: url("../../assets/font/SaansTRIAL-SemiBold.eot");
    src: local("Saans TRIAL SemiBold"), local("SaansTRIAL-SemiBold"),
        url("../../assets/font/SaansTRIAL-SemiBold.eot?#iefix") format("embedded-opentype"),
        url("../../assets/font/SaansTRIAL-SemiBold.woff2") format("woff2"), url("../../assets/font/SaansTRIAL-SemiBold.woff") format("woff"),
        url("../../assets/font/SaansTRIAL-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Saans TRIAL";
    src: url("../../assets/font/SaansTRIAL-Medium.eot");
    src: local("Saans TRIAL Medium"), local("SaansTRIAL-Medium"), url("../../assets/font/SaansTRIAL-Medium.eot?#iefix") format("embedded-opentype"),
        url("../../assets/font/SaansTRIAL-Medium.woff2") format("woff2"), url("../../assets/font/SaansTRIAL-Medium.woff") format("woff"),
        url("../../assets/font/SaansTRIAL-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Mardoto";
    src: url("../../assets/font/Mardoto-Medium.woff2") format("woff2"), url("../../assets/font/Mardoto-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

.services-container {
    margin: 160px 70px;
    max-width: 1920px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    font-family: "Saans TRIAL";
}

.services-header,
.services-header_icons {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.services-header {
    margin-bottom: 40px;
}

.services-header span {
    font-size: 21px;
    font-weight: 600;
    margin-right: 10px;
}

.services-header_icons svg {
    animation: rotatePauseSvg 2.5s infinite;
}

@keyframes rotatePauseSvg {
    0% {
        transform: rotate(0);
    }
    20% {
        transform: rotate(0);
    }
    40% {
        transform: rotate(-90deg);
    }
    60% {
        transform: rotate(-90deg);
    }
    80% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(0);
    }
}

.services-title {
    max-width: 60%;
    margin-bottom: 70px;
}

.services-container.arm-language .services-title h1 {
    font-size: 64px;
    line-height: 1.135;
}

.services-title h1 {
    font-size: 72px;
    font-weight: 500;
}

.services-main {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 100px 100px;
    background-color: #000;
    border: none;
    border-radius: 30px;
    height: 830px;
}

.services-block {
    width: 40%;
}

.services-block h2 {
    font-size: 48px;
    font-weight: 500;
    color: #fff;
    margin-bottom: 50px;
}

.services-list {
    border-top: 1px solid #333;
    margin-top: 20px;
}

.service-item {
    border-bottom: 1px solid #333;
    padding: 20px 0;
}

.service-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    margin-bottom: 20px;
}

.service-header h3 {
    font-size: 24px;
    font-weight: 500;
    color: #fff;
}

.service-header svg {
    transition: transform 0.3s ease;
}

.service-header svg.rotate {
    transform: rotate(45deg);
}

.rotate-icon .rotatable-rect {
    transform: rotate(-90deg);
    transform-origin: center;
    transition: transform 0.3s ease;
}

.rotatable-rect {
    transform-origin: center;
    transition: transform 0.3s ease;
}

.service-description-wrapper {
    overflow: hidden;
    transition: max-height 0.3s ease, opacity 0.3s ease;
}

.service-description p {
    font-size: 18px;
    font-weight: 400;
    color: #c3c3c3;
    margin-bottom: 20px;
}

/**/

.service-desc_links {
    max-width: 200px;
    height: 21px;
    overflow: hidden;
    position: relative;
    cursor: pointer;
}

.read-more {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: baseline;
    color: #adf233;
    font-weight: 500;
    text-decoration: none;
    transition: transform 0.5s ease, opacity 0.5s ease;
}

.read-more.primary {
    transform: translateX(0);
    opacity: 1;
}

.read-more.secondary {
    transform: translateX(-100%);
    opacity: 0;
}

.service-desc_links:hover .read-more.primary {
    transform: translateX(100%);
    opacity: 0;
}

.service-desc_links:hover .read-more.secondary {
    transform: translateX(0);
    opacity: 1;
}

.read-more svg {
    margin-left: 8px;
    transition: transform 0.3s ease, fill 0.3s ease;
    fill: #adf233;
}

.read-more:hover svg {
    transform: translateX(5px);
    fill: #fff;
}

/**/

.graphic-element_container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 35%;
}

.graphic-element {
    width: 100%;
    height: 100%;
    max-height: 394.12px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
}

.graphic-element svg {
    max-width: 196.58px;
    width: 100%;
    height: auto;
    flex: 50%;
    transform: rotateY(180deg);
}

.glow-effect {
    fill: transparent;
    transition: fill 0.3s ease, filter 0.3s ease;
}

.glow-effect.active {
    filter: drop-shadow(0 0 3px #adf233) drop-shadow(0 0 3px #adf233) drop-shadow(0 0 10px #adf233) drop-shadow(0 0 15px #adf233);
    fill: #adf233;
    transition: fill 0.3s ease, filter 0.3s ease;
}

@media (min-width: 576px) and (max-width: 1920px) {
    .services-container {
        margin: 8.33vw 3.65vw;
        max-width: 100vw;
    }

    .services-header {
        margin-bottom: 1.04vw;
    }

    .services-header span {
        font-size: 1.09vw;
        margin-right: 0.52vw;
    }

    .services-title {
        margin-bottom: 3.65vw;
    }

    .services-title h1 {
        font-size: 3.75vw;
    }

    .services-main {
        padding: 5.21vw 5.21vw;
        border-radius: 1.56vw;
        height: 43.23vw;
    }

    .services-block h2 {
        font-size: 2.5vw;
        margin-bottom: 2.6vw;
    }

    .services-list {
        margin-top: 1.04vw;
        border-top: 0.05vw solid #333;
    }

    .services-container.arm-language .services-title h1 {
        font-size: 3.3vw;
        line-height: 1.135;
    }

    .service-item {
        padding: 1.04vw 0;
        border-bottom: 0.05vw solid #333;
    }

    .service-header {
        margin-bottom: 1.04vw;
    }

    .service-header h3 {
        font-size: 1.25vw;
    }

    .service-description p {
        font-size: 0.94vw;
        margin-bottom: 1.04vw;
    }

    .service-desc_links {
        max-width: 10vw;
        height: 1.09vw;
    }

    .read-more {
        font-size: 0.8vw;
    }

    .read-more svg {
        margin-left: 0.42vw;
        width: 0.76vw;
        height: 0.36vw;
    }

    .read-more:hover svg {
        transform: translateX(0.26vw);
    }

    .graphic-element {
        max-height: 20.53vw;
    }

    .graphic-element svg {
        max-width: 10.23vw;
    }

    .glow-effect.active {
        filter: drop-shadow(0 0 0.16vw #adf233) drop-shadow(0 0 0.16vw #adf233) drop-shadow(0 0 0.52vw #adf233) drop-shadow(0 0 0.78vw #adf233);
    }

    .service-header svg {
        width: 1.3vw;
    }

    .services-header_icons svg {
        width: 0.6vw;
        height: 0.6vw;
    }
}

@media (max-width: 576px) {
    .services-container {
        margin: 24.27vw 4.85vw;
        max-width: 576px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }

    .services-header,
    .services-header_icons {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
    }

    .services-header {
        margin-bottom: 7.28vw;
    }

    .services-header span {
        font-size: 4.36vw;
        font-weight: 500;
        margin-right: 2.42vw;
    }

    .services-header_icons svg {
        width: 2.42vw;
        height: 2.42vw;
        animation: rotatePauseSvg 2.5s infinite;
    }

    .services-container.arm-language .services-title h1 {
        font-size: 7.9vw;
        line-height: 1.135;
    }

    .services-container.arm-language .service-description p {
        font-size: 3.6vw;
    }

    @keyframes rotatePauseSvg {
        0% {
            transform: rotate(0);
        }
        20% {
            transform: rotate(0);
        }
        40% {
            transform: rotate(-90deg);
        }
        60% {
            transform: rotate(-90deg);
        }
        80% {
            transform: rotate(0);
        }
        100% {
            transform: rotate(0);
        }
    }

    .services-title {
        max-width: 100%;
        margin-bottom: 9.7vw;
    }
    .services-title h1 {
        font-size: 10.19vw;
        font-weight: 500;
        line-height: 11.57vw;
        text-align: left;
    }

    .services-main {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 4.85vw;
        background-color: #000;
        border: none;
        /**/
        border-radius: 4.85vw;
        /* height: 700px; */
        height: auto;
    }

    .services-block {
        width: 100%;
    }

    .services-block h2 {
        font-size: 6.79vw;
        font-weight: 500;
        line-height: 7.7vw;
        text-align: left;
        margin-bottom: 7.28vw;
    }

    .services-list {
        border-top: 0.24vw solid #333;
        margin-top: 3.64vw;
    }

    .service-item {
        border-bottom: 0.24vw solid #333;
        padding: 3.64vw 0;
    }

    .service-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        margin-bottom: 2.42vw;
    }

    .service-header h3 {
        font-size: 4.8vw;
        font-weight: 500;
        line-height: 5.5vw;
        text-align: left;
        color: #fff;
    }

    .service-header svg {
        width: 6.06vw;
        height: 6.06vw;
        transition: transform 0.3s ease;
    }

    .service-header svg.rotate {
        transform: rotate(45deg);
    }

    .rotate-icon .rotatable-rect {
        transform: rotate(-90deg);
        transform-origin: center;
        transition: transform 0.3s ease;
    }

    .rotatable-rect {
        transform-origin: center;
        transition: transform 0.3s ease;
    }

    .service-description-wrapper {
        overflow: hidden;
        transition: max-height 0.3s ease, opacity 0.3s ease;
    }

    .service-description p {
        font-size: 3.8vw;
        font-weight: 400;
        line-height: 4.4vw;
        text-align: left;
        color: #c3c3c3;
        margin-bottom: 3.64vw;
        margin-right: 10.9vw;
    }

    /**/

    .service-desc_links {
        max-width: 50.6vw;
        height: 5.09vw;
        overflow: hidden;
        position: relative;
        cursor: pointer;
    }

    .read-more {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: baseline;
        color: #adf233;
        font-weight: bold;
        text-decoration: none;
        transition: transform 0.5s ease, opacity 0.5s ease;

        font-size: 4.36vw;
        font-weight: 500;
        line-height: 4.85vw;
    }

    .read-more.primary {
        transform: translateX(0);
        opacity: 1;
    }

    .read-more.secondary {
        transform: translateX(-100%);
        opacity: 0;
    }

    .service-desc_links:hover .read-more.primary {
        transform: translateX(100%);
        opacity: 0;
    }

    .service-desc_links:hover .read-more.secondary {
        transform: translateX(0);
        opacity: 1;
    }

    .read-more svg {
        margin-left: 1.94vw;
        transition: transform 0.3s ease, fill 0.3s ease;
        fill: #adf233;
    }

    .read-more:hover svg {
        transform: translateX(1.21vw);
        fill: #fff;
    }

    /**/

    .graphic-element_container {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 100%;
        width: 100%;

        margin: 11.4vw 16.99vw 9.7vw 16.99vw;
    }

    .graphic-element {
        width: 100%;
        height: 100%;
        max-height: 46.6vw;
        max-width: 46.6vw;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .graphic-element svg {
        max-width: 23.3vw;
        max-height: 23.3vw;
        width: 100%;
        height: 100%;
        flex: 50%;
        transform: rotateY(180deg);
    }

    .glow-effect {
        fill: transparent;
        transition: fill 0.3s ease, filter 0.3s ease;
    }

    .glow-effect.active {
        filter: drop-shadow(0 0 0.72vw #adf233) drop-shadow(0 0 0.72vw #adf233) drop-shadow(0 0 2.42vw #adf233) drop-shadow(0 0 0.64vw #adf233);
        fill: #adf233;
        transition: fill 0.3s ease, filter 0.3s ease;
    }
}
