@font-face {
    font-family: "Saans TRIAL";
    src: url("../assets/font/SaansTRIAL-Bold.eot");
    src: local("Saans TRIAL Bold"), local("SaansTRIAL-Bold"), url("../assets/font/SaansTRIAL-Bold.eot?#iefix") format("embedded-opentype"),
        url("../assets/font/SaansTRIAL-Bold.woff2") format("woff2"), url("../assets/font/SaansTRIAL-Bold.woff") format("woff"),
        url("../assets/font/SaansTRIAL-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Saans TRIAL";
    src: url("../assets/font/SaansTRIAL-SemiBold.eot");
    src: local("Saans TRIAL SemiBold"), local("SaansTRIAL-SemiBold"),
        url("../assets/font/SaansTRIAL-SemiBold.eot?#iefix") format("embedded-opentype"),
        url("../assets/font/SaansTRIAL-SemiBold.woff2") format("woff2"), url("../assets/font/SaansTRIAL-SemiBold.woff") format("woff"),
        url("../assets/font/SaansTRIAL-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Saans TRIAL";
    src: url("../assets/font/SaansTRIAL-Medium.eot");
    src: local("Saans TRIAL Medium"), local("SaansTRIAL-Medium"), url("../assets/font/SaansTRIAL-Medium.eot?#iefix") format("embedded-opentype"),
        url("../assets/font/SaansTRIAL-Medium.woff2") format("woff2"), url("../assets/font/SaansTRIAL-Medium.woff") format("woff"),
        url("../assets/font/SaansTRIAL-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Saans TRIAL";
    src: url("../assets/font/SaansTRIAL-Regular.eot");
    src: local("Saans TRIAL Regular"), local("SaansTRIAL-Regular"), url("../assets/font/SaansTRIAL-Regular.eot?#iefix") format("embedded-opentype"),
        url("../assets/font/SaansTRIAL-Regular.woff2") format("woff2"), url("../assets/font/SaansTRIAL-Regular.woff") format("woff"),
        url("../assets/font/SaansTRIAL-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

.link-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 10px;
    padding: 40px;
    width: 573px;
    height: 340px;
    position: relative;
    margin-right: 30px;
    text-decoration: none;
    color: black;
    font-family: "Saans TRIAL";
}

.link-card:nth-child(2) {
    background-color: #fdfdfd;
}

.link-card:last-child {
    margin-right: 0;
    background-color: #f8f8f8;
}

.link-card-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.link-card-title {
    font-size: 26px;
    font-weight: 500;
    line-height: 29.52px;
    text-align: left;
}

.link-card-text {
    font-size: 18px;
    font-weight: 500;
    line-height: 20.43px;
    text-align: left;
    margin-top: 10px;
}

.link-card_footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: auto;
}

.link-card-description {
    font-size: 21px;
    font-weight: 500;
    line-height: 23.84px;
    text-align: left;
}

.link-card-link {
    text-decoration: none;
}

.link-card-arrow {
    position: relative;
    width: 42px;
    height: 42px;
    border: 1px solid #e2e2e2;
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;
    transition: transform 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
}

.link-card-arrow::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #e2e2e2;
    top: 100%;
    left: 0;
    transition: top 0.5s ease;
    z-index: 0;
}

.link-card-arrow:hover::before {
    top: 0;
}
.link-card:hover .link-card-arrow::before {
    top: 0;
}

.link-card-svg_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.5s ease;
}

.link-card-arrow:hover .link-card-svg_wrapper {
    transform: translateX(18px);
}

.link-card:hover .link-card-arrow .link-card-svg_wrapper {
    transform: translateX(18px);
}

.link-card-arrow .link-card-svg_wrapper {
    transform: translateX(-18px);
}

.link-card-arrow .link-card-svg_wrapper:last-child {
    margin-left: 20px;
}

@media (min-width: 576px) and (max-width: 1920px) {
    .link-card {
        border-radius: 0.52vw;
        padding: 2.08vw;
        width: 29.84vw;
        height: 17.71vw;
        margin-right: 1.56vw;
    }

    .link-card-title {
        font-size: 1.35vw;
        line-height: 1.54vw;
    }

    .link-card-text {
        font-size: 0.94vw;
        line-height: 1.06vw;
        margin-top: 0.52vw;
    }

    .link-card-description {
        font-size: 1.09vw;
        line-height: 1.24vw;
    }

    .link-card-arrow {
        position: relative;
        width: 2.19vw;
        height: 2.19vw;
    }

    .link-card-arrow:hover .link-card-svg_wrapper {
        transform: translateX(0.94vw);
    }

    .link-card-arrow .link-card-svg_wrapper {
        transform: translateX(-0.94vw);
    }

    .link-card-arrow .link-card-svg_wrapper:last-child {
        margin-left: 1.04vw;
    }

    .link-card-svg_wrapper svg {
        width: 0.93vw;
        height: 0.93vw;
    }
}

@media (max-width: 576px) {
    .link-card {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-size: cover;
        background-repeat: no-repeat;
        background-color: #fff;
        border: 0.24vw solid #d9d9d9; /* 1px */
        border-radius: 2.43vw; /* 10px */
        padding: 4.85vw; /* 20px */
        width: 100%;
        height: 48.54vw; /* 200px */
        position: relative;
        margin-right: 0;
        margin-bottom: 4.85vw; /* 20px */
    }

    .link-card:nth-child(2) {
        background-color: #fdfdfd;
    }

    .link-card:last-child {
        margin-right: 0;
        margin-bottom: 0;
        background-color: #f8f8f8;
    }

    .link-card-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    }

    .link-card-title {
        font-size: 4.85vw; /* 20px */
        font-weight: 500;
        line-height: 5.51vw; /* 22.71px */
        text-align: left;
    }

    .link-card-text {
        font-size: 3.4vw; /* 14px */
        font-weight: 500;
        line-height: 3.86vw; /* 15.89px */
        text-align: left;
        margin-top: 2.43vw; /* 10px */
    }

    .link-card_footer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-top: auto;
    }

    .link-card-description {
        font-size: 3.4vw; /* 14px */
        font-weight: 500;
        line-height: 3.86vw; /* 15.89px */
        text-align: left;
    }

    .link-card-link {
        text-decoration: none;
    }

    .link-card-arrow {
        position: relative;
        width: 7.77vw; /* 32px */
        height: 7.77vw; /* 32px */
        border: 0.24vw solid #e2e2e2; /* 1px */
        border-radius: 50%;
        overflow: hidden;
        cursor: pointer;
        transition: transform 0.3s ease;
        display: flex;
        align-items: center;
        justify-content: center;
        background: transparent;
    }

    .link-card-arrow::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: #e2e2e2;
        top: 100%;
        left: 0;
        transition: top 0.5s ease;
        z-index: 0;
    }

    .link-card-arrow:hover::before {
        top: 0;
    }

    .link-card-svg_wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        transition: transform 0.5s ease;
    }

    .link-card-svg_wrapper svg {
        width: 3.4vw; /* 14px */
        height: 3.4vw; /* 14px */
    }

    .link-card-arrow:hover .link-card-svg_wrapper {
        transform: translateX(3.88vw); /* 16px */
    }

    .link-card-arrow .link-card-svg_wrapper {
        transform: translateX(-3.88vw); /* 16px */
    }

    .link-card-arrow .link-card-svg_wrapper:last-child {
        margin-left: 4.85vw; /* 20px */
    }
}
