.scrollbar-wrapper {
    position: fixed;
    top: 50%;
    right: 32px;
    transform: translateY(-50%);
    width: 6px;
    height: 190px;
    background-color: #000;
    border-radius: 3px;
    overflow: hidden;
    z-index: 998;
}

.scrollbar {
    position: absolute;
    width: 6px;
    min-height: 32px;
    background-color: #243be2;
    border-radius: 3px;
    cursor: grab;
    transition: background-color 0.2s ease;
}

.scrollbar:hover {
    background-color: #555;
}

@media (min-width: 576px) and (max-width: 1920px) {
    .scrollbar-wrapper {
        right: 1.6vw;
        width: 0.3vw;
        height: 9.8vw;
        border-radius: 0.1vw;
    }

    .scrollbar {
        width: 0.3vw;
        min-height: 1.6vw;
        border-radius: 0.1vw;
    }
}


@media (max-width: 576px) {
    .scrollbar-wrapper{
        display: none;
    }
}