@font-face {
    font-family: "Saans TRIAL";
    src: url("../../assets/font/SaansTRIAL-Bold.eot");
    src: local("Saans TRIAL Bold"), local("SaansTRIAL-Bold"), url("../../assets/font/SaansTRIAL-Bold.eot?#iefix") format("embedded-opentype"),
        url("../../assets/font/SaansTRIAL-Bold.woff2") format("woff2"), url("../../assets/font/SaansTRIAL-Bold.woff") format("woff"),
        url("../../assets/font/SaansTRIAL-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Saans TRIAL";
    src: url("../../assets/font/SaansTRIAL-SemiBold.eot");
    src: local("Saans TRIAL SemiBold"), local("SaansTRIAL-SemiBold"),
        url("../../assets/font/SaansTRIAL-SemiBold.eot?#iefix") format("embedded-opentype"),
        url("../../assets/font/SaansTRIAL-SemiBold.woff2") format("woff2"), url("../../assets/font/SaansTRIAL-SemiBold.woff") format("woff"),
        url("../../assets/font/SaansTRIAL-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Saans TRIAL";
    src: url("../../assets/font/SaansTRIAL-Medium.eot");
    src: local("Saans TRIAL Medium"), local("SaansTRIAL-Medium"), url("../../assets/font/SaansTRIAL-Medium.eot?#iefix") format("embedded-opentype"),
        url("../../assets/font/SaansTRIAL-Medium.woff2") format("woff2"), url("../../assets/font/SaansTRIAL-Medium.woff") format("woff"),
        url("../../assets/font/SaansTRIAL-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Saans TRIAL";
    src: url("../../assets/font/SaansTRIAL-Regular.eot");
    src: local("Saans TRIAL Regular"), local("SaansTRIAL-Regular"), url("../../assets/font/SaansTRIAL-Regular.eot?#iefix") format("embedded-opentype"),
        url("../../assets/font/SaansTRIAL-Regular.woff2") format("woff2"), url("../../assets/font/SaansTRIAL-Regular.woff") format("woff"),
        url("../../assets/font/SaansTRIAL-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Mardoto";
    src: url("../../assets/font/Mardoto-Medium.woff2") format("woff2"), url("../../assets/font/Mardoto-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

.vacancies {
    margin: 200px 70px 0 70px;
    max-width: 1920px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    font-family: "Saans TRIAL";

    padding-bottom: 70px;
    border-bottom: 1px solid #d9d9d9;
}

.vacancies-header,
.vacancies-header_icons {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.vacancies-header {
    margin-bottom: 40px;
}

.vacancies-header span {
    font-size: 21px;
    font-weight: 600;
    line-height: 1.135;
    margin-right: 10px;
}

.vacancies-header_icons svg {
    animation: rotatePauseSvg 2.5s infinite;
}

@keyframes rotatePauseSvg {
    0% {
        transform: rotate(0);
    }
    20% {
        transform: rotate(0);
    }
    40% {
        transform: rotate(-90deg);
    }
    60% {
        transform: rotate(-90deg);
    }
    80% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(0);
    }
}

.vacancies-title {
    max-width: 60%;
    margin-bottom: 70px;
}
.vacancies-title h1 {
    font-size: 72px;
    font-weight: 500;
    line-height: 1.135;
}

/* vacancies list */
.vacancies-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 30px;
}

@media (min-width: 576px) and (max-width: 1920px) {
    .vacancies {
        margin: 10.4vw 3.65vw 0 3.65vw;
        max-width: 100vw;
        padding-bottom: 3.64vw;
        border-bottom: 0.05vw solid #d9d9d9;
    }

    .vacancies-header {
        margin-bottom: 2.08vw;
    }

    .vacancies-header span {
        font-size: 1.09vw;
        margin-right: 0.52vw;
    }

    .vacancies-header_icons svg {
        width: 0.625vw;
        height: 0.625vw;
        animation: rotatePauseSvg 2.5s infinite;
    }

    .vacancies-title {
        margin-bottom: 3.64vw;
    }
    .vacancies-title h1 {
        font-size: 3.75vw;
    }

    .vacancies-list {
        grid-row-gap: 1.56vw;
    }
}

@media (max-width: 576px) {
    .vacancies {
        margin: 24.27vw 4.85vw 0 4.85vw;
        max-width: 576px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding-bottom: 14.56vw;
        border-bottom: 0.24vw solid #d9d9d9;
    }

    .vacancies-header,
    .vacancies-header_icons {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
    }

    .vacancies-header {
        margin-bottom: 7.28vw;
    }

    .vacancies-header span {
        font-size: 4.36vw;
        font-weight: 600;
        line-height: 1.135;
        margin-right: 2.42vw;
    }

    .vacancies-header_icons svg {
        width: 2.42vw;
        height: 2.2vw;
        animation: rotatePauseSvg 2.5s infinite;
    }

    @keyframes rotatePauseSvg {
        0% {
            transform: rotate(0);
        }
        20% {
            transform: rotate(0);
        }
        40% {
            transform: rotate(-90deg);
        }
        60% {
            transform: rotate(-90deg);
        }
        80% {
            transform: rotate(0);
        }
        100% {
            transform: rotate(0);
        }
    }

    .vacancies-title {
        max-width: 100%;
        margin-bottom: 9.7vw;
    }
    .vacancies-title h1 {
        font-size: 6.94vw;
        font-weight: 500;
        line-height: 1.135;
    }

    /* vacancies list */
    .vacancies-list {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-row-gap: 0;
    }
}
