@font-face {
    font-family: "Saans TRIAL";
    src: url("../../assets/font/SaansTRIAL-Bold.eot");
    src: local("Saans TRIAL Bold"), local("SaansTRIAL-Bold"), url("../../assets/font/SaansTRIAL-Bold.eot?#iefix") format("embedded-opentype"),
        url("../../assets/font/SaansTRIAL-Bold.woff2") format("woff2"), url("../../assets/font/SaansTRIAL-Bold.woff") format("woff"),
        url("../../assets/font/SaansTRIAL-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Saans TRIAL";
    src: url("../../assets/font/SaansTRIAL-SemiBold.eot");
    src: local("Saans TRIAL SemiBold"), local("SaansTRIAL-SemiBold"),
        url("../../assets/font/SaansTRIAL-SemiBold.eot?#iefix") format("embedded-opentype"),
        url("../../assets/font/SaansTRIAL-SemiBold.woff2") format("woff2"), url("../../assets/font/SaansTRIAL-SemiBold.woff") format("woff"),
        url("../../assets/font/SaansTRIAL-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Saans TRIAL";
    src: url("../../assets/font/SaansTRIAL-Medium.eot");
    src: local("Saans TRIAL Medium"), local("SaansTRIAL-Medium"), url("../../assets/font/SaansTRIAL-Medium.eot?#iefix") format("embedded-opentype"),
        url("../../assets/font/SaansTRIAL-Medium.woff2") format("woff2"), url("../../assets/font/SaansTRIAL-Medium.woff") format("woff"),
        url("../../assets/font/SaansTRIAL-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Saans TRIAL";
    src: url("../../assets/font/SaansTRIAL-Regular.eot");
    src: local("Saans TRIAL Regular"), local("SaansTRIAL-Regular"), url("../../assets/font/SaansTRIAL-Regular.eot?#iefix") format("embedded-opentype"),
        url("../../assets/font/SaansTRIAL-Regular.woff2") format("woff2"), url("../../assets/font/SaansTRIAL-Regular.woff") format("woff"),
        url("../../assets/font/SaansTRIAL-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

.news-notFound {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    font-size: 36px;
    font-weight: 500;
    font-family: "Saans TRIAL";
}

@media (max-width: 576px) {
    .news-notFound {
        font-size: 5.5vw;
    }
}

.news-notFound-green {
    color: rgb(173, 242, 51);
}

.news-notFound-blue {
    color: rgb(36, 59, 226);
}

/*news details*/

.newsDetailPage {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    max-width: 1920px;
    margin: 200px 70px 120px 70px;
    font-family: "Saans TRIAL";
}

.backButton-container {
    display: flex;
    justify-content: flex-start;
    width: 100%;
}

.backButton {
    border: none;
    outline: none;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    font-size: 21px;
    font-weight: 600;
    line-height: 1.135;
    text-align: left;
    color: #000;
    margin-bottom: 40px;

    cursor: pointer;
}

.backButton svg {
    width: 12px;
    height: 12px;
    margin-right: 10px;
}

.newsDetail-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.newsDetail-content span {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.135;
    margin-bottom: 20px;
}

.newsDetail-content h1 {
    font-size: 36px;
    font-weight: 600;
    line-height: 1.135;
    text-align: center;
    margin-bottom: 30px;
    max-width: 30%;
}

.newsDetail-content img {
    width: 100%;
    height: 100%;
    max-height: 650px;
    object-fit: cover;
    margin-bottom: 30px;
    border-radius: 10px;
    border: none;
}

.newsDetailPage-description_container {
    width: 100%;
    padding-bottom: 70px;
    border-bottom: 1px solid #d9d9d9;
}

.newsDetailPage-description {
    font-size: 18px;
    line-height: 1.135;
}

.newsDetailPage-description p {
    margin-bottom: 20px;
}

.newsDetailPage-description ul {
    margin: 20px 0;
    padding-left: 20px;
}

.newsDetailPage-description li {
    margin-bottom: 5px;
}

@media (min-width: 576px) and (max-width: 1920px) {
    /*news details*/
    .newsDetailPage {
        max-width: 100vw;
        margin: 10.41vw 3.64vw 6.25vw 3.64vw;
    }

    .backButton {
        font-size: 1.09vw;
        margin-bottom: 2.08vw;
    }

    .backButton svg {
        width: 0.62vw;
        height: 0.62vw;
        margin-right: 0.52vw;
    }

    .newsDetail-content span {
        font-size: 0.93vw;
        margin-bottom: 1.04vw;
    }

    .newsDetail-content h1 {
        font-size: 1.87vw;
        margin-bottom: 1.56vw;
    }

    .newsDetail-content img {
        margin-bottom: 1.56vw;
        max-height: 33.85vw;
        border-radius: 0.52vw;
    }

    .newsDetailPage-description_container {
        padding-bottom: 3.64vw;
        border-bottom: 0.05vw solid #d9d9d9;
    }

    .newsDetailPage-description {
        font-size: 0.93vw;
    }

    .newsDetailPage-description p {
        margin-bottom: 1.04vw;
    }

    .newsDetailPage-description ul {
        margin: 1.04vw 0;
        padding-left: 1.04vw;
    }

    .newsDetailPage-description li {
        margin-bottom: 0.26vw;
    }
}

@media (max-width: 576px) {
    /*news details*/
    .newsDetailPage {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        max-width: 576px;
        margin: 24.27vw 4.85vw;
    }

    .backButton-container {
        display: flex;
        justify-content: flex-start;
        width: 100%;
    }

    .backButton {
        border: none;
        outline: none;
        background-color: transparent;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        font-size: 4.36vw;
        font-weight: 600;
        line-height: 1.135;
        text-align: left;
        color: #000;
        margin-bottom: 9.7vw;

        cursor: pointer;
    }

    .backButton svg {
        width: 1.94vw;
        height: 1.94vw;
        margin-right: 2.42vw;
    }

    .newsDetail-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .newsDetail-content span {
        font-size: 3.88vw;
        font-weight: 500;
        line-height: 1.135;
        margin-bottom: 3.6vw;
    }

    .newsDetail-content h1 {
        font-size: 5.82vw;
        font-weight: 600;
        line-height: 1.135;
        text-align: center;
        margin-bottom: 3.64vw;
        max-width: 100%;
    }

    .newsDetail-content img {
        width: 100%;
        height: 100%;
        max-height: 44.17vw;
        object-fit: cover;
        margin-bottom: 3.64vw;
        border-radius: 2.42vw;
    }

    .newsDetailPage-description_container {
        width: 100%;
        padding-bottom: 14.56vw;
        border-bottom: 0.24vw solid #d9d9d9;
    }

    .newsDetailPage-description {
        font-size: 4.36vw;
        line-height: 1.135;
    }

    .newsDetailPage-description p {
        margin-bottom: 4.85vw;
    }

    .newsDetailPage-description ul {
        margin: 4.85vw 0;
        padding-left: 4.85vw;
    }

    .newsDetailPage-description li {
        margin-bottom: 1.21vw;
    }
}
