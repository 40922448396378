@font-face {
    font-family: "Saans TRIAL";
    src: url("../assets/font/SaansTRIAL-Bold.eot");
    src: local("Saans TRIAL Bold"), local("SaansTRIAL-Bold"), url("../assets/font/SaansTRIAL-Bold.eot?#iefix") format("embedded-opentype"),
        url("../assets/font/SaansTRIAL-Bold.woff2") format("woff2"), url("../assets/font/SaansTRIAL-Bold.woff") format("woff"),
        url("../assets/font/SaansTRIAL-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Saans TRIAL";
    src: url("../assets/font/SaansTRIAL-SemiBold.eot");
    src: local("Saans TRIAL SemiBold"), local("SaansTRIAL-SemiBold"), url("../assets/font/SaansTRIAL-SemiBold.eot?#iefix") format("embedded-opentype"),
        url("../assets/font/SaansTRIAL-SemiBold.woff2") format("woff2"), url("../assets/font/SaansTRIAL-SemiBold.woff") format("woff"),
        url("../assets/font/SaansTRIAL-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Saans TRIAL";
    src: url("../assets/font/SaansTRIAL-Medium.eot");
    src: local("Saans TRIAL Medium"), local("SaansTRIAL-Medium"), url("../assets/font/SaansTRIAL-Medium.eot?#iefix") format("embedded-opentype"),
        url("../assets/font/SaansTRIAL-Medium.woff2") format("woff2"), url("../assets/font/SaansTRIAL-Medium.woff") format("woff"),
        url("../assets/font/SaansTRIAL-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Mardoto";
    src: url("../assets/font/Mardoto-Medium.woff2") format("woff2"),
         url("../assets/font/Mardoto-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

.main-block {
    padding: 195px 70px 0 70px;
    max-width: 1920px;
    /* border-bottom: 1px solid #d9d9d9; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    position: sticky;
    position: -webkit-sticky;
    top: 0;
    width: 100%;
    z-index: -1;
    font-family: "Saans TRIAL";
}

.main-block-title {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 71vh;
    max-height: 746px;
    position: relative;
    background-size: cover;
    background-position: center;
    overflow: hidden;
    border-radius: 40px;
    background-color: #d8d8d8;

    margin: 0 70px;
}

.background-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 110%;
    height: 110%;
    object-fit: cover;
    z-index: 0;
}

.main-block-title video::-webkit-media-controls {
  display: none;
}

@keyframes gradientWave {
    0% {
        background-position: 0% 50%;
    }
    100% {
        background-position: 100% 50%;
    }
}

.main-block.arm-language .main-block-title h1{
    font-size: 98px;
}

.main-block-title h1 {
    font-size: 128px;
    color: rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    white-space: nowrap;
    z-index: 1;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    background: linear-gradient(90deg, #ffffff, #999999, #000, #000, #000000);
    background-size: 200% 200%;
    background-position: 0% 50%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: gradientWave 3.5s linear forwards;

    font-weight: 600;
}

.main-block-scroll {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 28px 63px 50px 63px;
    color: black;
    border-bottom: 1px solid #d9d9d9;
}

.main-block-scroll p {
    font-size: 26px;
    font-weight: bold;
    margin: 0 20px;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
}

.scroll-controls {
    display: flex;
    align-items: center;
}

.scroll-controls span {
    margin-right: 50px;
}

.scroll-controls span:last-child {
    margin-right: 0;
}

.scroll-controls span svg {
    animation: rotatePause 2.5s infinite;
}

@keyframes rotatePause {
    0% {
        transform: rotate(0);
    }
    20% {
        transform: rotate(0);
    }
    40% {
        transform: rotate(-180deg);
    }
    60% {
        transform: rotate(-180deg);
    }
    80% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(0);
    }
}

@media (min-width: 576px) and (max-width: 1920px) {
    .main-block {
        /* padding: 10vw 3.6vw 2.6vw 3.6vw; */
        padding: 7vw 3.6vw 0 3.6vw;
        max-width: 100vw;
        /* border-bottom: 0.05vw solid #d9d9d9; */
    }

    .main-block.arm-language .main-block-title h1{
        font-size: 5.1vw;
    }

    .main-block-title {
        height: 35.8vw;
        border-radius: 2vw;
        margin: 0 3.6vw;
    }

    .main-block-title h1 {
        font-size: 6.6vw;
    }

    .main-block-scroll {
        padding: 1.45vw 3.2vw 1.6vw 3.2vw;
        border-bottom: 0.05vw solid #d9d9d9;
    }

    .main-block-scroll p {
        font-size: 1.35vw;
        margin: 0 1vw;
    }

    .scroll-controls span {
        margin-right: 2.6vw;
    }

    .scroll-controls span svg {
        width: 0.7vw;
        height: 0.7vw;
    }
}

@media (max-width: 576px) {

    .main-block.arm-language .main-block-title h1{
        font-size: 9.2vw;
        text-align: center;
        line-height: 1.135;
    }


    .main-block {
        padding: 24.27vw 4.85vw 12.14vw 4.85vw;
        max-width: 139.81vw;
        width: 100%;
        border-bottom: none;
    }

    .main-block-title {
        width: 100%;
        height: 100vh;
        max-height: 77dvh;
        border-radius: 4.85vw;
        margin: 0 4.85vw;
    }

    .main-block-title h1 {
        font-size: 15.05vw;
        font-weight: 600;
        line-height: 17.08vw;
        text-align: left;
        /* white-space: wrap; */
    }

    .main-block-scroll {
        padding: 4.85vw 14.56vw;
        color: black;
        border-bottom: 0.24vw solid #d9d9d9;
    }

    .main-block-scroll p {
        font-size: 4.37vw;
        font-weight: 600;
        line-height: 4.96vw;
        margin: 0 4.85vw;
        user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;
    }

    .scroll-controls span {
        display: flex;
        align-items: unset;
        margin-right: 4.85vw;
        width: 3.15vw;
        height: 3.15vw;
    }

    .scroll-controls span svg {
        width: 3.15vw;
        height: 3.15vw;
    }

    .scroll-controls span:last-child {
        margin-right: 0;
    }
}
