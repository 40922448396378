@font-face {
    font-family: "Saans TRIAL";
    src: url("../assets/font/SaansTRIAL-Bold.eot");
    src: local("Saans TRIAL Bold"), local("SaansTRIAL-Bold"), url("../assets/font/SaansTRIAL-Bold.eot?#iefix") format("embedded-opentype"),
        url("../assets/font/SaansTRIAL-Bold.woff2") format("woff2"), url("../assets/font/SaansTRIAL-Bold.woff") format("woff"),
        url("../assets/font/SaansTRIAL-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Saans TRIAL";
    src: url("../assets/font/SaansTRIAL-SemiBold.eot");
    src: local("Saans TRIAL SemiBold"), local("SaansTRIAL-SemiBold"), url("../assets/font/SaansTRIAL-SemiBold.eot?#iefix") format("embedded-opentype"),
        url("../assets/font/SaansTRIAL-SemiBold.woff2") format("woff2"), url("../assets/font/SaansTRIAL-SemiBold.woff") format("woff"),
        url("../assets/font/SaansTRIAL-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Saans TRIAL";
    src: url("../assets/font/SaansTRIAL-Medium.eot");
    src: local("Saans TRIAL Medium"), local("SaansTRIAL-Medium"), url("../assets/font/SaansTRIAL-Medium.eot?#iefix") format("embedded-opentype"),
        url("../assets/font/SaansTRIAL-Medium.woff2") format("woff2"), url("../assets/font/SaansTRIAL-Medium.woff") format("woff"),
        url("../assets/font/SaansTRIAL-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Saans TRIAL";
    src: url("../assets/font/SaansTRIAL-Regular.eot");
    src: local("Saans TRIAL Regular"), local("SaansTRIAL-Regular"), url("../assets/font/SaansTRIAL-Regular.eot?#iefix") format("embedded-opentype"),
        url("../assets/font/SaansTRIAL-Regular.woff2") format("woff2"), url("../assets/font/SaansTRIAL-Regular.woff") format("woff"),
        url("../assets/font/SaansTRIAL-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Mardoto";
    src: url("../assets/font/Mardoto-Medium.woff2") format("woff2"), url("../assets/font/Mardoto-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

.footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    max-width: 1920px;
    margin: 0 70px 40px 70px;
    font-family: "Saans TRIAL";
}

.footer-description {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    background-image: url("../assets/footer-bg.png");
    background-size: cover;
    background-position: center;

    width: 33%;
    height: 500px;

    padding: 50px 53px 40px 53px;
    border: none;
    border-radius: 30px;

    margin-right: 30px;
}

.footer-description span {
    font-size: 48px;
    font-weight: 500;
    line-height: 1.135;
    color: #fff;
}

.footer-description p {
    font-size: 20px;
    font-weight: 500;
    line-height: 1.135;
    color: #cacaca;
}

/**/

.footer-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 50px 80px 40px 80px;
    background-color: #000;
    border: none;
    border-radius: 30px;
    width: 67%;
}

.footer-links {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* justify-content: flex-start; */
    align-items: flex-start;
    width: 100%;
}

.footer-link {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    /* margin-right: 120px; */
}

/* .footer-links .footer-link:last-child {
    margin-right: 40px;
} */

.footer-link span {
    font-size: 24px;
    font-weight: 700;
    line-height: 1.135;
    color: #fff;
    margin-bottom: 20px;
}

.footer-link_items {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    max-width: 265px;
}

.footer-link_items a {
    text-decoration: none;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.135;
    color: #cacaca;
    margin-bottom: 10px;
    transition: color 0.3s ease-in-out;
}

.footer-link_items a:last-child {
    margin-bottom: 0;
}

.footer-link_items a:hover {
    color: #fff;
    transition: color 0.3s ease-in-out;
}

.footer-credentials {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
    border-top: 1px solid #717171;
}

.footer-credentials span {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.135;
    color: #cacaca;
}
.footer-credentials span a {
    text-decoration: none;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.135;
    color: #cacaca;
}

.footer-privacy {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.footer-privacy a {
    font-size: 18px;
    font-weight: 500;
    text-decoration: none;
    color: #fff;
    margin-right: 10px;
}

.footer-privacy a:last-child {
    margin-right: 0;
}

.footer_credentials-container {
    display: flex;
    flex-direction: column;
}

.bygaroon {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.135;
    color: #cacaca;
    margin-top: 10px;
}
.bygaroon a {
    text-decoration: none;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.135;
    color: #cacaca;
}

@media (min-width: 576px) and (max-width: 1920px) {
    .footer {
        max-width: 100vw;
        margin: 0 3.6vw 2vw 3.6vw;
    }

    .footer-description {
        height: 26vw;
        padding: 2.6vw 2.76vw 2.08vw 2.76vw;
        border-radius: 1.5vw;
        margin-right: 1.5vw;
    }

    .footer-description span {
        font-size: 2.5vw;
    }

    .footer-description p {
        font-size: 1vw;
    }
    /**/
    .footer-container {
        padding: 2.6vw 4.1vw 2.08vw 4.1vw;
        border-radius: 1.5vw;
    }

    .footer-link span {
        font-size: 1.25vw;
        margin-bottom: 1vw;
    }

    .footer-link_items {
        max-width: 13.8vw;
    }

    .footer-link_items a {
        font-size: 0.9vw;
        margin-bottom: 0.5vw;
    }

    .footer-link_items a:last-child {
        margin-bottom: 0;
    }

    .footer-credentials {
        padding-top: 1.04vw;
    }

    .footer-credentials span {
        font-size: 0.9vw;
    }
    .footer-credentials span a {
        font-size: 0.9vw;
    }

    .footer-privacy a {
        font-size: 0.9vw;
        margin-right: 0.5vw;
    }

    .footer-privacy a:last-child {
        margin-right: 0;
    }

    .bygaroon {
        font-size: 0.93vw;
        font-weight: 500;
        line-height: 1.135;
        color: #cacaca;
        margin-top: 0.52vw;
    }
    .bygaroon a {
        text-decoration: none;
        font-size: 0.93vw;
        font-weight: 500;
        line-height: 1.135;
        color: #cacaca;
    }
}

.footer-link-mobile {
    display: none;
}

@media (max-width: 576px) {
    .footer {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        max-width: 139.81vw; /* 576px */
        margin: 0 4.85vw 4.85vw 4.85vw; /* 20px */
    }

    .footer-description {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;

        background-image: url("../assets/footer-bg.png");
        background-size: cover;
        background-position: center;

        width: 100%;
        height: 78.88vw; /* 325px */

        padding: 4.85vw; /* 20px */
        border: none;
        border-radius: 7.28vw; /* 30px */

        margin-right: 0;
        margin-bottom: 4.85vw; /* 20px */
    }

    .footer-description span {
        font-size: 11.17vw; /* 46px */
        line-height: 9.92vw; /* 40.87px */
        font-weight: 500;
        color: #fff;
    }

    .footer-description p {
        font-size: 3.4vw; /* 14px */
        font-weight: 500;
        line-height: 3.86vw; /* 15.89px */
        text-align: left;

        color: #cacaca;
    }

    /**/

    .footer-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 7.28vw; /* 30px */
        background-color: #000;
        border: none;
        border-radius: 4.85vw; /* 20px */
        width: 100%;
    }

    .footer-links {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        /* justify-content: flex-start; */
        align-items: flex-start;
        flex-wrap: wrap;
        width: 100%;
    }

    .footer-link {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        /* margin-right: 29.13vw; */ /* 120px */
    }

    /* .footer-links .footer-link:last-child {
    margin-right: 9.71vw; /* 40px */

    .footer-link span {
        font-size: 4.85vw; /* 20px */
        font-weight: 700;
        line-height: 5.51vw; /* 22.71px */
        text-align: left;

        color: #fff;
        margin-bottom: 3.64vw; /* 15px */
    }

    .footer-link_items {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        max-width: unset;
    }

    .footer-link_items a {
        text-decoration: none;
        font-size: 3.88vw; /* 16px */
        font-weight: 500;
        line-height: 4.41vw; /* 18.16px */
        color: #cacaca;
        margin-bottom: 2.43vw; /* 10px */
    }

    .footer-link_items a:last-child {
        margin-bottom: 0;
    }

    .footer-link-desktop {
        display: none;
    }

    .footer-link-mobile {
        display: flex;
    }

    .footer-link:nth-child(3) {
        margin: 7.28vw 0;
    }

    .footer-link:nth-child(4) {
        margin-bottom: 7.28vw;
    }

    .footer-credentials {
        display: flex;
        flex-direction: column-reverse;
        justify-content: flex-start;
        align-items: initial;
        padding-top: 4.85vw; /* 20px */
        border-top: 0.24vw solid #717171; /* 1px */
    }

    .footer-credentials span {
        font-size: 3.88vw; /* 16px */
        font-weight: 500;
        line-height: 4.41vw; /* 18.16px */
        text-align: left;

        color: #cacaca;
    }
    .footer-credentials span a {
        text-decoration: none;
        font-size: 3.88vw; /* 16px */
        font-weight: 500;
        line-height: 4.41vw; /* 18.16px */
        color: #cacaca;
    }

    .footer-privacy {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 2.42vw;
    }

    .footer.arm-language .footer-privacy {
        flex-direction: column;
        align-items: flex-start;
    }

    .footer-privacy a {
        font-size: 3.88vw; /* 16px */
        font-weight: 500;
        line-height: 4.41vw; /* 18.16px */

        text-decoration: none;
        color: #fff;
        margin-right: 2.43vw; /* 10px */
    }

    .footer-privacy a:last-child {
        margin-right: 0;
    }

    .footer.arm-language .footer-privacy a:last-child {
        margin-top: 2.4vw;
    }

    .bygaroon {
        font-size: 3.88vw;
        font-weight: 500;
        line-height: 1.135;
        color: #cacaca;
        margin-top: 2.42vw;
    }
    .bygaroon a {
        text-decoration: none;
        font-size: 3.88vw;
        font-weight: 500;
        line-height: 1.135;
        color: #cacaca;
    }
}
