@font-face {
    font-family: "Saans TRIAL";
    src: url("../../assets/font/SaansTRIAL-Bold.eot");
    src: local("Saans TRIAL Bold"), local("SaansTRIAL-Bold"), url("../../assets/font/SaansTRIAL-Bold.eot?#iefix") format("embedded-opentype"),
        url("../../assets/font/SaansTRIAL-Bold.woff2") format("woff2"), url("../../assets/font/SaansTRIAL-Bold.woff") format("woff"),
        url("../../assets/font/SaansTRIAL-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Saans TRIAL";
    src: url("../../assets/font/SaansTRIAL-SemiBold.eot");
    src: local("Saans TRIAL SemiBold"), local("SaansTRIAL-SemiBold"),
        url("../../assets/font/SaansTRIAL-SemiBold.eot?#iefix") format("embedded-opentype"),
        url("../../assets/font/SaansTRIAL-SemiBold.woff2") format("woff2"), url("../../assets/font/SaansTRIAL-SemiBold.woff") format("woff"),
        url("../../assets/font/SaansTRIAL-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Saans TRIAL";
    src: url("../../assets/font/SaansTRIAL-Medium.eot");
    src: local("Saans TRIAL Medium"), local("SaansTRIAL-Medium"), url("../../assets/font/SaansTRIAL-Medium.eot?#iefix") format("embedded-opentype"),
        url("../../assets/font/SaansTRIAL-Medium.woff2") format("woff2"), url("../../assets/font/SaansTRIAL-Medium.woff") format("woff"),
        url("../../assets/font/SaansTRIAL-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Saans TRIAL";
    src: url("../../assets/font/SaansTRIAL-Regular.eot");
    src: local("Saans TRIAL Regular"), local("SaansTRIAL-Regular"), url("../../assets/font/SaansTRIAL-Regular.eot?#iefix") format("embedded-opentype"),
        url("../../assets/font/SaansTRIAL-Regular.woff2") format("woff2"), url("../../assets/font/SaansTRIAL-Regular.woff") format("woff"),
        url("../../assets/font/SaansTRIAL-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

.news {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    max-width: 1920px;
    margin: 0 70px 120px 70px;
    font-family: "Saans TRIAL";
}

.news-header,
.news-header_icons {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.news-header {
    margin-bottom: 40px;
}

.news-header span {
    font-size: 21px;
    font-weight: 600;
    line-height: 1.135;
    margin-right: 10px;
}

.news-header_icons svg {
    animation: rotatePauseSvg 2.5s infinite;
}

@keyframes rotatePauseSvg {
    0% {
        transform: rotate(0);
    }
    20% {
        transform: rotate(0);
    }
    40% {
        transform: rotate(-90deg);
    }
    60% {
        transform: rotate(-90deg);
    }
    80% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(0);
    }
}

.news-title {
    max-width: 65%;
    margin-bottom: 70px;
}
.news-title h1 {
    font-size: 72px;
    font-weight: 500;
    line-height: 1.135;
}

.news-cards {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 100%;
}

.news-card {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-right: 30px;
    text-decoration: none;
    color: #000;
    max-width: 25%;
}

.news-card:last-child {
    margin-right: 0;
}

.news-card_image {
    margin-bottom: 10px;
    width: 100%;
    height: 100%;
    max-height: 384px;
    min-height: 384px;
    display: block;
    border: none;
    outline: none;
    border-radius: 10px;
    object-fit: cover;
}

.news-card_content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 20px;
    border: none;
    border-radius: 10px;
    background-color: #f8f8f8;
    height: 100%;
}

.news-card_date {
    font-size: 18px;
    font-weight: 400;
    line-height: 1.135;
    margin-bottom: 10px;
}

.news-card_title {
    font-size: 24px;
    font-weight: 500;
    line-height: 1.135;
    margin-bottom: 10px;
}

.news-card_description {
    font-size: 18px;
    line-height: 1.135;
    font-weight: 400;

    display: -webkit-box; /* Creates a flexible box layout */
    -webkit-line-clamp: 2; /* Limits the text to 2 lines */
    -webkit-box-orient: vertical; /* Specifies vertical stacking */
    overflow: hidden; /* Ensures content beyond 2 lines is hidden */
    text-overflow: ellipsis;
}

.news-footer {
    text-align: right;
    margin-top: 50px;
}

.news-readmore {
    text-decoration: none;
    color: #000;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    width: fit-content;
    float: inline-end;

    font-size: 21px;
    font-weight: 700;
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
}

.news-readmore svg {
    animation: rotatePause 2.5s infinite;
}

.news-readmore svg:first-child {
    margin-right: 3.21px;
    animation: rotateXPause 2.5s infinite;
}

.news-readmore svg:last-of-type {
    margin-right: 8.5px;
    margin-left: 4.63px;
}

@media (min-width: 576px) and (max-width: 1920px) {
    .news {
        max-width: 100vw;
        margin: 0 3.65vw 6.25vw 3.65vw;
    }

    .news-header {
        margin-bottom: 2.08vw;
    }

    .news-header span {
        font-size: 1.09vw;
        margin-right: 0.52vw;
    }

    .news-title {
        margin-bottom: 3.65vw;
    }

    .news-title h1 {
        font-size: 3.75vw;
    }

    .news-card {
        margin-right: 1.56vw;
    }

    .news-card:last-child {
        margin-right: 0;
    }

    .news-card_image {
        margin-bottom: 0.52vw;
        height: 100%;
        max-height: 20vw;
        min-height: 20vw;
        border-radius: 0.52vw;
    }

    .news-card_content {
        padding: 1.04vw;
        border-radius: 0.52vw;
    }

    .news-card_date {
        font-size: 0.94vw;
        margin-bottom: 0.52vw;
    }

    .news-card_title {
        font-size: 1.25vw;
        margin-bottom: 0.52vw;
    }

    .news-card_description {
        font-size: 0.92vw;
    }

    .news-footer {
        margin-top: 2.6vw;
    }

    .news-readmore {
        font-size: 1.09vw;
    }

    .news-readmore svg:first-child {
        margin-right: 0.17vw;
    }

    .news-readmore svg:last-of-type {
        margin-right: 0.44vw;
        margin-left: 0.24vw;
    }

    .news-header_icons svg {
        width: 0.6vw;
        height: 0.6vw;
    }
}
@media (min-width: 786px) and (max-width: 1520px) {
    .news-card_date {
        font-size: 0.94vw;
        margin-bottom: 0.52vw;
    }

    .news-card_title {
        font-size: 1.15vw;
        margin-bottom: 0.52vw;
    }

    .news-card_description {
        font-size: 0.87vw;
    }
}
@media (min-width: 576px) and (max-width: 786px) {
    .news-card_date {
        font-size: 0.74vw;
        margin-bottom: 0.52vw;
    }

    .news-card_title {
        font-size: 1vw;
        margin-bottom: 0.52vw;
    }

    .news-card_description {
        font-size: 0.77vw;
    }
}

@media (max-width: 576px) {
    .news {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        max-width: 576px;
        margin: 0 4.85vw 24.27vw 4.85vw;
    }

    .news-header,
    .news-header_icons {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
    }

    .news-header {
        margin-bottom: 7.28vw;
    }

    .news-header span {
        font-size: 4.36vw;
        font-weight: 600;
        line-height: 4.95vw;
        text-align: left;

        margin-right: 2.42vw;
    }

    .news-header_icons svg {
        width: 2.42vw;
        height: 2.42vw;
        animation: rotatePauseSvg 2.5s infinite;
    }

    @keyframes rotatePauseSvg {
        0% {
            transform: rotate(0);
        }
        20% {
            transform: rotate(0);
        }
        40% {
            transform: rotate(-90deg);
        }
        60% {
            transform: rotate(-90deg);
        }
        80% {
            transform: rotate(0);
        }
        100% {
            transform: rotate(0);
        }
    }

    .news-title {
        max-width: 100%;
        margin-bottom: 9.7vw;
    }
    .news-title h1 {
        font-size: 10.19vw;
        font-weight: 500;
        line-height: 11.57vw;
        text-align: left;
    }

    .news-cards {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 100%;
    }

    .news-card {
        width: 100%;
        flex: 1;
        display: flex;
        flex-direction: column;
        margin-right: 0;
        margin-bottom: 4.85vw;
        max-width: 100%;
    }

    .news-card:last-child {
        margin-right: 0;
    }

    .news-card_image {
        margin-bottom: 2.42vw;
        width: 100%;
        height: 100%;
        max-height: 82.28vw;
        min-height: 82.28vw;
        border-radius: 2.42vw;
        display: block;
    }

    .news-card_content {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 4.85vw;
        border: none;
        border-radius: 2.42vw;
        background-color: #f8f8f8;
    }

    .news-card_date {
        font-size: 3.88vw;
        font-weight: 400;
        line-height: 4.4vw;
        margin-bottom: 2.42vw;
    }

    .news-card_title {
        font-size: 4.85vw;
        font-weight: 500;
        line-height: 5.51vw;
        margin-bottom: 2.42vw;
    }

    .news-card_description {
        font-size: 3.88vw;
        font-weight: 400;
        line-height: 4.4vw;
    }

    .news-footer {
        text-align: right;
        margin-top: 12.1vw;
    }

    .news-readmore {
        text-decoration: none;
        color: #000;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;

        width: fit-content;
        float: inline-end;

        font-size: 4.36vw;
        font-weight: 700;
        line-height: 4.95vw;
        text-decoration-line: underline;
        text-decoration-style: solid;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
    }
}
