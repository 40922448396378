@font-face {
    font-family: "Saans TRIAL";
    src: url("../../assets/font/SaansTRIAL-Bold.eot");
    src: local("Saans TRIAL Bold"), local("SaansTRIAL-Bold"), url("../../assets/font/SaansTRIAL-Bold.eot?#iefix") format("embedded-opentype"),
        url("../../assets/font/SaansTRIAL-Bold.woff2") format("woff2"), url("../../assets/font/SaansTRIAL-Bold.woff") format("woff"),
        url("../../assets/font/SaansTRIAL-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Saans TRIAL";
    src: url("../../assets/font/SaansTRIAL-SemiBold.eot");
    src: local("Saans TRIAL SemiBold"), local("SaansTRIAL-SemiBold"),
        url("../../assets/font/SaansTRIAL-SemiBold.eot?#iefix") format("embedded-opentype"),
        url("../../assets/font/SaansTRIAL-SemiBold.woff2") format("woff2"), url("../../assets/font/SaansTRIAL-SemiBold.woff") format("woff"),
        url("../../assets/font/SaansTRIAL-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Saans TRIAL";
    src: url("../../assets/font/SaansTRIAL-Medium.eot");
    src: local("Saans TRIAL Medium"), local("SaansTRIAL-Medium"), url("../../assets/font/SaansTRIAL-Medium.eot?#iefix") format("embedded-opentype"),
        url("../../assets/font/SaansTRIAL-Medium.woff2") format("woff2"), url("../../assets/font/SaansTRIAL-Medium.woff") format("woff"),
        url("../../assets/font/SaansTRIAL-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Saans TRIAL";
    src: url("../../assets/font/SaansTRIAL-Regular.eot");
    src: local("Saans TRIAL Regular"), local("SaansTRIAL-Regular"), url("../../assets/font/SaansTRIAL-Regular.eot?#iefix") format("embedded-opentype"),
        url("../../assets/font/SaansTRIAL-Regular.woff2") format("woff2"), url("../../assets/font/SaansTRIAL-Regular.woff") format("woff"),
        url("../../assets/font/SaansTRIAL-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Mardoto";
    src: url("../../assets/font/Mardoto-Medium.woff2") format("woff2"), url("../../assets/font/Mardoto-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

.payment {
    margin: 160px 70px 160px 70px;
    max-width: 1920px;
    padding: 0 0 70px 0;
    border-bottom: 1px solid #d9d9d9;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-family: "Saans TRIAL";
}

.payment-header,
.payment-header_icons {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.payment-header {
    margin-bottom: 40px;
}

.payment-header span {
    font-size: 21px;
    font-weight: 600;
    line-height: 1.135;
    margin-right: 10px;
}

.payment-header_icons svg {
    animation: rotatePauseSvg 2.5s infinite;
}

@keyframes rotatePauseSvg {
    0% {
        transform: rotate(0);
    }
    20% {
        transform: rotate(0);
    }
    40% {
        transform: rotate(-90deg);
    }
    60% {
        transform: rotate(-90deg);
    }
    80% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(0);
    }
}

.payment-title {
    margin-bottom: 70px;
}
.payment-title h1 {
    font-size: 72px;
    font-weight: 500;
    line-height: 1.135;
}

.payment-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 120px;
    width: 50%;
}

.payment-main {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.payment-description {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 80%;
}

.payment-description svg {
    width: 100%;
    height: 100%;
    max-width: 23px;
    max-height: 23px;
    margin-right: 5px;
}

.payment-description p {
    font-size: 28px;
    font-weight: 600;
    text-align: left;
    color: #000;
}
.payment-description span {
    font-size: 28px;
    font-weight: 600;
    line-height: 1.135;
    text-align: left;
    color: #686868;
}

.payment-image {
    display: flex;
    width: 50%;
}

.payment-image img {
    width: 100%;
    object-fit: contain;
    border: none;
    border-radius: 38px;
}

@media (min-width: 576px) and (max-width: 1920px) {
    .payment {
        margin: 8.33vw 3.65vw 8.33vw 3.65vw;
        max-width: 100vw;
        padding: 0 0 3.65vw 0;
        border-bottom: 1px solid #d9d9d9;
    }

    .payment-header {
        margin-bottom: 2.08vw;
    }

    .payment-header span {
        font-size: 1.09vw;
        margin-right: 0.52vw;
    }

    .payment-title {
        margin-bottom: 3.65vw;
    }

    .payment-title h1 {
        font-size: 3.75vw;
    }

    .payment-container {
        margin-right: 6.25vw;
    }

    .payment-description svg {
        max-width: 1.2vw;
        max-height: 1.2vw;
        margin-right: 0.26vw;
    }

    .payment-description p {
        font-size: 1.46vw;
    }

    .payment-description span {
        font-size: 1.46vw;
    }

    .payment-header_icons svg {
        width: 0.6vw;
        height: 0.6vw;
    }

    .payment-image {
        display: flex;
        width: 50%;
    }
    
    .payment-image img {
        width: 100%;
        object-fit: contain;
        border: none;
        border-radius: 1.97vw;
    }
}

@media (max-width: 576px) {
    .payment {
        margin: 29.13vw 4.85vw 29.13vw 4.85vw; /* 120px 20px 120px 20px */
        max-width: 139.81vw; /* 576px */
        padding: 0 0 14.56vw 0; /* 0 0 60px 0 */
        border-bottom: 0.24vw solid #d9d9d9; /* 1px */
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }

    .payment-header,
    .payment-header_icons {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
    }

    .payment-header {
        margin-bottom: 7.28vw; /* 30px */
    }

    .payment-header span {
        font-size: 4.37vw; /* 18px */
        font-weight: 600;
        line-height: 4.96vw; /* 20.43px */
        text-align: left;
        margin-right: 2.43vw; /* 10px */
    }

    .payment-header_icons svg {
        width: 2.43vw; /* 10px */
        height: 2.43vw; /* 10px */
        animation: rotatePauseSvg 2.5s infinite;
    }

    @keyframes rotatePauseSvg {
        0% {
            transform: rotate(0);
        }
        20% {
            transform: rotate(0);
        }
        40% {
            transform: rotate(-90deg);
        }
        60% {
            transform: rotate(-90deg);
        }
        80% {
            transform: rotate(0);
        }
        100% {
            transform: rotate(0);
        }
    }

    .payment-title {
        margin-bottom: 8.5vw; /* 35px */
    }

    .payment-title h1 {
        font-size: 10.19vw; /* 42px */
        font-weight: 500;
        line-height: 11.57vw; /* 47.68px */
        text-align: left;
    }

    .payment-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-right: 0;
        margin-bottom: 7.28vw; /* 30px */
        width: 100%;
    }

    .payment-main {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }

    .payment-description {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
    }

    .payment-description svg {
        width: 100%;
        height: 100%;
        max-width: 3.64vw; /* 15px */
        max-height: 3.64vw; /* 15px */
        margin-right: 1.21vw; /* 5px */
    }

    .payment-description p {
        font-size: 4.85vw; /* 20px */
        line-height: 5.51vw; /* 22.71px */
        font-weight: 600;
        text-align: left;
        color: #000;
    }
    .payment-description span {
        font-size: 4.85vw; /* 20px */
        line-height: 5.51vw; /* 22.71px */
        font-weight: 600;
        text-align: left;
        color: #686868;
    }

    .payment-image {
        display: flex;
        width: 100%;
    }

    .payment-image img {
        width: 100%;
        object-fit: contain;
        border: none;
        border-radius: 4.85vw;
    }
}
