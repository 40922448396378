@font-face {
    font-family: "Saans TRIAL";
    src: url("../../assets/font/SaansTRIAL-Bold.eot");
    src: local("Saans TRIAL Bold"), local("SaansTRIAL-Bold"), url("../../assets/font/SaansTRIAL-Bold.eot?#iefix") format("embedded-opentype"),
        url("../../assets/font/SaansTRIAL-Bold.woff2") format("woff2"), url("../../assets/font/SaansTRIAL-Bold.woff") format("woff"),
        url("../../assets/font/SaansTRIAL-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Saans TRIAL";
    src: url("../../assets/font/SaansTRIAL-SemiBold.eot");
    src: local("Saans TRIAL SemiBold"), local("SaansTRIAL-SemiBold"), url("../../assets/font/SaansTRIAL-SemiBold.eot?#iefix") format("embedded-opentype"),
        url("../../assets/font/SaansTRIAL-SemiBold.woff2") format("woff2"), url("../../assets/font/SaansTRIAL-SemiBold.woff") format("woff"),
        url("../../assets/font/SaansTRIAL-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Saans TRIAL";
    src: url("../../assets/font/SaansTRIAL-Medium.eot");
    src: local("Saans TRIAL Medium"), local("SaansTRIAL-Medium"), url("../../assets/font/SaansTRIAL-Medium.eot?#iefix") format("embedded-opentype"),
        url("../../assets/font/SaansTRIAL-Medium.woff2") format("woff2"), url("../../assets/font/SaansTRIAL-Medium.woff") format("woff"),
        url("../../assets/font/SaansTRIAL-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Saans TRIAL";
    src: url("../../assets/font/SaansTRIAL-Regular.eot");
    src: local("Saans TRIAL Regular"), local("SaansTRIAL-Regular"), url("../../assets/font/SaansTRIAL-Regular.eot?#iefix") format("embedded-opentype"),
        url("../../assets/font/SaansTRIAL-Regular.woff2") format("woff2"), url("../../assets/font/SaansTRIAL-Regular.woff") format("woff"),
        url("../../assets/font/SaansTRIAL-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

.newsPage {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    max-width: 1920px;
    margin: 200px 70px 120px 70px;
    font-family: "Saans TRIAL";
}

.newsPage-header,
.newsPage-header_icons {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.newsPage-header {
    margin-bottom: 40px;
}

.newsPage-header span {
    font-size: 21px;
    font-weight: 600;
    line-height: 1.135;
    margin-right: 10px;
}

.newsPage-header_icons svg {
    animation: rotatePauseSvg 2.5s infinite;
}

@keyframes rotatePauseSvg {
    0% {
        transform: rotate(0);
    }
    20% {
        transform: rotate(0);
    }
    40% {
        transform: rotate(-90deg);
    }
    60% {
        transform: rotate(-90deg);
    }
    80% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(0);
    }
}

.newsPage-title {
    max-width: 65%;
    margin-bottom: 70px;
}
.newsPage-title h1 {
    font-size: 72px;
    font-weight: 500;
    line-height: 1.135;
}

.newsPage-cards {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 100%;
}

.newsPage-card {
    width: calc(33% - 15px);
    display: flex;
    flex-direction: column;
    margin-right: 30px;
    margin-bottom: 30px;
    text-decoration: none;
    color: #000;
}

.newsPage-card:nth-child(3n) {
    margin-right: 0;
}

.newsPage-card_image {
    margin-bottom: 10px;
    width: 100%;
    height: 100%;
    min-height: 384px;
    max-height: 384px;
    display: block;
    object-fit: cover;
    border-radius: 10px;
    border: none;
    outline: none;
}

.newsPage-card_content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 20px;
    border: none;
    border-radius: 10px;
    background-color: #f8f8f8;
    height: 100%;
}

.newsPage-card_date {
    font-size: 18px;
    font-weight: 400;
    line-height: 1.135;
    margin-bottom: 10px;
}

.newsPage-card_title {
    font-size: 24px;
    font-weight: 500;
    line-height: 1.135;
    margin-bottom: 10px;
}

.newsPage-card_description {
    font-size: 18px;
    line-height: 1.135;
    font-weight: 400;

    display: -webkit-box; /* Creates a flexible box layout */
    -webkit-line-clamp: 2; /* Limits the text to 2 lines */
    -webkit-box-orient: vertical; /* Specifies vertical stacking */
    overflow: hidden; /* Ensures content beyond 2 lines is hidden */
    text-overflow: ellipsis;
}

.newsPage-footer_container {
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
}

.newsPage-footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.newsPage-footer span {
    font-size: 21px;
    font-weight: 700;
    line-height: 1.135;
    margin: 0 10px;
}

.newsPage-footer svg {
    width: 13px;
    height: 13px;
}

@media (min-width: 576px) and (max-width: 1920px) {
    .newsPage {
        max-width: 100vw;
        margin: 10.4vw 3.65vw 6.25vw 3.65vw;
    }

    .newsPage-header {
        margin-bottom: 2.08vw;
    }

    .newsPage-header span {
        font-size: 1.09vw;
        margin-right: 0.52vw;
    }

    .newsPage-title {
        margin-bottom: 3.65vw;
    }

    .newsPage-title h1 {
        font-size: 3.75vw;
    }

    .newsPage-card {
        margin-right: 1.56vw;
        margin-bottom: 1.56vw;
    }

    .newsPage-card:nth-child(3n) {
        margin-right: 0;
    }

    .newsPage-card_image {
        margin-bottom: 0.52vw;
        height: 100%;
        min-height: 20vw;
        max-height: 20vw;
        border-radius: 0.52vw;
    }

    .newsPage-card_content {
        padding: 1.04vw;
        border-radius: 0.52vw;
    }

    .newsPage-card_date {
        font-size: 0.94vw;
        margin-bottom: 0.52vw;
    }

    .newsPage-card_title {
        font-size: 1.25vw;
        margin-bottom: 0.52vw;
    }

    .newsPage-card_description {
        font-size: 0.92vw;
    }

    .newsPage-header_icons svg {
        width: 0.6vw;
        height: 0.6vw;
    }

    .newsPage-footer_container {
        margin-top: 1.04vw;
    }

    .newsPage-footer span {
        font-size: 1.09vw;
        margin: 0 0.52vw;
    }

    .newsPage-footer svg {
        width: 0.67vw;
        height: 0.67vw;
    }
}
@media (min-width: 786px) and (max-width: 1520px) {
    .newsPage-card_date {
        font-size: 0.94vw;
        margin-bottom: 0.52vw;
    }

    .newsPage-card_title {
        font-size: 1.15vw;
        margin-bottom: 0.52vw;
    }

    .newsPage-card_description {
        font-size: 0.87vw;
    }
}
@media (min-width: 576px) and (max-width: 786px) {
    .newsPage-card_date {
        font-size: 0.74vw;
        margin-bottom: 0.52vw;
    }

    .newsPage-card_title {
        font-size: 1vw;
        margin-bottom: 0.52vw;
    }

    .newsPage-card_description {
        font-size: 0.77vw;
    }
}

@media (max-width: 576px) {
    .newsPage {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        max-width: 576px;
        margin: 24.27vw 4.85vw;
    }

    .newsPage-header,
    .newsPage-header_icons {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
    }

    .newsPage-header {
        margin-bottom: 7.28vw;
    }

    .newsPage-header span {
        font-size: 4.36vw;
        font-weight: 600;
        line-height: 4.95vw;
        text-align: left;

        margin-right: 2.42vw;
    }

    .newsPage-header_icons svg {
        width: 2.42vw;
        height: 2.42vw;
        animation: rotatePauseSvg 2.5s infinite;
    }

    @keyframes rotatePauseSvg {
        0% {
            transform: rotate(0);
        }
        20% {
            transform: rotate(0);
        }
        40% {
            transform: rotate(-90deg);
        }
        60% {
            transform: rotate(-90deg);
        }
        80% {
            transform: rotate(0);
        }
        100% {
            transform: rotate(0);
        }
    }

    .newsPage-title {
        max-width: 100%;
        margin-bottom: 9.7vw;
    }
    .newsPage-title h1 {
        font-size: 10.19vw;
        font-weight: 500;
        line-height: 11.57vw;
        text-align: left;
    }

    .newsPage-cards {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 100%;
    }

    .newsPage-card {
        width: 100%;
        flex: 1;
        display: flex;
        flex-direction: column;
        margin-right: 0;
        margin-bottom: 4.85vw;
    }

    .newsPage-card:last-child {
        margin-right: 0;
    }

    .newsPage-card_image {
        margin-bottom: 2.42vw;
        width: 100%;
        display: block;
        height: 100%;
        min-height: 60.43vw;
        max-height: 60.43vw;
        border-radius: 2.42vw;
    }

    .newsPage-card_content {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 4.85vw;
        border: none;
        border-radius: 2.42vw;
        background-color: #f8f8f8;
        height: auto;
    }

    .newsPage-card_date {
        font-size: 3.88vw;
        font-weight: 400;
        line-height: 4.4vw;
        margin-bottom: 2.42vw;
    }

    .newsPage-card_title {
        font-size: 4.85vw;
        font-weight: 500;
        line-height: 5.51vw;
        margin-bottom: 2.42vw;
    }

    .newsPage-card_description {
        font-size: 3.88vw;
        font-weight: 400;
        line-height: 4.4vw;
    }

    .newsPage-footer_container {
        margin-top: 4.85vw;
    }

    .newsPage-footer span {
        font-size: 4.36vw;
        margin: 0 1.21vw;
    }

    .newsPage-footer svg {
        width: 2.66vw;
        height: 2.66vw;
    }
}
