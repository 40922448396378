@font-face {
    font-family: "Saans TRIAL";
    src: url("../../assets/font/SaansTRIAL-Bold.eot");
    src: local("Saans TRIAL Bold"), local("SaansTRIAL-Bold"), url("../../assets/font/SaansTRIAL-Bold.eot?#iefix") format("embedded-opentype"),
        url("../../assets/font/SaansTRIAL-Bold.woff2") format("woff2"), url("../../assets/font/SaansTRIAL-Bold.woff") format("woff"),
        url("../../assets/font/SaansTRIAL-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Saans TRIAL";
    src: url("../../assets/font/SaansTRIAL-SemiBold.eot");
    src: local("Saans TRIAL SemiBold"), local("SaansTRIAL-SemiBold"), url("../../assets/font/SaansTRIAL-SemiBold.eot?#iefix") format("embedded-opentype"),
        url("../../assets/font/SaansTRIAL-SemiBold.woff2") format("woff2"), url("../../assets/font/SaansTRIAL-SemiBold.woff") format("woff"),
        url("../../assets/font/SaansTRIAL-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Saans TRIAL";
    src: url("../../assets/font/SaansTRIAL-Medium.eot");
    src: local("Saans TRIAL Medium"), local("SaansTRIAL-Medium"), url("../../assets/font/SaansTRIAL-Medium.eot?#iefix") format("embedded-opentype"),
        url("../../assets/font/SaansTRIAL-Medium.woff2") format("woff2"), url("../../assets/font/SaansTRIAL-Medium.woff") format("woff"),
        url("../../assets/font/SaansTRIAL-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Saans TRIAL";
    src: url("../../assets/font/SaansTRIAL-Regular.eot");
    src: local("Saans TRIAL Regular"), local("SaansTRIAL-Regular"), url("../../assets/font/SaansTRIAL-Regular.eot?#iefix") format("embedded-opentype"),
        url("../../assets/font/SaansTRIAL-Regular.woff2") format("woff2"), url("../../assets/font/SaansTRIAL-Regular.woff") format("woff"),
        url("../../assets/font/SaansTRIAL-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

.contacts {
    padding: 195px 70px 160px 70px;
    max-width: 1920px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    font-family: "Saans TRIAL";
}

.contacts-image {
    display: flex;
    flex-direction: column;
    margin-right: 53px;
    width: 50%;
}

.contacts-image img {
    object-fit: contain;
    display: block;
}

.contacts-image_footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.contacts-image_icons {
    display: flex;
    align-items: center;
    margin-top: 26px;
}

.contacts-image_icons span {
    margin-right: 50px;
}

.contacts-image_icons span:last-child {
    margin-right: 0;
}

.contacts-image_icons span svg {
    animation: rotatePause 2.5s infinite;
}

.contacts-image img {
    border: none;
    border-radius: 38px;
    max-width: 852px;
    object-fit: cover;
}

/*second container icons*/
.contatcs-container_footer {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 26px;
}
.contatcs-container_footer span svg {
    animation: rotatePause 2.5s infinite;
}

/*animation single spin*/
@keyframes rotatePause {
    0% {
        transform: rotate(0);
    }
    20% {
        transform: rotate(0);
    }
    40% {
        transform: rotate(-180deg);
    }
    60% {
        transform: rotate(-180deg);
    }
    80% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(0);
    }
}

.contacts-image_links {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-top: 26px;
}

.contacts-image_links a {
    font-size: 21px;
    font-weight: 600;
    line-height: 1.135;
    text-align: left;
    color: black;
    text-decoration: none;
    margin-right: 40px;
}

.contacts-image_links a:last-child {
    margin-right: 0px;
}

/*contacts main info */
.contacts-main {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 50%;
}

.contacts-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    /*not for responsive*/

    height: -webkit-fill-available;
}

.contacts-content_header,
.contacts-content_header-icons {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.contacts-content_header {
    margin-bottom: 40px;
}

.contacts-content_header span {
    font-size: 21px;
    font-weight: 600;
    line-height: 1.135;
    margin-right: 10px;
}

.contacts-content_header-icons svg {
    animation: rotatePauseSvg 2.5s infinite;
}

@keyframes rotatePauseSvg {
    0% {
        transform: rotate(0);
    }
    20% {
        transform: rotate(0);
    }
    40% {
        transform: rotate(-90deg);
    }
    60% {
        transform: rotate(-90deg);
    }
    80% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(0);
    }
}

.contacts-content_title h1 {
    font-size: 72px;
    font-weight: 500;
    line-height: 1.135;
    text-align: left;
}

.contacts-info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
}

.contacts-info_row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 20px 0;
    border-bottom: 1px solid #d9d9d9;
}

.contacts-info .contacts-info_row:first-child {
    border-top: 1px solid #d9d9d9;
}

.contacts-info_row p,
.contacts-info_row a {
    font-size: 21px;
    font-weight: 500;
    line-height: 1.135;
    text-align: left;
    text-decoration: none;
    color: black;
}

.contacts-info_row p {
    white-space: nowrap;
}
.contacts-info_row a {
    width: 53%;
}

@media (min-width: 576px) and (max-width: 1920px) {
    .contacts {
        padding: 10.16vw 3.65vw 8.33vw 3.65vw;
        max-width: 100vw;
    }

    .contacts-image {
        margin-right: 2.76vw;
    }

    .contacts-image img {
        object-fit: cover;
        border: none;
        border-radius: 1.97vw;
        max-width: 44.37vw;
    }

    .contacts-image_icons {
        margin-top: 1.35vw;
    }

    .contacts-image_icons span {
        margin-right: 2.6vw;
    }

    /*second container icons*/
    .contatcs-container_footer {
        margin-top: 1.35vw;
    }

    .contacts-image_links {
        margin-top: 1.35vw;
    }

    .contacts-image_links a {
        font-size: 1.09vw;
        margin-right: 2.08vw;
    }

    .contacts-image_links a:last-child {
        margin-right: 0;
    }

    .contacts-content_header {
        margin-bottom: 2.08vw;
    }

    .contacts-content_header span {
        font-size: 1.09vw;
        margin-right: 0.52vw;
    }

    .contacts-content_title h1 {
        font-size: 3.75vw;
    }

    .contacts-info_row {
        padding: 1.04vw 0;
    }

    .contacts-info_row p,
    .contacts-info_row a {
        font-size: 1.09vw;
    }

    .contacts-content_header-icons svg {
        width: 0.62vw;
        height: 0.62vw;
    }

    .contacts-info .contacts-info_row:first-child {
        border-top: 0.05vw solid #d9d9d9;
    }

    .contacts-info_row {
        border-bottom: 0.05vw solid #d9d9d9;
    }

    .contacts-image_icons svg,
    .contatcs-container_footer svg {
        width: 0.7vw;
        height: 0.7vw;
    }
}

/**/

.contacts-content_head-mobile,
.contacts-content_header-mobile,
.contacts-content_header-icons-mobile,
.contacts-content_title-mobile,
.contacts-info-mobile,
.contacts-info_row-mobile,
.contacts-image_icons-mobile,
.mySwiperContacts,
.custom-navigation {
    display: none;
}

/**/

@media (max-width: 576px) {
    /**/
    .contacts-main {
        display: none;
    }

    .contacts-content_head-mobile,
    .contacts-content_header-mobile,
    .contacts-content_header-icons-mobile {
        display: flex;
    }

    .contacts-info-mobile,
    .contacts-info_row-mobile,
    .contacts-image_icons-mobile {
        display: flex;
    }

    .contacts-content_title-mobile {
        display: block;
    }

    /**/
    .contacts {
        padding: 24.27vw 4.85vw;
        max-width: 576px;

        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
    }

    .contacts-image {
        display: flex;
        flex-direction: column;
        margin-right: 0;
        width: 100%;
    }

    .contacts-image img {
        object-fit: cover;
        display: block;
    }

    .contacts-image img {
        border: none;
        border-radius: 4.85vw;
        max-width: 100%;
        height: 79.46vw;
    }

    .contacts-image_footer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-top: 17.1vw;
    }

    .contacts-image_icons {
        display: flex;
        align-items: unset;
        margin-top: 0;
    }

    .contacts-image_icons span {
        margin-right: 4.85vw;
    }

    .contacts-image_icons span:last-child {
        margin-right: 0;
    }

    .contacts-image_icons span svg {
        animation: rotatePause 2.5s infinite;
    }

    .contacts-image_links {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        margin-top: 0;
    }

    .contacts-image_links a {
        font-size: 4.36vw;
        font-weight: 600;
        line-height: 4.95vw;
        text-align: left;
        color: black;
        text-decoration: none;
        margin-right: 2.42vw;
    }

    .contacts-image_links a:last-child {
        margin-right: 0px;
    }

    .contacts-content_head {
        display: flex;
        flex-direction: column;
    }

    .contacts-content_header,
    .contacts-content_header-icons {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
    }

    .contacts-content_header-icons {
        width: 11.16vw;
    }

    .contacts-content_header {
        margin-bottom: 7.28vw;
    }

    .contacts-content_header span {
        font-size: 4.36vw;
        font-weight: 600;
        line-height: 4.95vw;
        text-align: left;
        margin-right: 2.42;
    }

    .contacts-content_header-icons svg {
        width: 2.42vw;
        height: 2.42vw;
        animation: rotatePauseSvg 2.5s infinite;
    }

    @keyframes rotatePauseSvg {
        0% {
            transform: rotate(0);
        }
        20% {
            transform: rotate(0);
        }
        40% {
            transform: rotate(-90deg);
        }
        60% {
            transform: rotate(-90deg);
        }
        80% {
            transform: rotate(0);
        }
        100% {
            transform: rotate(0);
        }
    }

    .contacts-content_title h1 {
        font-size: 10.19vw;
        font-weight: 500;
        line-height: 11.57vw;
        text-align: left;
        margin-bottom: 9.7vw;
    }

    .contacts-info {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 100%;
    }

    .contacts-info_row {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: unset;
        width: 100%;
        padding: 4.85vw 0;
        border-bottom: 1px solid #d9d9d9;
    }

    .contacts-info .contacts-info_row:first-child {
        border-top: 0.24vw solid #d9d9d9;
    }

    .contacts-info_row p,
    .contacts-info_row a {
        font-size: 4.36vw;
        font-weight: 600;
        line-height: 4.95vw;
        text-align: left;
        text-decoration: none;
        color: black;
    }

    .contacts-info_row p {
        white-space: unset;
    }
    .contacts-info_row a {
        width: 100%;
        margin-top: 2.4vw;
    }

    /**/

    .mySwiperContacts {
        display: block;
        width: 100%;
        height: 100%;
    }

    .mySwiperContacts .swiper-slide {
        display: flex;
        justify-content: center;
    }

    /**/

    .custom-navigation {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin: 7.28vw 0 4.85vw 0;
    }

    .custom-control {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .custom-control button:first-child {
        margin-right: 3.64vw;
    }

    .custom-control button {
        position: relative;
        width: 7.76vw;
        height: 7.76vw;
        border: 0.24vw solid #000;
        border-radius: 50%;
        overflow: hidden;
        cursor: pointer;
        transition: transform 0.3s ease;
        display: flex;
        align-items: center;
        justify-content: center;
        background: transparent;
    }

    .custom-control button svg {
        width: 3.39vw;
        height: 3.39vw;
    }

    .custom-control button::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: #d9d9d9;
        top: 100%; /* Start below the button */
        left: 0;
        transition: top 0.5s ease;
        z-index: 0;
    }

    .custom-control button:hover::before {
        top: 0;
    }

    .svg-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        transition: transform 0.5s ease;
    }

    .custom-control button.custom-prev:hover .svg-wrapper {
        transform: translateX(-3.85vw);
    }
    .custom-control button.custom-prev .svg-wrapper {
        transform: translateX(3.85vw);
    }

    .custom-control button.custom-next:hover .svg-wrapper {
        transform: translateX(3.85vw);
    }

    .custom-control button.custom-next .svg-wrapper {
        transform: translateX(-3.85vw);
    }

    .custom-control button.custom-prev .svg-wrapper:first-child {
        margin-right: 3.85vw;
    }
    .custom-control button.custom-next .svg-wrapper:last-child {
        margin-left: 3.85vw;
    }

    .contacts-image_icons svg {
        width: 3.15vw;
        height: 3.15vw;
        display: list-item;
    }
}
