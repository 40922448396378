@font-face {
    font-family: "Saans TRIAL";
    src: url("../../assets/font/SaansTRIAL-Bold.eot");
    src: local("Saans TRIAL Bold"), local("SaansTRIAL-Bold"), url("../../assets/font/SaansTRIAL-Bold.eot?#iefix") format("embedded-opentype"),
        url("../../assets/font/SaansTRIAL-Bold.woff2") format("woff2"), url("../../assets/font/SaansTRIAL-Bold.woff") format("woff"),
        url("../../assets/font/SaansTRIAL-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Saans TRIAL";
    src: url("../../assets/font/SaansTRIAL-SemiBold.eot");
    src: local("Saans TRIAL SemiBold"), local("SaansTRIAL-SemiBold"),
        url("../../assets/font/SaansTRIAL-SemiBold.eot?#iefix") format("embedded-opentype"),
        url("../../assets/font/SaansTRIAL-SemiBold.woff2") format("woff2"), url("../../assets/font/SaansTRIAL-SemiBold.woff") format("woff"),
        url("../../assets/font/SaansTRIAL-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Saans TRIAL";
    src: url("../../assets/font/SaansTRIAL-Medium.eot");
    src: local("Saans TRIAL Medium"), local("SaansTRIAL-Medium"), url("../../assets/font/SaansTRIAL-Medium.eot?#iefix") format("embedded-opentype"),
        url("../../assets/font/SaansTRIAL-Medium.woff2") format("woff2"), url("../../assets/font/SaansTRIAL-Medium.woff") format("woff"),
        url("../../assets/font/SaansTRIAL-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Saans TRIAL";
    src: url("../../assets/font/SaansTRIAL-Regular.eot");
    src: local("Saans TRIAL Regular"), local("SaansTRIAL-Regular"), url("../../assets/font/SaansTRIAL-Regular.eot?#iefix") format("embedded-opentype"),
        url("../../assets/font/SaansTRIAL-Regular.woff2") format("woff2"), url("../../assets/font/SaansTRIAL-Regular.woff") format("woff"),
        url("../../assets/font/SaansTRIAL-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Mardoto";
    src: url("../../assets/font/Mardoto-Medium.woff2") format("woff2"), url("../../assets/font/Mardoto-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

.vacancy-card {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 20px;
    background-color: #f2f2f2;
    border: none;
    border-radius: 10px;
    /* max-height: 241px; */
}

.vacancy-card:nth-child(3n + 1) {
    margin-right: 30px;
}

.vacancy-card:nth-child(3n + 2) {
    margin-right: 30px;
    /* margin-bottom: 30px; */
}

.vacancy-card:nth-child(3n) {
    margin-right: 0;
    /* margin-bottom: 30px; */
}

.vacancy-card:nth-last-child(1),
.vacancy-card:nth-last-child(2),
.vacancy-card:nth-last-child(3) {
    margin-bottom: 0;
}

.vacancy-card h3 {
    font-size: 24px;
    font-weight: 500;
    line-height: 27.25px;
    text-align: left;

    margin-bottom: 60px;
}

.vacancy-card__description {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    max-width: 90%;
}

.vacancy-card__description p {
    font-size: 16px;
    font-weight: 400;
    line-height: 18.16px;
    text-align: left;
    /**/
    overflow: hidden;
    width: 100%;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.vacancy-card__description span {
    font-size: 16px;
    font-weight: 500;
    line-height: 18.16px;
    text-align: left;
    margin-bottom: 15px;
}

.vacancy-card_footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 20px;
}

.vacancy-card__details {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.vacancy-card__details span {
    font-size: 14px;
    font-weight: 500;
    line-height: 15.89px;
    text-align: left;
    /**/
    padding: 6px 12px;
    border: 1px solid black;
    border-radius: 30px;
    margin-right: 10px;
}

.vacancy-card__apply {
    border: none;
    outline: none;
    width: 42px;
    height: 42px;
    cursor: pointer;
    background: transparent;
}

@media (min-width: 576px) and (max-width: 1920px) {
    .vacancy-card {
        padding: 1.04vw;
        border-radius: 0.52vw;
        /* max-height: 12.55vw; */
    }

    .vacancy-card:nth-child(3n + 1) {
        margin-right: 1.56vw;
    }

    .vacancy-card:nth-child(3n + 2) {
        margin-right: 1.56vw;
        /* margin-bottom: 1.56vw; */
    }

    .vacancy-card:nth-child(3n) {
        margin-right: 0;
        /* margin-bottom: 1.56vw; */
    }

    .vacancy-card:nth-last-child(1),
    .vacancy-card:nth-last-child(2),
    .vacancy-card:nth-last-child(3) {
        margin-bottom: 0;
    }

    .vacancy-card h3 {
        font-size: 1.25vw;
        line-height: 1.135;
        margin-bottom: 3.125vw;
    }

    .vacancy-card__description p {
        font-size: 0.833vw;
        line-height: 1.135;
    }

    .vacancy-card__description span {
        font-size: 0.833vw;
        line-height: 1.135;
        margin-bottom: 0.76vw;
    }

    .vacancy-card_footer {
        margin-top: 1.04vw;
    }

    .vacancy-card__details span {
        font-size: 0.72vw;
        line-height: 1.135;
        /**/
        padding: 0.31vw 0.62vw;
        border: 0.05vw solid black;
        border-radius: 1.56vw;
        margin-right: 0.52vw;
    }

    .vacancy-card__apply {
        border: none;
        outline: none;
        width: 2.18vw;
        height: 2.18vw;
        cursor: pointer;
    }
    .vacancy-card__apply svg {
        width: 2.18vw;
        height: 2.18vw;
    }
}

@media (max-width: 576px) {
    .vacancy-card {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 4.85vw;
        background-color: #f2f2f2;
        border: none;
        border-radius: 2.4vw;
        max-height: none;
    }

    .vacancy-card:nth-child(3n + 1) {
        margin-right: unset;
        margin-bottom: unset;
    }

    .vacancy-card:nth-child(3n + 2) {
        margin-right: unset;
        margin-bottom: unset;
    }

    .vacancy-card:nth-child(3n) {
        margin-right: unset;
        margin-bottom: unset;
    }

    .vacancy-card:nth-last-child(1),
    .vacancy-card:nth-last-child(2),
    .vacancy-card:nth-last-child(3) {
        margin-bottom: unset;
    }

    .vacancy-card {
        margin-bottom: 4.85vw !important;
    }

    .vacancy-card:last-child {
        margin-bottom: 0 !important;
    }

    .vacancy-card h3 {
        font-size: 5vw;
        font-weight: 500;
        line-height: 1.135;
        text-align: left;

        margin-bottom: 9.7vw;
    }

    .vacancy-card__description {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        max-width: 80%;
    }

    .vacancy-card__description p {
        font-size: 3.88vw;
        font-weight: 400;
        line-height: 1.135;
        text-align: left;
    }

    .vacancy-card__description span {
        font-size: 3.88vw;
        font-weight: 500;
        line-height: 1.135;
        text-align: left;
        margin-bottom: 2.42vw;
    }

    .vacancy-card_footer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
        width: 100%;
        margin-top: 4.85vw;
    }

    .vacancy-card__details {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        flex-wrap: wrap;
        align-items: center;
        width: 90%;
    }

    .vacancy-card__details span {
        font-size: 2.9vw;
        font-weight: 500;
        line-height: 1.135;
        text-align: left;
        /**/
        padding: 1.21vw 2.9vw;
        border: 0.24vw solid black;
        border-radius: 7.28vw;
        margin-right: 2.42vw;
    }

    .vacancy-card__details span:last-child {
        margin-right: 0;
    }

    .vacancy-card__apply {
        border: none;
        outline: none;
        width: 7.76vw;
        height: 7.76vw;
        cursor: pointer;
    }
    .vacancy-card__apply svg {
        width: 7.76vw;
        height: 7.76vw;
    }
}
