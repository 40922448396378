@font-face {
    font-family: "Saans TRIAL";
    src: url("../../assets/font/SaansTRIAL-Bold.eot");
    src: local("Saans TRIAL Bold"), local("SaansTRIAL-Bold"), url("../../assets/font/SaansTRIAL-Bold.eot?#iefix") format("embedded-opentype"),
        url("../../assets/font/SaansTRIAL-Bold.woff2") format("woff2"), url("../../assets/font/SaansTRIAL-Bold.woff") format("woff"),
        url("../../assets/font/SaansTRIAL-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Saans TRIAL";
    src: url("../../assets/font/SaansTRIAL-SemiBold.eot");
    src: local("Saans TRIAL SemiBold"), local("SaansTRIAL-SemiBold"),
        url("../../assets/font/SaansTRIAL-SemiBold.eot?#iefix") format("embedded-opentype"),
        url("../../assets/font/SaansTRIAL-SemiBold.woff2") format("woff2"), url("../../assets/font/SaansTRIAL-SemiBold.woff") format("woff"),
        url("../../assets/font/SaansTRIAL-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Saans TRIAL";
    src: url("../../assets/font/SaansTRIAL-Medium.eot");
    src: local("Saans TRIAL Medium"), local("SaansTRIAL-Medium"), url("../../assets/font/SaansTRIAL-Medium.eot?#iefix") format("embedded-opentype"),
        url("../../assets/font/SaansTRIAL-Medium.woff2") format("woff2"), url("../../assets/font/SaansTRIAL-Medium.woff") format("woff"),
        url("../../assets/font/SaansTRIAL-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Saans TRIAL";
    src: url("../../assets/font/SaansTRIAL-Regular.eot");
    src: local("Saans TRIAL Regular"), local("SaansTRIAL-Regular"), url("../../assets/font/SaansTRIAL-Regular.eot?#iefix") format("embedded-opentype"),
        url("../../assets/font/SaansTRIAL-Regular.woff2") format("woff2"), url("../../assets/font/SaansTRIAL-Regular.woff") format("woff"),
        url("../../assets/font/SaansTRIAL-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Mardoto";
    src: url("../../assets/font/Mardoto-Medium.woff2") format("woff2"), url("../../assets/font/Mardoto-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

.mission {
    margin: 0 70px 160px 70px;
    max-width: 1920px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    font-family: "Saans TRIAL";
}

.mission-header,
.mission-header_icons {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.mission-header {
    margin-bottom: 40px;
}

.mission-header span {
    font-size: 21px;
    font-weight: 600;
    line-height: 1.135;
    margin-right: 10px;
}

.mission-header_icons svg {
    animation: rotatePauseSvg 2.5s infinite;
}

@keyframes rotatePauseSvg {
    0% {
        transform: rotate(0);
    }
    20% {
        transform: rotate(0);
    }
    40% {
        transform: rotate(-90deg);
    }
    60% {
        transform: rotate(-90deg);
    }
    80% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(0);
    }
}

.mission-title {
    max-width: 60%;
    margin-bottom: 70px;
}
.mission-title h1 {
    font-size: 72px;
    font-weight: 500;
    line-height: 1.135;
}

/**/

.mission-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    background-color: transparent;
    height: 830px;
}

/*mission description*/

.mission-desc {
    width: 40%;
    background-color: #243be2;
    border-radius: 0px 30px 30px 0px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;

    padding: 50px 110px;
}

/* Pagination dots */
.mission-pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70px;
}

.mission-pagination .dot {
    width: 8px;
    height: 8px;
    margin-right: 10px;
    border-radius: 50px;
    background-color: #adadad;
    transition: all 0.3s ease;
    cursor: pointer;
}

.mission-pagination .dot:last-child {
    margin-right: 0;
}

.mission-pagination .dot.active {
    width: 35px;
    height: 8px;
    background-color: white;
    transition: all 0.3s ease;
}

/*mission description content*/

.mission-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    color: #fff;
}

.mission-desc_header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.mission-desc_header svg {
    width: 18px;
    height: 18px;

    margin-bottom: 20px;
}

.mission-desc_header span {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
    line-height: 1.135;
    color: #fff;
    text-align: center;
}

.mission-content h2 {
    text-transform: uppercase;
    font-size: 65px;
    font-weight: 700;
    line-height: 1.135;
    margin-bottom: 20px;
    text-align: center;
}

.mission-content p {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.135;
    text-align: center;
}

/*mission animated*/

.mission-anim {
    position: relative;
    width: 60%;
    background-image: url("../../assets/mission_bg.svg");
    background-size: cover;
    border-radius: 30px 0 0 30px;
}

.animated-svg_1,
.animated-svg_2,
.animated-svg_3 {
    position: absolute;
    transform: rotate(180deg);
    transition: all 0.5s ease;
}

/*1st svg*/
.animated-svg_1.position-1 {
    bottom: 23.1%;
    left: 17.4%;
}

.animated-svg_1.position-2 {
    bottom: 54.4%;
    left: 17.4%;
}

.animated-svg_1.position-3 {
    bottom: 70.2%;
    left: 17.4%;
}

/*2nd svg*/
.animated-svg_2.position-1 {
    top: 15.5%;
    left: 44%;
}

.animated-svg_2.position-2 {
    top: 39%;
    left: 44%;
}

.animated-svg_2.position-3 {
    top: 62.4%;
    left: 44%;
}

/*3rd svg*/
.animated-svg_3.position-1 {
    bottom: 38.8%;
    right: 26.8%;
}

.animated-svg_3.position-2 {
    bottom: 54.4%;
    right: 26.8%;
}

.animated-svg_3.position-3 {
    bottom: 54.5%;
    right: 26.8%;
}

.custom-pagination {
    display: flex;
    align-items: baseline;
    justify-content: center;
    position: absolute;
    bottom: 5%;
    left: 50%;
    transform: translateX(-50%);
}

/* Number Styles */
.custom-number {
    font-size: 32px;
    font-weight: 500;
    line-height: 1.135;
    color: #818181; /* Default gray color */
    cursor: pointer;
    transition: color 0.3s ease;
}

.custom-number.active {
    color: #0047ff;
}

/* Progress Bar Styles */
.custom-progress {
    width: 102px;
    height: 2px;
    background-color: #818181;
    transition: background-color 0.3s ease, width 0.5s ease;
    margin: 0 5px;
}

.custom-progress.completed {
    background-color: #0047ff;
}

/*swipers*/

.swiper-slide {
    cursor: grab;
}

.titleSwiperMission {
    width: 100%;
    height: 76px !important;
    text-align: center;
}

.titleSwiperMission .swiper-slide {
    height: 76px !important;
}

.headerSwiperMission {
    width: 100%;
    height: 20px !important;
    text-align: center;
}

.headerSwiperMission .swiper-slide {
    height: 20px !important;
}

.descriptionSwiperMission {
    width: 100%;
    height: 80px !important;
    margin-top: 20px;
}

.descriptionSwiperMission .swiper-slide {
    width: 100%;
    height: 80px !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (min-width: 576px) and (max-width: 1920px) {
    .mission {
        margin: 0 3.65vw 8.33vw 3.65vw;
        max-width: 100vw;
    }

    .mission-header {
        margin-bottom: 2.08vw;
    }

    .mission-header span {
        font-size: 1.09vw;
        margin-right: 0.52vw;
    }

    .mission-header_icons svg {
        width: 0.63vw;
        height: 0.63vw;
    }

    .mission-title {
        margin-bottom: 3.64vw;
    }

    .mission-title h1 {
        font-size: 3.75vw;
    }

    .mission-container {
        height: 43.23vw;
    }

    .mission-desc {
        border-radius: 0 1.56vw 1.56vw 0;
        height: 100%;
        padding: 2.6vw 5.73vw;
    }

    /* Pagination dots */
    .mission-pagination {
        width: 3.65vw;
    }

    .mission-pagination .dot {
        width: 0.42vw;
        height: 0.42vw;
        margin-right: 0.52vw;
        border-radius: 50px;
    }

    .mission-pagination .dot:last-child {
        margin-right: 0;
    }

    .mission-pagination .dot.active {
        width: 1.82vw;
        height: 0.42vw;
    }

    .mission-desc_header svg {
        width: 0.94vw;
        height: 0.94vw;
        margin-bottom: 1.04vw;
    }

    .mission-desc_header span {
        font-size: 0.73vw;
        line-height: 0.83vw;
    }

    .mission-content h2 {
        font-size: 3.91vw;
        margin-bottom: 1.04vw;
    }

    .mission-content p {
        font-size: 0.94vw;
        line-height: 1.06vw;
    }

    /*mission animated*/
    .mission-anim {
        border-radius: 1.56vw 0 0 1.56vw;
    }

    /* Number Styles */
    .custom-number {
        font-size: 1.67vw;
    }

    /* Progress Bar Styles */
    .custom-progress {
        width: 5.31vw;
        height: 0.1vw;
        margin: 0 0.26vw;
    }

    .mission-anim svg {
        width: 2.8vw;
        height: 2.8vw;
    }

    .titleSwiperMission {
        height: 3.9vw !important;
    }
    .titleSwiperMission .swiper-slide {
        height: 3.9vw !important;
    }

    .headerSwiperMission {
        height: 1.02vw !important;
    }

    .headerSwiperMission .swiper-slide {
        height: 1.02vw !important;
    }

    .descriptionSwiperMission {
        height: 5vw !important;
        margin-top: 10px;
    }

    .descriptionSwiperMission .swiper-slide {
        height: 5vw !important;
    }

    .mission.arm-language .mission-content p {
        font-size: 0.92vw;
    }
}

@media (min-width: 950px) and (max-width: 1350px) {
    /*swipers*/

    .titleSwiperMission {
        height: 4.6vw !important;
    }
    .titleSwiperMission .swiper-slide {
        height: 4.6vw !important;
    }

    .headerSwiperMission {
        height: 20px !important;
    }

    .headerSwiperMission .swiper-slide {
        height: 20px !important;
    }

    .descriptionSwiperMission {
        height: 5vw !important;
        margin-top: 10px;
    }

    .descriptionSwiperMission .swiper-slide {
        height: 5vw !important;
    }
}

@media (min-width: 576px) and (max-width: 950px) {
    /*swipers*/

    .titleSwiperMission {
        height: 4.6vw !important;
    }
    .titleSwiperMission .swiper-slide {
        height: 4.6vw !important;
    }

    .headerSwiperMission {
        height: 16px !important;
    }

    .headerSwiperMission .swiper-slide {
        height: 16px !important;
    }

    .descriptionSwiperMission {
        height: 5vw !important;
        margin-top: 10px;
    }

    .descriptionSwiperMission .swiper-slide {
        height: 5vw !important;
    }

    .mission-desc_header svg {
        margin-bottom: 0.4vw;
    }

    .mission-desc {
        padding: 2.6vw 4.73vw;
    }
}

@media (max-width: 576px) {
    .mission {
        margin: 0 4.85vw 24.27vw 4.85vw;
        max-width: 576px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        font-family: "Saans TRIAL";
    }

    .mission-header,
    .mission-header_icons {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
    }

    .mission-header {
        margin-bottom: 7.28vw;
    }

    .mission-header span {
        font-size: 4.36vw;
        font-weight: 600;
        line-height: 1.135;
        margin-right: 2.42vw;
    }

    .mission-header_icons svg {
        animation: rotatePauseSvg 2.5s infinite;
    }

    @keyframes rotatePauseSvg {
        0% {
            transform: rotate(0);
        }
        20% {
            transform: rotate(0);
        }
        40% {
            transform: rotate(-90deg);
        }
        60% {
            transform: rotate(-90deg);
        }
        80% {
            transform: rotate(0);
        }
        100% {
            transform: rotate(0);
        }
    }

    .mission-title {
        max-width: 100%;
        margin-bottom: 7.28vw;
    }
    .mission-title h1 {
        font-size: 10.19vw;
        font-weight: 500;
        line-height: 1.135;
    }

    .mission.arm-language .mission-title h1 {
        font-size: 7.9vw;
        line-height: 1.135;
    }

    .mission.arm-language .mission-desc_header span {
        font-size: 2.21vw;
    }

    .mission.arm-language .mission-content p {
        font-size: 3.38vw;
    }
    /**/

    .mission-container {
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        width: 100%;
        background-color: transparent;
        height: auto;
    }

    /*mission description*/

    .mission-desc {
        width: 100%;
        background-color: #243be2;
        border-radius: 7.28vw 7.28vw 0 0px;

        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        height: 100%;

        padding: 4.85vw 5.33vw;
    }

    /* Pagination dots */
    .mission-pagination {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 16.9vw;
    }

    .mission-pagination .dot {
        width: 1.94vw;
        height: 1.94vw;
        margin-right: 2.42vw;
        border-radius: 12.1vw;
        background-color: #adadad;
        transition: all 0.3s ease;
        cursor: pointer;
    }

    .mission-pagination .dot:last-child {
        margin-right: 0;
    }

    .mission-pagination .dot.active {
        width: 8.49vw;
        height: 1.94vw;
        background-color: white;
        transition: all 0.3s ease;
    }

    /*mission description content*/

    .mission-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        color: #fff;
        height: 84.95vw;
    }

    .mission-desc_header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .mission-desc_header svg {
        width: 3.39vw;
        height: 3.39vw;

        margin-bottom: 3.64vw;
    }

    .mission-desc_header span {
        text-transform: uppercase;
        font-size: 2.91vw;
        font-weight: 700;
        line-height: 1.135;
        color: #fff;
        text-align: center;
    }

    .mission-content h2 {
        text-transform: uppercase;
        font-size: 8.73vw;
        font-weight: 700;
        line-height: 1.135;
        margin-bottom: 4.85vw;
        text-align: center;
    }

    .mission-content p {
        font-size: 3.88vw;
        font-weight: 500;
        line-height: 1.135;
        text-align: center;
    }

    /*mission animated*/

    .mission-anim {
        position: relative;
        width: 100%;
        /*need to change bg image for mobile*/
        background-image: url("../../assets/mission_bg-mobile.svg");
        background-size: cover;
        border-radius: 0 0 7.28vw 7.28vw;
        height: 84.95vw;
    }

    .animated-svg_1,
    .animated-svg_2,
    .animated-svg_3 {
        position: absolute;
        transform: rotate(180deg);
        transition: all 0.5s ease;
        width: 5.2vw;
        height: 5.2vw;
    }

    /*1st svg*/
    .animated-svg_1.position-1 {
        bottom: 23.1%;
        left: 17.4%;
    }

    .animated-svg_1.position-2 {
        bottom: 54.4%;
        left: 17.4%;
    }

    .animated-svg_1.position-3 {
        bottom: 70.2%;
        left: 17.4%;
    }

    /*2nd svg*/
    .animated-svg_2.position-1 {
        top: 15.5%;
        left: 44%;
    }

    .animated-svg_2.position-2 {
        top: 39%;
        left: 44%;
    }

    .animated-svg_2.position-3 {
        top: 62.4%;
        left: 44%;
    }

    /*3rd svg*/
    .animated-svg_3.position-1 {
        bottom: 38.8%;
        right: 26.8%;
    }

    .animated-svg_3.position-2 {
        bottom: 54.4%;
        right: 26.8%;
    }

    .animated-svg_3.position-3 {
        bottom: 54.5%;
        right: 26.8%;
    }

    .custom-pagination {
        display: flex;
        align-items: baseline;
        justify-content: center;
        position: absolute;
        bottom: 5%;
        left: 50%;
        transform: translateX(-50%);
    }

    /* Number Styles */
    .custom-number {
        font-size: 3.64vw;
        font-weight: 700;
        line-height: 1.135;
        color: #818181; /* Default gray color */
        cursor: pointer;
        transition: color 0.3s ease;
    }

    .custom-number.active {
        color: #0047ff;
    }

    /* Progress Bar Styles */
    .custom-progress {
        width: 25.7vw;
        height: 0.48vw;
        background-color: #818181;
        transition: background-color 0.3s ease, width 0.5s ease;
        margin: 0 1.21vw;
    }

    .custom-progress.completed {
        background-color: #0047ff;
    }

    /*swipers*/

    .titleSwiperMission {
        width: 100%;
        height: 9.708vw !important;
        text-align: center;
    }

    .titleSwiperMission .swiper-slide {
        height: 9.708vw !important;
    }

    /* .headerSwiperMission {
        width: 100%;
        height: 4.36vw !important;
        text-align: center;
    }

    .headerSwiperMission .swiper-slide {
        height: 4.36vw !important;
    } */

    .descriptionSwiperMission {
        width: 100%;
        height: 21.844vw !important;
        margin-top: 20px;
    }

    .descriptionSwiperMission .swiper-slide {
        width: 100%;
        height: 21.844vw !important;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .mission-content {
        width: 100%;
    }
    .mission-content p {
        width: 100%;
    }
}
