@font-face {
    font-family: "Saans TRIAL";
    src: url("../../assets/font/SaansTRIAL-Bold.eot");
    src: local("Saans TRIAL Bold"), local("SaansTRIAL-Bold"), url("../../assets/font/SaansTRIAL-Bold.eot?#iefix") format("embedded-opentype"),
        url("../../assets/font/SaansTRIAL-Bold.woff2") format("woff2"), url("../../assets/font/SaansTRIAL-Bold.woff") format("woff"),
        url("../../assets/font/SaansTRIAL-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Saans TRIAL";
    src: url("../../assets/font/SaansTRIAL-SemiBold.eot");
    src: local("Saans TRIAL SemiBold"), local("SaansTRIAL-SemiBold"),
        url("../../assets/font/SaansTRIAL-SemiBold.eot?#iefix") format("embedded-opentype"),
        url("../../assets/font/SaansTRIAL-SemiBold.woff2") format("woff2"), url("../../assets/font/SaansTRIAL-SemiBold.woff") format("woff"),
        url("../../assets/font/SaansTRIAL-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Saans TRIAL";
    src: url("../../assets/font/SaansTRIAL-Medium.eot");
    src: local("Saans TRIAL Medium"), local("SaansTRIAL-Medium"), url("../../assets/font/SaansTRIAL-Medium.eot?#iefix") format("embedded-opentype"),
        url("../../assets/font/SaansTRIAL-Medium.woff2") format("woff2"), url("../../assets/font/SaansTRIAL-Medium.woff") format("woff"),
        url("../../assets/font/SaansTRIAL-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Saans TRIAL";
    src: url("../../assets/font/SaansTRIAL-Regular.eot");
    src: local("Saans TRIAL Regular"), local("SaansTRIAL-Regular"), url("../../assets/font/SaansTRIAL-Regular.eot?#iefix") format("embedded-opentype"),
        url("../../assets/font/SaansTRIAL-Regular.woff2") format("woff2"), url("../../assets/font/SaansTRIAL-Regular.woff") format("woff"),
        url("../../assets/font/SaansTRIAL-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Mardoto";
    src: url("../../assets/font/Mardoto-Medium.woff2") format("woff2"), url("../../assets/font/Mardoto-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

.team {
    margin: 0 70px;
    padding-bottom: 130px;
    max-width: 1920px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border-bottom: 1px solid #d9d9d9;
    font-family: "Saans TRIAL";
}

.team-header,
.team-header_icons {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.team-header {
    margin-bottom: 40px;
}

.team-header span {
    font-size: 21px;
    font-weight: 600;
    line-height: 1.135;
    margin-right: 10px;
}

.team-header_icons svg {
    animation: rotatePauseSvg 2.5s infinite;
}

@keyframes rotatePauseSvg {
    0% {
        transform: rotate(0);
    }
    20% {
        transform: rotate(0);
    }
    40% {
        transform: rotate(-90deg);
    }
    60% {
        transform: rotate(-90deg);
    }
    80% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(0);
    }
}

.team-title {
    max-width: 40%;
    margin-bottom: 20px;
}
.team-title h1 {
    font-size: 72px;
    font-weight: 500;
    line-height: 1.135;
    text-align: left;
}

#team-pagination_container,
#team-pagination_container_second {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 40px;
}

.swiper-pagination-bullet {
    width: 8px;
    height: 8px;
    background-color: #6a6a6d;
    opacity: 1;
    cursor: pointer;
    transition: all 0.3s ease;
}

.swiper-pagination-bullet-active {
    width: 35px;
    height: 8px;
    background-color: #000;
    border-radius: 50px;
}

/*card*/

.team-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.team-card img {
    width: 100%;
    height: auto;
    border-radius: 10px;
    margin-bottom: 10px;
}

.team-card_content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    background-color: #f2f2f2;
    border-radius: 10px;
    padding: 20px;
}

.team-card_content span {
    font-size: 24px;
    font-weight: 500;
    line-height: 1.135;
    text-align: left;
    margin-bottom: 10px;
}

.team-card_role {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.135;
    text-align: left;
    margin-bottom: 10px;
}

.team-card_description {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    /* align-items: center; */
    width: 100%;
}

.team-card_description p {
    font-size: 18px;
    font-weight: 400;
    line-height: 1.135;
    text-align: left;
    margin-right: 10px;
}

.team-description {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    overflow: hidden;
    transition: max-height 0.3s ease;
    max-height: 40px;
}

.team-description-expanded {
    max-height: 200px;
    transition: max-height 0.3s ease;
}

.team-card_description span {
    cursor: pointer;
}

.team-rotate-icon .rotatable-rect {
    transform: rotate(-90deg);
    transform-origin: center;
    transition: transform 0.3s ease;
}

.teamSwiper,
.sliderTeam {
    width: 100%;
    height: 100%;
}

.teamSwiper .swiper-slide,
.sliderTeam .swiper-slide {
    width: 100%;
    height: 100%;
}

.team-navigation {
    background-color: #e6e6e6;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px;
    border-radius: 30px;
    max-width: fit-content;
}

.team-prev,
.team-next {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 15px;
    border-radius: 30px;
    border: none;
    outline: none;

    font-size: 18px;
    font-weight: 500;
    line-height: 1.135;
    color: #8e8e8e;
    background-color: #f2f2f2;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;
    z-index: 1;
}

.team-prev {
    margin-right: 10px;
}

.team-prev::before,
.team-next::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.3s ease;
    z-index: -1;
}

.team-prev.swiper-button-disabled::before {
    transform: scaleX(1);
    transform-origin: left;
}

.team-next::before {
    transform-origin: right;
}

.team-next.swiper-button-disabled::before {
    transform: scaleX(1);
    transform-origin: right;
}

.team-prev.swiper-button-disabled,
.team-next.swiper-button-disabled {
    color: #fff;
}

.team_license-wrapper {
    display: flex;
    justify-content: end;
    width: 100%;
}

.team_license {
    display: flex;
    flex-direction: column;
    /* width: 32%; */
    width: fit-content;
    margin-top: 50px;
}

.team.team.arm-language .team_license p {
    font-size: 24px;
}

.team_license p {
    font-size: 26px;
    font-weight: 500;
    line-height: 29.52px;
    text-align: left;
}

.team_license-links {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 20px;
}

.team_license-links a {
    color: #000;
    font-size: 21px;
    font-weight: 700;
    line-height: 23.84px;
    text-align: left;
    text-decoration-line: underline;
    text-decoration-style: solid;

    display: flex;
    flex-direction: row;
    align-items: center;
}

.team_license-links a svg {
    margin-left: 5px;
    width: 18px;
    height: 18px;
}

.team_license-links a:last-child {
    margin-left: 58px;
}

.team.arm-language .team-title {
    max-width: 43%;
}

@media (min-width: 576px) and (max-width: 1920px) {
    .team {
        margin: 0 3.64vw;
        padding-bottom: 6.77vw;
        max-width: 1920px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        border-bottom: 0.05vw solid #d9d9d9;
    }

    .team-header,
    .team-header_icons {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
    }

    .team-header {
        margin-bottom: 2.08vw;
    }

    .team-header span {
        font-size: 1.09vw;
        font-weight: 600;
        line-height: 1.135;
        margin-right: 0.5vw;
    }

    .team-header_icons svg {
        width: 0.625vw;
        height: 0.625vw;
        animation: rotatePauseSvg 2.5s infinite;
    }

    .team.team.arm-language .team_license p {
        font-size: 1.25vw;
    }

    @keyframes rotatePauseSvg {
        0% {
            transform: rotate(0);
        }
        20% {
            transform: rotate(0);
        }
        40% {
            transform: rotate(-90deg);
        }
        60% {
            transform: rotate(-90deg);
        }
        80% {
            transform: rotate(0);
        }
        100% {
            transform: rotate(0);
        }
    }

    .team-title {
        max-width: 40%;
        margin-bottom: 1.04vw;
    }
    .team-title h1 {
        font-size: 3.75vw;
        font-weight: 500;
        line-height: 4.25vw;
        text-align: left;
    }

    #team-pagination_container,
    #team-pagination_container_second {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-bottom: 2.08vw;
    }

    .swiper-pagination-bullet {
        width: 0.41vw;
        height: 0.41vw;
        background-color: #6a6a6d;
        opacity: 1;
        cursor: pointer;
        transition: all 0.3s ease;
    }

    .swiper-pagination-bullet-active {
        width: 1.82vw;
        height: 0.41vw;
        background-color: #000;
        border-radius: 2.6vw;
    }

    /*card*/

    .team-card {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .team-card img {
        width: 100%;
        height: auto;
        border-radius: 0.5vw;
        margin-bottom: 0.5vw;
    }

    .team-card_content {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 100%;
        background-color: #f2f2f2;
        border-radius: 0.5vw;
        padding: 1.04vw;
    }

    .team-card_content span {
        font-size: 1.25vw;
        font-weight: 500;
        line-height: 1.41vw;
        text-align: left;
        margin-bottom: 0.5vw;
    }

    .team-card_role {
        font-size: 0.83vw;
        font-weight: 500;
        line-height: 0.94vw;
        text-align: left;
        margin-bottom: 0.5vw;
    }

    .team-card_description {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        /* align-items: center; */
        width: 100%;
    }

    .team-card_description svg {
        width: 1.3vw;
        height: 1.3vw;
    }

    .team-card_description p {
        font-size: 0.93vw;
        font-weight: 400;
        line-height: 1.06vw;
        text-align: left;
        margin-right: 0.52vw;
    }

    .team-description {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        width: 100%;
        overflow: hidden;
        transition: max-height 0.3s ease;
        max-height: 2.08vw;
    }

    .team-description-expanded {
        max-height: 10.41vw;
        transition: max-height 0.3s ease;
    }

    .team-card_description span {
        cursor: pointer;
    }

    .team-rotate-icon .rotatable-rect {
        transform: rotate(-90deg);
        transform-origin: center;
        transition: transform 0.3s ease;
    }

    .teamSwiper,
    .sliderTeam {
        width: 100%;
        height: 100%;
    }

    .teamSwiper .swiper-slide,
    .sliderTeam .swiper-slide {
        width: 100%;
        height: 100%;
    }

    .team-navigation {
        background-color: #e6e6e6;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0.26vw;
        border-radius: 1.56vw;
        max-width: fit-content;
    }

    .team-prev,
    .team-next {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.41vw 0.78vw;
        border-radius: 1.56vw;
        border: none;
        outline: none;

        font-size: 0.93vw;
        font-weight: 500;
        line-height: 1.06vw;
        color: #8e8e8e;
        background-color: #f2f2f2;
        cursor: pointer;
        transition: all 0.3s ease;
        position: relative;
        overflow: hidden;
        z-index: 1;
    }

    .team-prev::before,
    .team-next::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #000;
        transform: scaleX(0);
        transform-origin: left;
        transition: transform 0.3s ease;
        z-index: -1;
    }

    .team-prev.swiper-button-disabled::before {
        transform: scaleX(1);
        transform-origin: left;
    }

    .team-next::before {
        transform-origin: right;
    }

    .team-next.swiper-button-disabled::before {
        transform: scaleX(1);
        transform-origin: right;
    }

    .team-prev.swiper-button-disabled,
    .team-next.swiper-button-disabled {
        color: #fff;
    }

    .team_license-wrapper {
        display: flex;
        justify-content: end;
        width: 100%;
    }

    .team_license {
        display: flex;
        flex-direction: column;
        /* width: 31%; */
        width: fit-content;
        margin-top: 2.6vw;
    }

    .team_license p {
        font-size: 1.35vw;
        font-weight: 500;
        line-height: 1.53vw;
        text-align: left;
    }

    .team_license-links {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin-top: 1.04vw;
    }

    .team_license-links a {
        font-size: 1.09vw;
        font-weight: 700;
        line-height: 1.24vw;
        text-align: left;
        text-decoration-line: underline;
        text-decoration-style: solid;

        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .team_license-links a svg {
        margin-left: 0.26vw;
        width: 0.93vw;
        height: 0.93vw;
    }

    .team_license-links a:last-child {
        margin-left: 2.02vw;
    }

    .team-prev {
        margin-right: 0.6vw;
    }
}

.team-first-navigation,
.team-second-navigation {
    display: none;
}

@media (max-width: 576px) {
    .team {
        margin: 0 4.85vw;
        padding-bottom: 14.56vw;
        max-width: 576px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        border-bottom: 0.24vw solid #d9d9d9;
        font-family: "Saans TRIAL";
    }

    .team-header,
    .team-header_icons {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
    }

    .team-header {
        margin-bottom: 7.28vw;
    }

    .team-header span {
        font-size: 4.36vw;
        font-weight: 600;
        line-height: 1.135;
        margin-right: 2.42vw;
    }

    .team-header_icons svg {
        width: 2.42vw;
        height: 2.42vw;
        animation: rotatePauseSvg 2.5s infinite;
    }

    .team.arm-language .team-title {
        max-width: 100%;
    }

    @keyframes rotatePauseSvg {
        0% {
            transform: rotate(0);
        }
        20% {
            transform: rotate(0);
        }
        40% {
            transform: rotate(-90deg);
        }
        60% {
            transform: rotate(-90deg);
        }
        80% {
            transform: rotate(0);
        }
        100% {
            transform: rotate(0);
        }
    }

    .team-title {
        max-width: 100%;
        margin-bottom: 9.7vw;
    }

    .team-title h1 {
        font-size: 10.19vw;
        font-weight: 500;
        line-height: 1.135;
        text-align: left;
    }

    .team.arm-language .team-title h1 {
        font-size: 7.9vw;
    }

    #team-pagination_container,
    #team-pagination_container_second {
        display: none;
    }

    .team-first-navigation {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin: 0 0 4.85vw 0;
    }

    .team-first-control {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .team-first-control button:first-child {
        margin-right: 3.64vw;
    }

    .team.team.arm-language .team_license p {
        font-size: 4.05vw;
    }

    .team-first-control button {
        position: relative;
        width: 7.76vw;
        height: 7.76vw;
        border: 0.24vw solid #000;
        border-radius: 50%;
        overflow: hidden;
        cursor: pointer;
        transition: transform 0.3s ease;
        display: flex;
        align-items: center;
        justify-content: center;
        background: transparent;
    }

    .team-first-control button svg {
        width: 3.39vw;
        height: 3.39vw;
    }

    .team-first-control button::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: #d9d9d9;
        top: 100%; /* Start below the button */
        left: 0;
        transition: top 0.5s ease;
        z-index: 0;
    }

    .team-first-control button:hover::before {
        top: 0;
    }

    .svg-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        transition: transform 0.5s ease;
    }

    .team-first-control button.team-first-prev:hover .svg-wrapper {
        transform: translateX(-3.85vw);
    }
    .team-first-control button.team-first-prev .svg-wrapper {
        transform: translateX(3.85vw);
    }

    .team-first-control button.team-first-next:hover .svg-wrapper {
        transform: translateX(3.85vw);
    }

    .team-first-control button.team-first-next .svg-wrapper {
        transform: translateX(-3.85vw);
    }

    .team-first-control button.team-first-prev .svg-wrapper:first-child {
        margin-right: 3.85vw;
    }
    .team-first-control button.team-first-next .svg-wrapper:last-child {
        margin-left: 3.85vw;
    }

    .team-second-navigation {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin: 0 0 4.85vw 0;
    }

    .team-second-control {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .team-second-control button:first-child {
        margin-right: 3.64vw;
    }

    .team-second-control button {
        position: relative;
        width: 7.76vw;
        height: 7.76vw;
        border: 0.24vw solid #000;
        border-radius: 50%;
        overflow: hidden;
        cursor: pointer;
        transition: transform 0.3s ease;
        display: flex;
        align-items: center;
        justify-content: center;
        background: transparent;
    }

    .team-second-control button svg {
        width: 3.39vw;
        height: 3.39vw;
    }

    .team-second-control button::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: #d9d9d9;
        top: 100%; /* Start below the button */
        left: 0;
        transition: top 0.5s ease;
        z-index: 0;
    }

    .team-second-control button:hover::before {
        top: 0;
    }

    .svg-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        transition: transform 0.5s ease;
    }

    .team-second-control button.team-second-prev:hover .svg-wrapper {
        transform: translateX(-3.85vw);
    }
    .team-second-control button.team-second-prev .svg-wrapper {
        transform: translateX(3.85vw);
    }

    .team-second-control button.team-second-next:hover .svg-wrapper {
        transform: translateX(3.85vw);
    }

    .team-second-control button.team-second-next .svg-wrapper {
        transform: translateX(-3.85vw);
    }

    .team-second-control button.team-second-prev .svg-wrapper:first-child {
        margin-right: 3.85vw;
    }
    .team-second-control button.team-second-next .svg-wrapper:last-child {
        margin-left: 3.85vw;
    }
    /*card*/

    .team-card {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .team-card img {
        width: 100%;
        height: auto;
        border-radius: 2.42vw;
        margin-bottom: 2.42vw;
    }

    .team-card_content {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 100%;
        background-color: #f2f2f2;
        border-radius: 2.42vw;
        padding: 4.85vw;
    }

    .team-card_content span {
        font-size: 4.85vw;
        font-weight: 500;
        line-height: 1.135;
        text-align: left;
        margin-bottom: 2.42vw;
    }

    .team-card_role {
        font-size: 3.39vw;
        font-weight: 500;
        line-height: 1.135;
        text-align: left;
        margin-bottom: 2.42vw;
    }

    .team-card_description {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        /* align-items: center; */
        width: 100%;
    }

    .team-card_description p {
        font-size: 3.88vw;
        font-weight: 400;
        line-height: 1.135;
        text-align: left;
        margin-right: 2.42vw;
    }

    .team-description {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        width: 100%;
        overflow: hidden;
        transition: max-height 0.3s ease;
        max-height: 8.73vw;
    }

    .team-description-expanded {
        max-height: 32.76vw;
        transition: max-height 0.3s ease;
    }

    .team-card_description span {
        cursor: pointer;
    }

    .team-rotate-icon .rotatable-rect {
        transform: rotate(-90deg);
        transform-origin: center;
        transition: transform 0.3s ease;
    }

    .teamSwiper,
    .sliderTeam {
        width: 100%;
        height: 100%;
    }

    .teamSwiper .swiper-slide,
    .sliderTeam .swiper-slide {
        width: 100%;
        height: 100%;
    }

    .team-navigation {
        background-color: #e6e6e6;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 1.21vw;
        border-radius: 7.28vw;
        max-width: fit-content;
        margin-bottom: 4.85vw;
    }

    .team-prev {
        margin-right: 2.42vw;
    }

    .team-prev,
    .team-next {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1.94vw 3.64vw;
        border-radius: 6.9vw;
        border: none;
        outline: none;

        font-size: 4.36vw;
        font-weight: 500;
        line-height: 1.135;
        color: #8e8e8e;
        background-color: #f2f2f2;
        cursor: pointer;
        transition: all 0.3s ease;
        position: relative;
        overflow: hidden;
        z-index: 1;
    }

    .team-prev::before,
    .team-next::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #000;
        transform: scaleX(0);
        transform-origin: left;
        transition: transform 0.3s ease;
        z-index: -1;
    }

    .team-prev.swiper-button-disabled::before {
        transform: scaleX(1);
        transform-origin: left;
    }

    .team-next::before {
        transform-origin: right;
    }

    .team-next.swiper-button-disabled::before {
        transform: scaleX(1);
        transform-origin: right;
    }

    .team-prev.swiper-button-disabled,
    .team-next.swiper-button-disabled {
        color: #fff;
    }

    .team_license-wrapper {
        display: flex;
        justify-content: end;
        width: 100%;
    }

    .team_license {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        margin-top: 16.99vw;
    }

    .team_license p {
        font-size: 4.85vw;
        font-weight: 500;
        line-height: 1.135;
        text-align: left;
    }

    .team_license-links {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-top: 4.85vw;
    }

    .team_license-links a {
        font-size: 4.36vw;
        font-weight: 700;
        line-height: 1.135;
        text-align: left;
        text-decoration-line: underline;
        text-decoration-style: solid;

        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .team_license-links a svg {
        margin-left: 1.21vw;
        width: 3.88vw;
        height: 3.88vw;
    }

    .team_license-links a:last-child {
        margin-left: 0;
        margin-top: 3.64vw;
    }
}
