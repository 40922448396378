/* Hide scrollbar for all browsers */
:root::-webkit-scrollbar {
    display: none;
}

:root {
    scrollbar-width: none;
    -ms-overflow-style: none;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html{
    scroll-behavior: smooth;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* font-family: 'Saans TRIAL'; */
    background-color: #fff;
    max-width: 1920px;
    margin: 0 auto;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
