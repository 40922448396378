@font-face {
    font-family: "Saans TRIAL";
    src: url("../../assets/font/SaansTRIAL-Bold.eot");
    src: local("Saans TRIAL Bold"), local("SaansTRIAL-Bold"), url("../../assets/font/SaansTRIAL-Bold.eot?#iefix") format("embedded-opentype"),
        url("../../assets/font/SaansTRIAL-Bold.woff2") format("woff2"), url("../../assets/font/SaansTRIAL-Bold.woff") format("woff"),
        url("../../assets/font/SaansTRIAL-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Saans TRIAL";
    src: url("../../assets/font/SaansTRIAL-SemiBold.eot");
    src: local("Saans TRIAL SemiBold"), local("SaansTRIAL-SemiBold"),
        url("../../assets/font/SaansTRIAL-SemiBold.eot?#iefix") format("embedded-opentype"),
        url("../../assets/font/SaansTRIAL-SemiBold.woff2") format("woff2"), url("../../assets/font/SaansTRIAL-SemiBold.woff") format("woff"),
        url("../../assets/font/SaansTRIAL-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Saans TRIAL";
    src: url("../../assets/font/SaansTRIAL-Medium.eot");
    src: local("Saans TRIAL Medium"), local("SaansTRIAL-Medium"), url("../../assets/font/SaansTRIAL-Medium.eot?#iefix") format("embedded-opentype"),
        url("../../assets/font/SaansTRIAL-Medium.woff2") format("woff2"), url("../../assets/font/SaansTRIAL-Medium.woff") format("woff"),
        url("../../assets/font/SaansTRIAL-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Saans TRIAL";
    src: url("../../assets/font/SaansTRIAL-Regular.eot");
    src: local("Saans TRIAL Regular"), local("SaansTRIAL-Regular"), url("../../assets/font/SaansTRIAL-Regular.eot?#iefix") format("embedded-opentype"),
        url("../../assets/font/SaansTRIAL-Regular.woff2") format("woff2"), url("../../assets/font/SaansTRIAL-Regular.woff") format("woff"),
        url("../../assets/font/SaansTRIAL-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Mardoto";
    src: url("../../assets/font/Mardoto-Medium.woff2") format("woff2"), url("../../assets/font/Mardoto-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

.materials {
    margin: 200px 70px 0 70px;
    max-width: 1920px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    font-family: "Saans TRIAL";

    padding-bottom: 70px;
}

.materials_cards-header,
.materials_cards-header_icons {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.materials_cards-header {
    margin-bottom: 40px;
}

.materials_cards-header span {
    font-size: 21px;
    font-weight: 600;
    line-height: 1.135;
    margin-right: 10px;
}

.materials_cards-header_icons svg {
    animation: rotatePauseSvg 2.5s infinite;
}

@keyframes rotatePauseSvg {
    0% {
        transform: rotate(0);
    }
    20% {
        transform: rotate(0);
    }
    40% {
        transform: rotate(-90deg);
    }
    60% {
        transform: rotate(-90deg);
    }
    80% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(0);
    }
}

.materials_cards-title {
    max-width: 60%;
    margin-bottom: 70px;
}
.materials_cards-title h1 {
    font-size: 72px;
    font-weight: 500;
    line-height: 1.135;
}

/*cards*/

.materials_cards-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 100%;
}

.materials_card {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    margin-right: 30px;
}

.materials_card:last-child {
    margin-right: 0;
}

.materials_card img {
    border: none;
    border-radius: 10px;
    display: block;
    height: 100%;
    margin-bottom: 10px;
    max-height: 384px;
    min-height: 384px;
    object-fit: cover;
    outline: none;
    width: 100%;
}

.materials_card-description {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 20px;
    border-radius: 10px;
    border: none;
    background-color: #f8f8f8;
    margin-top: 10px;
    height: 137px;
}

.materials_card-description h3 {
    font-size: 24px;
    font-weight: 500;
    line-height: 27.25px;
    text-align: left;
    margin-bottom: 10px;
}

.materials_card-description p {
    font-size: 18px;
    font-weight: 400;
    line-height: 20.43px;
    text-align: left;
}

/*tips*/

.materials_tips {
    margin: 160px 0;
    padding: 0 0 70px 0;
    border-bottom: 1px solid #d9d9d9;
}

.materials_tips-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
}

.materials_tip-card {
    display: flex;
    flex-direction: column;
    padding: 20px;
    border: none;
    border-radius: 10px;
    background-color: #f7f7f7;
    margin-right: 30px;
}

.materials_tip-card:last-child {
    margin-right: 0;
}

.materials_tip-card-head {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 50px;
}

.materials_tip-card-head h3 {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.135;
    text-align: left;
}

.materials_tip-card-head span {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.135;
    text-align: left;

    color: #777777;
}

.materials_tip-card p {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.135;
    text-align: left;
}

/* faq */

.materials_faq-container {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.materials_faq-accordion {
    display: flex;
    flex-direction: column;
    width: 50%;
}

.materials_faq-accordion:last-child {
    margin-left: 60px;
}

.materials_faq-accordion-item {
    border-bottom: 1px solid #d9d9d9;
    width: 100%;
}

.materials_faq-accordion-item:nth-child(1) {
    border-top: 1px solid #d9d9d9;
}

.materials_faq-accordion-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 0;
    cursor: pointer;
    /**/
    font-size: 21px;
    font-weight: 500;
    line-height: 1.135;
    text-align: left;
}

/* SVG Icon */
.materials_faq-accordion-icon {
    width: 20px;
    height: 20px;
    display: inline-block;
}

.materials_faq-accordion-rect {
    transition: transform 0.3s ease;
    transform-origin: center;
}

.materials_faq-accordion-rect.rotate {
    transform: rotate(90deg);
}

.materials_faq-accordion-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease, margin-bottom 0.3s ease;
    margin-bottom: 0;
    background: #fff;
    max-width: 79%;
}

.materials_faq-accordion-content p {
    font-size: 18px;
    font-weight: 400;
    line-height: 1.135;
    text-align: left;
}

@media (min-width: 576px) and (max-width: 1920px) {
    .materials {
        margin: 10.4vw 3.65vw 0 3.65vw;
        max-width: 100vw;
        padding-bottom: 3.64vw;
    }

    .materials_cards-header {
        margin-bottom: 2.08vw;
    }

    .materials_cards-header span {
        font-size: 1.09vw;
        margin-right: 0.52vw;
    }

    .materials_cards-header_icons svg {
        width: 0.625vw;
        height: 0.625vw;
        animation: rotatePauseSvg 2.5s infinite;
    }

    .materials_cards-title {
        margin-bottom: 3.64vw;
    }
    .materials_cards-title h1 {
        font-size: 3.75vw;
    }

    /*cards*/

    .materials_card {
        margin-right: 1.56vw;
    }

    .materials_card-description {
        padding: 1.04vw;
        border-radius: 0.52vw;
        margin-top: 0.52vw;
        height: 7.135vw;
    }

    .materials_card-description h3 {
        font-size: 1.25vw;
        line-height: 1.135;
        margin-bottom: 0.52vw;
    }

    .materials_card-description p {
        font-size: 0.93vw;
        line-height: 1.135;
    }

    /*tips*/

    .materials_tips {
        margin: 8.33vw 0;
        padding: 0 0 3.64vw 0;
        border-bottom: 0.05vw solid #d9d9d9;
    }

    .materials_tip-card {
        padding: 1.04vw;
        border-radius: 0.52vw;
        margin-right: 1.56vw;
    }

    .materials_tip-card:last-child {
        margin-right: 0;
    }

    .materials_tip-card-head {
        margin-bottom: 2.6vw;
    }

    .materials_tip-card-head h3 {
        font-size: 0.93vw;
    }

    .materials_tip-card-head span {
        font-size: 0.93vw;
    }

    .materials_tip-card p {
        font-size: 0.833vw;
    }

    /* faq */
    .materials_faq-accordion:last-child {
        margin-left: 3.125vw;
    }

    .materials_faq-accordion-item {
        border-bottom: 0.05vw solid #d9d9d9;
    }

    .materials_faq-accordion-item:nth-child(1) {
        border-top: 0.05vw solid #d9d9d9;
    }

    .materials_faq-accordion-header {
        padding: 1.56vw 0;
        /**/
        font-size: 1.09vw;
    }

    /* SVG Icon */
    .materials_faq-accordion-icon {
        width: 1.04vw;
        height: 1.04vw;
    }

    .materials_faq-accordion-content p {
        font-size: 0.93vw;
    }

    .materials_card img {
        margin-bottom: 0.52vw;
        height: 100%;
        min-height: 20vw;
        max-height: 20vw;
        border-radius: 0.52vw;
    }
}

@media (max-width: 576px) {
    .materials {
        margin: 24.27vw 4.85vw 0 4.85vw;
        max-width: 576px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding-bottom: 14.56vw;
    }

    .materials_cards-header,
    .materials_cards-header_icons {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
    }

    .materials_cards-header {
        margin-bottom: 7.28vw;
    }

    .materials_cards-header span {
        font-size: 4.36vw;
        font-weight: 600;
        line-height: 1.135;
        margin-right: 2.42vw;
    }

    .materials_cards-header_icons svg {
        width: 2.42vw;
        height: 2.2vw;
        animation: rotatePauseSvg 2.5s infinite;
    }

    @keyframes rotatePauseSvg {
        0% {
            transform: rotate(0);
        }
        20% {
            transform: rotate(0);
        }
        40% {
            transform: rotate(-90deg);
        }
        60% {
            transform: rotate(-90deg);
        }
        80% {
            transform: rotate(0);
        }
        100% {
            transform: rotate(0);
        }
    }

    .materials_cards-title {
        max-width: 100%;
        margin-bottom: 9.7vw;
    }
    .materials_cards-title h1 {
        font-size: 6.94vw;
        font-weight: 500;
        line-height: 1.135;
    }

    /*cards*/

    .materials_cards-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 100%;
    }

    .materials_card {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 100%;
        margin-right: 0;
        margin-bottom: 7.28vw;
    }

    .materials_card:last-child {
        margin-right: 0;
        margin-bottom: 0;
    }

    .materials_card img {
        margin-bottom: 2.42vw;
        width: 100%;
        display: block;
        height: 100%;
        min-height: 60.43vw;
        max-height: 60.43vw;
        border-radius: 2.42vw;
    }

    .materials_card-description {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 4.85vw;
        border-radius: 2.42vw;
        border: none;
        background-color: #f8f8f8;
        margin-top: 2.42vw;
        height: 35.19vw;
    }

    .materials_card-description h3 {
        font-size: 4.85vw;
        font-weight: 500;
        line-height: 1.135;
        text-align: left;
        margin-bottom: 2.42vw;
    }

    .materials_card-description p {
        font-size: 3.88vw;
        font-weight: 400;
        line-height: 1.135;
        text-align: left;
    }

    /*tips*/

    .materials_tips {
        margin: 24.27vw 0;
        padding: 0 0 14.56vw 0;
        border-bottom: 0.24vw solid #d9d9d9;
    }

    .materials_tips-container {
        flex-direction: column;
    }

    .materials_tip-card {
        padding: 4.85vw;
        border-radius: 2, 42vw;
        margin-right: 0;
        margin-bottom: 4.85vw;
    }

    .materials_tip-card:last-child {
        margin-right: 0;
        margin-bottom: 0;
    }

    .materials_tip-card-head {
        margin-bottom: 12.13vw;
    }

    .materials_tip-card-head h3 {
        font-size: 4.36vw;
    }

    .materials_tip-card-head span {
        font-size: 4.36vw;
    }

    .materials_tip-card p {
        font-size: 3.88vw;
    }

    /* faq */

    .materials_faq-container {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .materials_faq-accordion {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .materials_faq-accordion:last-child {
        margin-left: 0;
    }

    .materials_faq-accordion-item {
        border-bottom: 0.24vw solid #d9d9d9;
        width: 100%;
    }

    .materials_faq-accordion-item:nth-child(1) {
        border-top: none;
    }

    .materials_faq-accordion:nth-child(1) .materials_faq-accordion-item:nth-child(1) {
        border-top: 0.24vw solid #d9d9d9;
    }

    .materials_faq-accordion-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 7.28vw 0;
        cursor: pointer;
        /**/
        font-size: 5.09vw;
        font-weight: 500;
        line-height: 1.135;
        text-align: left;
    }

    /* SVG Icon */
    .materials_faq-accordion-icon {
        width: 4.85vw;
        height: 4.85vw;
        display: inline-block;
    }

    .materials_faq-accordion-rect {
        transition: transform 0.3s ease;
        transform-origin: center;
    }

    .materials_faq-accordion-rect.rotate {
        transform: rotate(90deg);
    }

    .materials_faq-accordion-content {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.3s ease, margin-bottom 0.3s ease;
        margin-bottom: 0;
        background: #fff;
        max-width: 79%;
    }

    .materials_faq-accordion-content p {
        font-size: 4.36vw;
        font-weight: 400;
        line-height: 1.135;
        text-align: left;
    }
}
