#preloader {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100vw;
    height: 100vh;
    background: white;
    max-width: 1920px;
    margin: 0 auto;
}

.part {
    position: relative;
    transition: all 0.3s ease;
}

.logo {
    width: 0;
    height: max-content;
    overflow: hidden;
    transition: width 0.3s ease;
}

.part:nth-child(1) {
    right: -15px;
}

.part:nth-child(3) {
    left: -15px;
}

.d-none {
    display: none !important;
}
