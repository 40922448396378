.linkcards-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 1920px;
    margin: 90px 70px;
}

@media (min-width: 576px) and (max-width: 1920px) {
    .linkcards-container {
        max-width: 100vw;
        margin: 4.6vw 3.6vw;
    }
}

@media (max-width: 576px) {
    .linkcards-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 576px;
        margin: 12.13vw 0 24.27vw 0;
        padding: 0 4.85vw;
    }
}
