@font-face {
    font-family: "Saans TRIAL";
    src: url("../../assets/font/SaansTRIAL-Bold.eot");
    src: local("Saans TRIAL Bold"), local("SaansTRIAL-Bold"), url("../../assets/font/SaansTRIAL-Bold.eot?#iefix") format("embedded-opentype"),
        url("../../assets/font/SaansTRIAL-Bold.woff2") format("woff2"), url("../../assets/font/SaansTRIAL-Bold.woff") format("woff"),
        url("../../assets/font/SaansTRIAL-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Saans TRIAL";
    src: url("../../assets/font/SaansTRIAL-SemiBold.eot");
    src: local("Saans TRIAL SemiBold"), local("SaansTRIAL-SemiBold"),
        url("../../assets/font/SaansTRIAL-SemiBold.eot?#iefix") format("embedded-opentype"),
        url("../../assets/font/SaansTRIAL-SemiBold.woff2") format("woff2"), url("../../assets/font/SaansTRIAL-SemiBold.woff") format("woff"),
        url("../../assets/font/SaansTRIAL-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Saans TRIAL";
    src: url("../../assets/font/SaansTRIAL-Medium.eot");
    src: local("Saans TRIAL Medium"), local("SaansTRIAL-Medium"), url("../../assets/font/SaansTRIAL-Medium.eot?#iefix") format("embedded-opentype"),
        url("../../assets/font/SaansTRIAL-Medium.woff2") format("woff2"), url("../../assets/font/SaansTRIAL-Medium.woff") format("woff"),
        url("../../assets/font/SaansTRIAL-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Saans TRIAL";
    src: url("../../assets/font/SaansTRIAL-Regular.eot");
    src: local("Saans TRIAL Regular"), local("SaansTRIAL-Regular"), url("../../assets/font/SaansTRIAL-Regular.eot?#iefix") format("embedded-opentype"),
        url("../../assets/font/SaansTRIAL-Regular.woff2") format("woff2"), url("../../assets/font/SaansTRIAL-Regular.woff") format("woff"),
        url("../../assets/font/SaansTRIAL-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

.brief {
    margin: 160px 70px 0 70px;
    max-width: 1920px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    font-family: "Saans TRIAL";
}

.briefCareers {
    padding: 0 0 130px 0;
    border-bottom: 1px solid #d9d9d9;
}

.brief-header,
.brief-header_icons {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.brief-header {
    margin-bottom: 40px;
}

.brief-header span {
    font-size: 21px;
    font-weight: 600;
    line-height: 1.135;
    margin-right: 10px;
}

.brief-header_icons svg {
    animation: rotatePauseSvg 2.5s infinite;
}

@keyframes rotatePauseSvg {
    0% {
        transform: rotate(0);
    }
    20% {
        transform: rotate(0);
    }
    40% {
        transform: rotate(-90deg);
    }
    60% {
        transform: rotate(-90deg);
    }
    80% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(0);
    }
}

.brief-title {
    max-width: 60%;
    margin-bottom: 70px;
}
.brief-title h1 {
    font-size: 72px;
    font-weight: 500;
}

.brief-cards-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
    align-items: stretch;
}

.brief-card {
    flex: 0 1 calc(50% - 10px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #f7f7f7;
    padding: 50px;
    border: none;
    border-radius: 10px;
    height: 355px;
    position: relative; /* Needed for the pseudo-element */
    overflow: hidden;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
}

.brief-card::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    z-index: 0;
    transform: translateY(120%);
    transition: transform 0.3s ease;
    border-radius: 10px;
}

.brief-card:nth-child(1)::before {
    background-color: #243be2;
}

.brief-card:hover::before {
    transform: translateY(0);
}

.brief-card-content,
.brief-card_desc {
    position: relative;
    transition: color 0.3s ease;
    z-index: 1;
}

.brief-card:hover .brief-card-content,
.brief-card:hover .brief-card_desc {
    color: #fff;
    transition: color 0.3s ease;
}

/* .brief-card-icon {
    transition: transform 0.3s ease;
}

.brief-card:hover .brief-card-icon {
    transform: rotate(30deg);
    transition: transform 0.3s ease;
} */

.brief-card-stat svg path {
    transition: fill 0.3s ease;
}

.brief-card:hover .brief-card-stat svg path {
    fill: #fff;
    transition: fill 0.3s ease;
}

.brief-card-stat svg {
    transition: transform 0.3s ease;
}

.brief-card:hover .brief-card-stat svg {
    transform: rotate(90deg);
    transition: transform 0.3s ease;
}

.brief-card-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

.briefCareers .brief-card span {
    width: 100%;
}

.brief-card span {
    font-size: 40px;
    font-weight: 500;
    line-height: 1.135;
    text-align: left;
    width: 40%;
}

.brief-card-stat {
    display: flex;
    flex-direction: row;
    align-items: center;

    font-size: 86px;
    font-weight: 500;
    line-height: 1.135;
    text-align: left;
}

.brief-card-stat svg {
    width: 100%;
    max-width: 42.5px;
    height: auto;

    margin-right: 15px;
}

.brief-card_desc {
    font-size: 21px;
    font-weight: 500;
    line-height: 1.135;
    text-align: left;
    width: 80%;
}

.brief-card-icon {
    width: auto;
    max-height: 151px;
}

@media (min-width: 576px) and (max-width: 1920px) {
    .brief {
        margin: 8.33vw 3.65vw 0 3.65vw;
        max-width: 100vw;
    }

    .briefCareers {
        padding: 0 0 6.77vw 0;
        border-bottom: 0.05vw solid #d9d9d9;
    }

    .brief-header {
        margin-bottom: 2.08vw;
    }

    .brief-header span {
        font-size: 1.09vw;
        margin-right: 0.52vw;
    }

    .brief-title {
        margin-bottom: 3.65vw;
    }

    .brief-title h1 {
        font-size: 3.75vw;
    }

    .brief-cards-container {
        gap: 1.04vw;
    }

    .brief-card {
        flex: 0 1 calc(50% - 0.52vw);
        padding: 2.6vw;
        border-radius: 0.52vw;
        height: 18.49vw;
    }

    .brief-card::before {
        border-radius: 0.52vw;
    }

    .brief-card span {
        font-size: 2.08vw;
    }

    .brief-card-stat {
        font-size: 4.48vw;
    }

    .brief-card-stat svg {
        max-width: 2.21vw;
        margin-right: 0.78vw;
    }

    .brief-card_desc {
        font-size: 1.09vw;
    }

    .brief-card-icon {
        max-height: 7.86vw;
    }

    .brief-header_icons svg {
        width: 0.62vw;
        height: 0.62vw;
    }
}

@media (max-width: 576px) {
    .brief {
        margin: 17.36vw 4.85vw;
        padding-bottom: 10.42vw;
        border-bottom: 0.17vw solid #d9d9d9;
        max-width: 576px;
    }

    .briefCareers {
        padding: 0 0 14.56vw 0;
        border-bottom: 0.24vw solid #d9d9d9;
    }

    .brief-header,
    .brief-header_icons {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
    }

    .brief-header {
        margin-bottom: 5.2vw;
    }

    .brief-header span {
        font-size: 3.125;
        font-weight: 600;
        line-height: 1.135;
        text-align: left;
        margin-right: 1.736vw;
    }

    .brief-header_icons svg {
        animation: rotatePauseSvg 2.5s infinite;
    }

    @keyframes rotatePauseSvg {
        0% {
            transform: rotate(0);
        }
        20% {
            transform: rotate(0);
        }
        40% {
            transform: rotate(-90deg);
        }
        60% {
            transform: rotate(-90deg);
        }
        80% {
            transform: rotate(0);
        }
        100% {
            transform: rotate(0);
        }
    }

    .brief-title {
        max-width: 100%;
        margin-bottom: 6.94vw;
    }
    .brief-title h1 {
        font-size: 6.94vw;
        font-weight: 500;
        line-height: 1.135;
        text-align: left;
    }

    .brief-cards-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 3.47vw;
        align-items: stretch;
    }

    .brief-card {
        flex: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-color: #f7f7f7;
        padding: 3.47vw;
        border: none;
        border-radius: 1.17vw;
        height: 61.63vw;
        position: relative; /* Needed for the pseudo-element */
        overflow: hidden;
        user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;
    }

    .brief-card::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #000;
        z-index: 0;
        transform: translateY(120%);
        transition: transform 0.3s ease;
        border-radius: 1.17vw;
    }

    .brief-card:nth-child(1)::before {
        background-color: #243be2;
    }

    .brief-card:hover::before {
        transform: translateY(0);
    }

    .brief-card-content,
    .brief-card_desc {
        position: relative;
        transition: color 0.3s ease;
        z-index: 1;
    }

    .brief-card:hover .brief-card-content,
    .brief-card:hover .brief-card_desc {
        color: #fff;
        transition: color 0.3s ease;
    }

    /* .brief-card-icon {
        transition: transform 0.3s ease;
    }
    
    .brief-card:hover .brief-card-icon {
        transform: rotate(30deg);
        transition: transform 0.3s ease;
    } */

    .brief-card-stat svg path {
        transition: fill 0.3s ease;
    }

    .brief-card:hover .brief-card-stat svg path {
        fill: #fff;
        transition: fill 0.3s ease;
    }

    .brief-card-stat svg {
        transition: transform 0.3s ease;
    }

    .brief-card:hover .brief-card-stat svg {
        transform: rotate(90deg);
        transition: transform 0.3s ease;
    }

    .brief-card-content {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .brief-card:nth-child(2) .brief-card-content,
    .brief-card:nth-child(3) .brief-card-content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
    }

    .brief-card span {
        font-size: 4.86vw;
        font-weight: 500;
        line-height: 1.135;
        text-align: left;
        width: 60%;
    }

    .brief-card-stat {
        display: flex;
        flex-direction: row;
        align-items: center;

        font-size: 11.11vw;
        font-weight: 500;
        line-height: 1.135;
        text-align: left;

        margin-top: 3.47vw;
    }

    .brief-card-stat svg {
        width: 100%;
        max-width: 4.34vw;
        height: auto;

        margin-right: 1.73vw;
    }

    .brief-card_desc {
        font-size: 3.15vw;
        font-weight: 500;
        line-height: 1.135;
        text-align: left;
        width: 100%;
    }

    .brief-card-icon {
        width: auto;
        max-height: 15.625vw;
    }

    .brief-cards-container.arm-language .brief-card-content span {
        line-height: 1.135;
    }

    .brief-cards-container .brief-card-content span {
        font-size: 5.5vw;
    }
}
