.wrapper {
    background-color: white;
    border-top-left-radius: 100px;
    border-top-right-radius: 100px;
    overflow: hidden;
}

@media (max-width: 1920px) {
    .wrapper {
        border-top-left-radius: 5.2vw;
        border-top-right-radius: 5.2vw;
    }
}