@font-face {
    font-family: "Saans TRIAL";
    src: url("../../assets/font/SaansTRIAL-Bold.eot");
    src: local("Saans TRIAL Bold"), local("SaansTRIAL-Bold"), url("../../assets/font/SaansTRIAL-Bold.eot?#iefix") format("embedded-opentype"),
        url("../../assets/font/SaansTRIAL-Bold.woff2") format("woff2"), url("../../assets/font/SaansTRIAL-Bold.woff") format("woff"),
        url("../../assets/font/SaansTRIAL-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Saans TRIAL";
    src: url("../../assets/font/SaansTRIAL-SemiBold.eot");
    src: local("Saans TRIAL SemiBold"), local("SaansTRIAL-SemiBold"),
        url("../../assets/font/SaansTRIAL-SemiBold.eot?#iefix") format("embedded-opentype"),
        url("../../assets/font/SaansTRIAL-SemiBold.woff2") format("woff2"), url("../../assets/font/SaansTRIAL-SemiBold.woff") format("woff"),
        url("../../assets/font/SaansTRIAL-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Saans TRIAL";
    src: url("../../assets/font/SaansTRIAL-Medium.eot");
    src: local("Saans TRIAL Medium"), local("SaansTRIAL-Medium"), url("../../assets/font/SaansTRIAL-Medium.eot?#iefix") format("embedded-opentype"),
        url("../../assets/font/SaansTRIAL-Medium.woff2") format("woff2"), url("../../assets/font/SaansTRIAL-Medium.woff") format("woff"),
        url("../../assets/font/SaansTRIAL-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Saans TRIAL";
    src: url("../../assets/font/SaansTRIAL-Regular.eot");
    src: local("Saans TRIAL Regular"), local("SaansTRIAL-Regular"), url("../../assets/font/SaansTRIAL-Regular.eot?#iefix") format("embedded-opentype"),
        url("../../assets/font/SaansTRIAL-Regular.woff2") format("woff2"), url("../../assets/font/SaansTRIAL-Regular.woff") format("woff"),
        url("../../assets/font/SaansTRIAL-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Mardoto";
    src: url("../../assets/font/Mardoto-Medium.woff2") format("woff2"), url("../../assets/font/Mardoto-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

.service-card {
    display: flex;
    flex-direction: column;
    width: 100%;
    font-family: "Saans TRIAL";
}

/*footer*/
.service-card-footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 50px;
    margin-bottom: 130px;
}

.service-card-footer span {
    font-size: 26px;
    font-weight: 500;
    line-height: 1.135;
    text-align: left;
    max-width: 43.8%;
}

/*content*/
.service-card-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #000;
    color: #fff;
    width: 100%;
    padding: 50px 100px;
    border: none;
    border-radius: 30px;
    height: 830px;
}

.service-card-left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 40%;
}

.service-card_header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.service-card_header span {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
    text-align: left;
    margin-top: 20px;
    line-height: 1.135;
}

.text-slider_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.text-pair {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.slide {
    font-size: 75px;
    font-weight: 700;
    text-align: center;
    color: #fff;
    line-height: 1;
}

.slide-description {
    width: 82%;
    /* margin-top: 20px; */
    font-size: 16px;
    font-weight: 500;
    text-align: center;
}

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70px;
}

.pagination-bullet {
    width: 8px;
    height: 8px;
    margin-right: 10px;
    border-radius: 50px;
    background-color: #adadad;
    transition: all 0.3s ease;
    cursor: pointer;
}

.pagination-bullet:last-child {
    margin-right: 0;
}

.pagination-bullet.active {
    width: 35px;
    height: 8px;
    background-color: white;
    transition: all 0.3s ease;
}

.service-card-right {
    display: flex;
    justify-content: center;
    align-items: center;
}

.service-card-right-2 {
    margin-right: 100px;
}

.service-card-right-2 img {
    max-width: 594px;
}

.service-card-right-3 {
    margin-right: 150px;
}

.service-card-right-3 img {
    max-width: 395px;
}

.titleSwiper {
    width: 100%;
    height: 85px !important;
    text-align: center;
}

.titleSwiper .swiper-slide {
    height: 85px !important;
}

.descriptionSwiper {
    width: 100%;
    height: 60px !important;
}

.descriptionSwiper .swiper-slide {
    width: 100%;
    height: 60px !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.service-card.service-card.arm-language .slide {
    font-weight: 600 !important;
}

@media (min-width: 576px) and (max-width: 1920px) {
    /*footer*/
    .service-card-footer {
        margin-top: 2.6vw;
        margin-bottom: 6.77vw;
    }

    .service-card-footer span {
        font-size: 1.35vw;
    }

    /*content*/
    .service-card-content {
        padding: 2.6vw 5.21vw;
        border-radius: 1.56vw;
        height: 43.23vw;
    }

    .service-card_header span {
        font-size: 0.73vw;
        margin-top: 1.04vw;
    }

    .slide {
        font-size: 3.91vw;
        font-weight: 700;
    }

    .slide-description {
        /* margin-top: 0.74vw; */
        font-size: 0.83vw;
        font-weight: 500;
    }

    .pagination {
        width: 3.65vw;
    }

    .pagination-bullet {
        width: 0.42vw;
        height: 0.42vw;
        margin-right: 0.52vw;
        border-radius: 50px;
    }

    .pagination-bullet:last-child {
        margin-right: 0;
    }

    .pagination-bullet.active {
        width: 1.82vw;
        height: 0.42vw;
    }

    /*swiper*/
    .titleSwiper {
        height: 4.37vw !important;
    }

    .titleSwiper .swiper-slide {
        height: 4.37vw !important;
    }

    .descriptionSwiper {
        height: 3.5vw !important;
    }

    .descriptionSwiper .swiper-slide {
        height: 3.5vw !important;
    }

    /*need to change*/
    .service-card-right-2 {
        margin-right: 5.2vw;
    }

    .service-card-right-2 img {
        max-width: 30.93vw;
    }

    .service-card-right-3 {
        margin-right: 7.81vw;
    }

    .service-card-right-3 img {
        max-width: 20.57vw;
    }

    .service-card_header svg {
        width: 0.9vw;
        height: 0.9vw;
    }
}

@media (max-width: 576px) {
    .service-card {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    /*footer*/
    .service-card-footer {
        display: flex;
        justify-content: flex-end;
        margin-top: 7.28vw; /* 30px */
        margin-bottom: 12.13vw; /* 50px */
    }

    .service-card-footer span {
        font-size: 4.85vw; /* 20px */
        font-weight: 500;
        line-height: 5.51vw; /* 22.71px */
        text-align: left;
        max-width: 100%;
    }

    /*content*/
    .service-card-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        background-color: #000;
        color: #fff;
        width: 100%;
        padding: 4.85vw; /* 20px */
        border: none;
        border-radius: 4.85vw; /* 20px */
        height: 169.9vw; /* 700px */
    }

    .service-card-left {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 50%;
    }

    .service-card_header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .service-card_header span {
        text-transform: uppercase;
        font-size: 2.91vw; /* 12px */
        font-weight: 700;
        line-height: 3.31vw; /* 13.62px */
        text-align: left;
        margin-top: 4.85vw; /* 20px */
    }

    .service-card_header svg {
        width: 3.88vw; /* 16px */
        height: 3.88vw; /* 16px */
    }

    .text-slider_container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .text-pair {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .slide {
        font-size: 8.74vw; /* 36px */
        font-weight: 700;
        text-align: center;
        color: #fff;
    }

    .service-card.arm-language .slide-description {
        font-size: 3.58vw; /* 16px */
    }

    .slide-description {
        width: 100%;
        margin-top: 4.85vw; /* 20px */
        font-size: 3.58vw; /* 16px */
        font-weight: 500;
        text-align: center;
    }

    .pagination {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 16.99vw; /* 70px */
    }

    .pagination-bullet {
        width: 1.94vw; /* 8px */
        height: 1.94vw; /* 8px */
        margin-right: 2.43vw; /* 10px */
        border-radius: 50px;
        background-color: #adadad;
        transition: all 0.3s ease;
        cursor: pointer;
    }

    .pagination-bullet:last-child {
        margin-right: 0;
    }

    .pagination-bullet.active {
        width: 8.5vw; /* 35px */
        height: 1.94vw; /* 8px */
        background-color: white;
        transition: all 0.3s ease;
    }

    .service-card-right {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .titleSwiper {
        width: 100%;
        height: 9.85vw !important; /* 40px */
        text-align: center;
    }

    .titleSwiper .swiper-slide {
        height: 9.85vw !important; /* 40px */
    }

    .descriptionSwiper {
        width: 100%;
        height: 20.56vw !important; /* 60px */
    }

    .descriptionSwiper .swiper-slide {
        width: 100%;
        height: 20.56vw !important; /* 60px */
        display: flex;
        justify-content: center;
        align-items: center;
    }

    /*need to change*/
    .service-card-right-2 {
        margin-right: 0;
    }

    .service-card-right-2 img {
        max-width: 100%;
    }

    .service-card-right-3 {
        margin-right: 0;
        margin-bottom: 13.27vw;
    }

    .service-card-right-3 img {
        max-width: 46.84vw;
    }

    .service-card.arm-language .service-card-footer span {
        font-size: 4vw;
    }

    /* .service-card_header svg {
        width: 0.9vw;
        height: 0.9vw;
    } */

    .text-slider {
        width: 100%;
    }
}
