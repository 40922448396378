@font-face {
    font-family: "Saans TRIAL";
    src: url("../../assets/font/SaansTRIAL-Bold.eot");
    src: local("Saans TRIAL Bold"), local("SaansTRIAL-Bold"), url("../../assets/font/SaansTRIAL-Bold.eot?#iefix") format("embedded-opentype"),
        url("../../assets/font/SaansTRIAL-Bold.woff2") format("woff2"), url("../../assets/font/SaansTRIAL-Bold.woff") format("woff"),
        url("../../assets/font/SaansTRIAL-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Saans TRIAL";
    src: url("../../assets/font/SaansTRIAL-SemiBold.eot");
    src: local("Saans TRIAL SemiBold"), local("SaansTRIAL-SemiBold"),
        url("../../assets/font/SaansTRIAL-SemiBold.eot?#iefix") format("embedded-opentype"),
        url("../../assets/font/SaansTRIAL-SemiBold.woff2") format("woff2"), url("../../assets/font/SaansTRIAL-SemiBold.woff") format("woff"),
        url("../../assets/font/SaansTRIAL-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Saans TRIAL";
    src: url("../../assets/font/SaansTRIAL-Medium.eot");
    src: local("Saans TRIAL Medium"), local("SaansTRIAL-Medium"), url("../../assets/font/SaansTRIAL-Medium.eot?#iefix") format("embedded-opentype"),
        url("../../assets/font/SaansTRIAL-Medium.woff2") format("woff2"), url("../../assets/font/SaansTRIAL-Medium.woff") format("woff"),
        url("../../assets/font/SaansTRIAL-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Mardoto";
    src: url("../../assets/font/Mardoto-Medium.woff2") format("woff2"), url("../../assets/font/Mardoto-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

/* Base Styles */
.numbers {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 1920px;
    padding: 100px 0 70px 0;
    margin: 0 56px 0 70px;
    border-bottom: 1px solid #d9d9d9;
    font-family: "Saans TRIAL";
}

.numbers_head {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.numbers.arm-language .numbers_head h1 {
    line-height: 1.135;
    font-size: 48px;
}

.numbers_head h1 {
    font-size: 64px;
    font-weight: 600;
    text-align: left;
    color: #000;
    max-width: 60%;
}

.numbers_head img {
    width: 100%;
    max-width: 315px;
    height: auto;
    object-fit: contain;
    display: block;
    margin-right: 97px;
}

.numbers_container {
    display: flex;
    justify-content: space-between;
    margin-right: 130px;
}

.number_item {
    margin-right: 150px;
}

.number_item:last-child {
    margin-right: 0;
}

.number_item h1 {
    font-size: 250px;
    font-weight: 500;
    text-align: left;
    line-height: 283.81px;
    color: #000;
    position: relative;
    overflow: hidden; /* Ensure digits hide properly */
    white-space: nowrap;
}

.number_item p {
    font-size: 26px;
    font-weight: 500;
    line-height: 29.52px;
    text-align: left;
    color: #3d3d3d;
    max-width: 78%;
}

/* Digit Animation */
.digit {
    display: inline-block;
    transform: translateY(100%);
    transition: transform 0.8s ease-out;
    animation: slideUp 0.8s ease-out forwards;
}

@keyframes slideUp {
    from {
        transform: translateY(100%);
    }
    to {
        transform: translateY(0);
    }
}

/* Animated Digits */
.digit.animate {
    animation-name: slideUp;
}

@media (min-width: 576px) and (max-width: 1920px) {
    .numbers.arm-language .numbers_head h1 {
        font-size: 2.5vw;
    }

    .numbers {
        max-width: 100vw;
        padding: 5.6vw 0 3.6vw 0;
        margin: 0 2.9vw 0 3.6vw;
        border-bottom: 1px solid #d9d9d9;
    }

    .numbers_head h1 {
        font-size: 3.333vw;
    }

    .numbers_head img {
        max-width: 16.4vw;
        margin-right: 5vw;
    }

    .numbers_container {
        margin-right: 6.7vw;
    }

    .number_item {
        margin-right: 7.8vw;
    }

    .number_item:last-child {
        margin-right: 0;
    }

    .number_item h1 {
        font-size: 13vw;
        line-height: 14.7vw;
    }

    .number_item p {
        font-size: 1.3vw;
        line-height: 1.53vw;
    }
}

@media (max-width: 576px) {
    .numbers.arm-language .numbers_head h1 {
        font-size: 5.82vw;
    }

    /* Base Styles */
    .numbers {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        max-width: 139.81vw;
        padding: 24.27vw 0 14.56vw 0;
        margin: 0 4.85vw 0 4.85vw;
        border-bottom: 1px solid #d9d9d9;
    }

    .numbers_head {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    .numbers_head h1 {
        font-size: 7.52vw;
        font-weight: 500;
        line-height: 8.54vw;
        text-align: left;
        color: #000;
        max-width: 100%;
    }

    .numbers_head img {
        display: none;
    }

    .numbers.arm-language .numbers_container {
        margin-right: 25.98vw;
    }

    .numbers_container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-right: 22.98vw;
    }

    .number_item {
        margin-right: 0;
        margin-bottom: 4.85vw;
    }

    .number_item:last-child {
        margin-right: 0;
        margin-bottom: 0;
    }

    .number_item h1 {
        font-size: 29.13vw;
        font-weight: 500;
        text-align: left;
        line-height: 33.05vw;
        color: #000;
        position: relative;
        overflow: hidden;
    }

    .number_item p {
        font-size: 4.85vw;
        font-weight: 500;
        line-height: 5.51vw;
        text-align: left;
        color: #3d3d3d;
        max-width: 98%;
    }

    .numbers.arm-language .numbers_container .number_item p {
        font-size: 4.4vw;
        line-height: 1.135;
    }

    .numbers_container::after {
        content: ""; /* Necessary for pseudo-element to display */
        background-image: url("../../assets/arcacube.gif");
        background-size: contain; /* Ensures the entire image is visible */
        background-repeat: no-repeat; /* Prevents the image from repeating */
        position: absolute; /* Allows positioning */
        right: 0; /* Aligns to the right */
        bottom: 14.56vw; /* Aligns to the bottom */
        width: 34.7vw; /* Adjust to the image's dimensions */
        height: 34.7vw; /* Adjust to the image's dimensions */
        pointer-events: none; /* Ensures it doesn’t interfere with user interactions */
    }
}
