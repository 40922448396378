@font-face {
    font-family: "Saans TRIAL";
    src: url("../../assets/font/SaansTRIAL-Bold.eot");
    src: local("Saans TRIAL Bold"), local("SaansTRIAL-Bold"), url("../../assets/font/SaansTRIAL-Bold.eot?#iefix") format("embedded-opentype"),
        url("../../assets/font/SaansTRIAL-Bold.woff2") format("woff2"), url("../../assets/font/SaansTRIAL-Bold.woff") format("woff"),
        url("../../assets/font/SaansTRIAL-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Saans TRIAL";
    src: url("../../assets/font/SaansTRIAL-SemiBold.eot");
    src: local("Saans TRIAL SemiBold"), local("SaansTRIAL-SemiBold"),
        url("../../assets/font/SaansTRIAL-SemiBold.eot?#iefix") format("embedded-opentype"),
        url("../../assets/font/SaansTRIAL-SemiBold.woff2") format("woff2"), url("../../assets/font/SaansTRIAL-SemiBold.woff") format("woff"),
        url("../../assets/font/SaansTRIAL-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Saans TRIAL";
    src: url("../../assets/font/SaansTRIAL-Medium.eot");
    src: local("Saans TRIAL Medium"), local("SaansTRIAL-Medium"), url("../../assets/font/SaansTRIAL-Medium.eot?#iefix") format("embedded-opentype"),
        url("../../assets/font/SaansTRIAL-Medium.woff2") format("woff2"), url("../../assets/font/SaansTRIAL-Medium.woff") format("woff"),
        url("../../assets/font/SaansTRIAL-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Mardoto";
    src: url("../../assets/font/Mardoto-Medium.woff2") format("woff2"), url("../../assets/font/Mardoto-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

.payment-system {
    max-width: 1920px;
    margin: 160px 70px;
    padding-bottom: 70px;
    border-bottom: 1px solid #d9d9d9;
    display: flex;
    flex-direction: column;
    font-family: "Saans TRIAL";
}
.payment-system-cards {
    display: flex;
    flex-direction: column;
    max-width: 55%;
}

.payment-system-cards-header,
.payment-system-cards-header_icons {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.payment-system-cards-header {
    margin-bottom: 40px;
}

.payment-system-cards-header span {
    font-size: 21px;
    font-weight: 600;
    margin-right: 10px;
}

.payment-system-cards-header_icons svg {
    animation: rotatePauseSvg 2.5s infinite;
}

@keyframes rotatePauseSvg {
    0% {
        transform: rotate(0);
    }
    20% {
        transform: rotate(0);
    }
    40% {
        transform: rotate(-90deg);
    }
    60% {
        transform: rotate(-90deg);
    }
    80% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(0);
    }
}

.payment-system-cards-title {
    margin-bottom: 70px;
}

.payment-system.arm-language .payment-system-cards-title h1 {
    font-size: 64px;
    line-height: 1.135;
}

.payment-system-cards-title h1 {
    font-size: 72px;
    font-weight: 500;
}

/*footer*/
.payment-system_footer {
    margin-top: 50px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.payment-system_footer span {
    font-size: 26px;
    font-weight: 500;
    line-height: 29.52px;
    text-align: left;
    width: 44%;
}

/*main block*/
.circle-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    background-color: #000;
    border-radius: 30px;
    max-width: 1920px;
    height: 830px;

    position: relative;
}

.circle-rotation-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.5s ease;

    width: 542px;
    height: 542px;
    border-radius: 50%;
    background: linear-gradient(180deg, #243be2 0%, #020201 100%);
}

.circle-border {
    width: 537px;
    height: 537px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000;
    position: relative;
}

.circle-border svg {
    position: absolute;
    top: 25px;
}

.circle-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 57%;
}

.circle-content {
    position: relative;
    background: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
}

.payment-system.arm-language .section {
    font-size: 24px;
}

.section {
    position: absolute;
    color: #8f8f8f;
    font-size: 28px;
    font-weight: 500;
    transition: color 0.3s ease-in-out, transform 0.3s ease-in-out;
    z-index: 10;
}

.section.active {
    color: #ffffff;
    transition: color 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.section {
    color: #8f8f8f;
}

.payment-system.arm-language .circle-content .section:nth-child(1) {
    top: -80px !important;
}
.payment-system.arm-language .circle-content .section:nth-child(2) {
    right: -170px !important;
}
.payment-system.arm-language .circle-content .section:nth-child(3) {
    bottom: -80px !important;
}
.payment-system.arm-language .circle-content .section:nth-child(4) {
    left: -170px !important;
}

.arrow-controls {
    position: absolute;
    top: 40px;
    right: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.arrow-controls .arrow:first-child {
    margin-right: 20px;
}

.arrow {
    position: relative;
    width: 42px;
    height: 42px;
    border: 1px solid #fff;
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;
    transition: transform 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    z-index: 10;
}

.arrow::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: white;
    top: 100%; /* Start below the button */
    left: 0;
    transition: top 0.5s ease;
    z-index: 0;
}

.arrow:hover::before {
    top: 0;
}

.svg-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.5s ease;
}

.arrow.left-arrow:hover .svg-wrapper {
    transform: translateX(-18px);
}
.arrow.left-arrow .svg-wrapper {
    transform: translateX(18px);
}

.arrow.right-arrow:hover .svg-wrapper {
    transform: translateX(18px);
}

.arrow.right-arrow .svg-wrapper {
    transform: translateX(-18px);
}

.arrow.left-arrow .svg-wrapper:first-child {
    margin-right: 20px;
}
.arrow.right-arrow .svg-wrapper:last-child {
    margin-left: 20px;
}

/*content*/
.content {
    position: relative;
    height: 100%;
    max-width: 500px;
    width: 100%;
}

.content span {
    position: absolute;
    top: 60px;
    left: 40px;

    color: #fff;
    font-size: 28px;
    font-weight: 500;
    text-align: left;
}

.content_desc {
    position: absolute;
    bottom: 40px;
    left: 40px;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.content .content_desc h1 {
    font-size: 150px;
    font-weight: 500;
    text-align: left;
    color: #fff;
}

.content .content_desc p {
    font-size: 28px;
    font-weight: 700;
    text-align: left;
    color: #fff;
}

.content_item {
    position: absolute;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.content_item h1 {
    font-size: 150px;
    font-weight: 500;
    color: #fff;
}

.content_item p {
    font-size: 28px;
    font-weight: 700;
    color: #fff;
}

.content_item.fade-in {
    opacity: 1;
    z-index: 2;
}

.content_item.fade-out {
    opacity: 0;
    z-index: 1;
}

.circle-content .section {
    cursor: pointer;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
}

/* Add this to handle the opacity transition */
.text-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.text-wrapper span {
    position: absolute;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.text-wrapper .fade-in {
    opacity: 1;
    z-index: 2;
}

.text-wrapper .fade-out {
    opacity: 0;
    z-index: 1;
}

.circle-content .section {
    padding: 30px;
}

.circle-container .section:hover {
    color: white;
    transition: color 0.3s ease-in-out;
}

@media (min-width: 576px) and (max-width: 1920px) {
    .payment-system {
        max-width: 100vw;
        margin: 8.33vw 3.65vw;
        padding-bottom: 3.65vw;
    }

    .payment-system-cards {
        max-width: 55%;
    }

    .payment-system-cards-header {
        margin-bottom: 2.08vw;
    }

    .payment-system-cards-header span {
        font-size: 1.09vw;
        margin-right: 0.52vw;
    }

    .payment-system-cards-title {
        margin-bottom: 3.65vw;
    }

    .payment-system-cards-title h1 {
        font-size: 3.75vw;
    }

    .payment-system.arm-language .payment-system-cards-title h1 {
        font-size: 3.3vw;
        line-height: 1.135;
    }

    /*footer*/
    .payment-system_footer {
        margin-top: 2.6vw;
    }

    .payment-system_footer span {
        font-size: 1.35vw;
        line-height: 1.54vw;
    }

    /*main block*/
    .circle-wrapper {
        border-radius: 1.56vw;
        max-width: 100vw;
        height: 43.23vw;
    }

    .circle-rotation-wrapper {
        width: 28.23vw;
        height: 28.23vw;
    }

    .circle-border {
        width: 27.97vw;
        height: 27.97vw;
    }

    .circle-border svg {
        top: 1.3vw;
        width: 2vw;
        height: 1.25vw;
    }

    .section {
        font-size: 1.46vw;
    }

    .arrow-controls {
        top: 2.08vw;
        right: 2.08vw;
    }

    .arrow-controls .arrow:first-child {
        margin-right: 1.04vw;
    }

    .arrow {
        width: 2.19vw;
        height: 2.19vw;
        border: 0.05vw solid #fff;
    }

    .arrow.left-arrow:hover .svg-wrapper {
        transform: translateX(-0.94vw);
    }

    .arrow.left-arrow .svg-wrapper {
        transform: translateX(0.94vw);
    }

    .arrow.right-arrow:hover .svg-wrapper {
        transform: translateX(0.94vw);
    }

    .arrow.right-arrow .svg-wrapper {
        transform: translateX(-0.94vw);
    }

    .arrow.left-arrow .svg-wrapper:first-child {
        margin-right: 1.04vw;
    }
    .arrow.right-arrow .svg-wrapper:last-child {
        margin-left: 1.04vw;
    }

    /*content*/
    .content {
        max-width: 26.04vw;
    }

    .content span {
        top: 3.125vw;
        left: 2.08vw;
        font-size: 1.46vw;
    }

    .content .content_desc {
        bottom: 2.08vw;
        left: 2.08vw;
    }

    .content .content_desc h1 {
        font-size: 7.81vw;
    }

    .content .content_desc p {
        font-size: 1.46vw;
    }

    .arrow-controls svg {
        width: 0.93vw;
        height: 0.93vw;
    }

    .payment-system.arm-language .section {
        font-size: 1.25vw;
    }

    .circle-content .section:nth-child(1) {
        top: -9.11vw !important;
        padding: 6vw;
    }
    .circle-content .section:nth-child(2) {
        right: -12.77vw !important;
        padding: 6vw;
    }
    .circle-content .section:nth-child(3) {
        bottom: -9.11vw !important;
        padding: 6vw;
    }
    .circle-content .section:nth-child(4) {
        left: -12.77vw !important;
        padding: 6vw;
    }

    .payment-system.arm-language .circle-content .section:nth-child(1) {
        top: -8.11vw !important;
        padding: 6vw;
    }
    .payment-system.arm-language .circle-content .section:nth-child(2) {
        right: -12.77vw !important;
        padding: 6vw;
    }
    .payment-system.arm-language .circle-content .section:nth-child(3) {
        bottom: -8.11vw !important;
        padding: 6vw;
    }
    .payment-system.arm-language .circle-content .section:nth-child(4) {
        left: -12.77vw !important;
        padding: 6vw;
    }

    .payment-system-cards-header_icons svg {
        width: 0.6vw;
        height: 0.6vw;
    }
}

/*mobile*/

.circle-container-mobile,
.text-wrapper-name {
    display: none;
}

@media (max-width: 576px) {
    .payment-system {
        max-width: 576px;
        margin: 24.27vw 4.85vw;
        padding-bottom: 14.5vw;
        border-bottom: 0.24vw solid #d9d9d9;
        display: flex;
        flex-direction: column;
    }
    .payment-system-cards {
        display: flex;
        flex-direction: column;
        max-width: 100%;
    }

    .payment-system-cards-header,
    .payment-system-cards-header_icons {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
    }

    .payment-system-cards-header {
        margin-bottom: 7.28vw;
    }

    .payment-system-cards-header span {
        font-size: 4.36vw;
        font-weight: 600;
        margin-right: 2.42vw;
    }

    .payment-system-cards-header_icons svg {
        width: 2.42vw;
        height: 2.42vw;
    }

    .payment-system-cards-title {
        margin-bottom: 9.7vw;
    }
    .payment-system-cards-title h1 {
        font-size: 10.1vw;
        font-weight: 500;
        line-height: 11.57vw;
    }

    .payment-system.arm-language .payment-system-cards-title h1 {
        font-size: 7.9vw;
        line-height: 1.135;
    }

    /*footer*/
    .payment-system_footer {
        margin-top: 7.28vw;
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }

    .payment-system_footer span {
        font-size: 4.85vw;
        font-weight: 500;
        line-height: 5.51vw;
        text-align: left;
        width: 100%;
    }

    .payment-system.arm-language .payment-system_footer span {
        font-size: 4.45vw;
        line-height: 1.135;
    }

    /*main block*/
    .circle-container-mobile {
        display: flex;
    }

    .circle-container-desktop {
        display: none;
    }

    .circle-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;

        background-color: #000;
        border-radius: 7.28vw;
        max-width: 139.8vw;
        height: 169.9vw;

        position: relative;
    }

    .circle-rotation-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        transition: transform 0.5s ease;

        width: 52.42vw;
        height: 52.42vw;
        border-radius: 50%;
        background: linear-gradient(180deg, #243be2 0%, #020201 100%);
    }

    .circle-border {
        width: 50.97vw;
        height: 50.97vw;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #000;
        position: relative;
    }

    .circle-border svg {
        position: absolute;
        top: 2.4vw;
        width: 2.61vw;
        height: 2.61vw;
    }

    .circle-container-mobile {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 85%;
        width: 100%;
    }

    .circle-content {
        position: relative;
        background: #000;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .section {
        position: absolute;
        color: #8f8f8f;
        font-size: 3.88vw;
        line-height: 4.4vw;
        font-weight: 500;
        transition: color 0.3s ease-in-out, transform 0.3s ease-in-out;
    }

    .section.active {
        color: #ffffff;
        transition: color 0.3s ease-in-out, transform 0.3s ease-in-out;
    }

    .section {
        color: #8f8f8f;
    }

    .payment-system.arm-language .section {
        font-size: 3vw;
    }

    .circle-content .section:nth-child(1) {
        top: -12.06vw !important;
        padding: 6vw;
    }
    .circle-content .section:nth-child(2) {
        right: -21.77vw !important;
        padding: 6vw;
    }
    .circle-content .section:nth-child(3) {
        bottom: -12.06vw !important;
        padding: 6vw;
    }
    .circle-content .section:nth-child(4) {
        left: -21.77vw !important;
        padding: 6vw;
    }

    .payment-system.arm-language .circle-content .section:nth-child(1) {
        top: -12.06vw !important;
        padding: 6vw;
    }
    .payment-system.arm-language .circle-content .section:nth-child(2) {
        right: -21.77vw !important;
        padding: 6vw;
    }
    .payment-system.arm-language .circle-content .section:nth-child(3) {
        bottom: -12.06vw !important;
        padding: 6vw;
    }
    .payment-system.arm-language .circle-content .section:nth-child(4) {
        left: -21.77vw !important;
        padding: 6vw;
    }

    .arrow-controls {
        position: absolute;
        top: 4.85vw;
        right: 4.85vw;
        z-index: 20;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .arrow-controls .arrow:first-child {
        margin-right: 4.85vw;
    }

    .arrow {
        position: relative;
        width: 7.76vw;
        height: 7.76vw;
        border: 1px solid #fff;
        border-radius: 50%;
        overflow: hidden;
        cursor: pointer;
        transition: transform 0.3s ease;
        display: flex;
        align-items: center;
        justify-content: center;
        background: transparent;
    }

    .arrow::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: white;
        top: 100%; /* Start below the button */
        left: 0;
        transition: top 0.5s ease;
        z-index: 0;
    }

    .arrow svg {
        width: 3.39vw;
        height: 3.39vw;
    }

    .arrow:hover::before {
        top: 0;
    }

    .svg-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        transition: transform 0.5s ease;
    }

    .arrow.left-arrow:hover .svg-wrapper {
        transform: translateX(-4vw);
    }
    .arrow.left-arrow .svg-wrapper {
        transform: translateX(4vw);
    }

    .arrow.right-arrow:hover .svg-wrapper {
        transform: translateX(4vw);
    }

    .arrow.right-arrow .svg-wrapper {
        transform: translateX(-4vw);
    }

    .arrow.left-arrow .svg-wrapper:first-child {
        margin-right: 4.85vw;
    }
    .arrow.right-arrow .svg-wrapper:last-child {
        margin-left: 4.85vw;
    }

    /*content*/
    .content {
        position: relative;
        height: 100%;
        max-width: 139.8vw;
        width: 100%;
    }

    .content span {
        position: absolute;
        top: 4.8vw;
        left: 4.8vw;

        color: #fff;
        font-size: 6.79vw;
        font-weight: 500;
        text-align: left;
    }

    .content_desc {
        position: absolute;
        bottom: 4.8vw;
        left: 4.8vw;
        height: max-content;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }

    .content .content_desc h1 {
        font-size: 20.87vw;
        line-height: 23.6vw;
        font-weight: 500;
        color: #fff;
    }

    .content .content_desc p {
        font-size: 4.8vw;
        line-height: 5.51vw;
        font-weight: 700;
        color: #fff;
    }

    .content_item {
        position: absolute;
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
        z-index: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .content_item h1 {
        font-size: 20.87vw;
        line-height: 23.6vw;
        font-weight: 500;
        color: #fff;
    }

    .content_item p {
        font-size: 4.8vw;
        line-height: 5.51vw;
        font-weight: 700;
        color: #fff;
    }

    .content_item.fade-in {
        opacity: 1;
        z-index: 2;
    }

    .content_item.fade-out {
        opacity: 0;
        z-index: 1;
    }

    .circle-content .section {
        cursor: pointer;
        user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;
    }

    /* Add this to handle the opacity transition */
    .text-wrapper-name {
        position: relative;
        width: 60%;
        height: 19.9vw;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    .text-wrapper {
        position: relative;
        width: 100%;
        height: max-content;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    .text-wrapper span {
        font-size: 4.8vw;
        font-weight: 500;
        line-height: 5.68vw;
        text-align: left;
        padding: 0 4.8vw 4.8vw 0;
    }

    .text-wrapper span,
    .text-wrapper-name span {
        position: absolute;
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
    }

    .text-wrapper .fade-in,
    .text-wrapper-name .fade-in {
        opacity: 1;
        z-index: 2;
    }

    .text-wrapper .fade-out,
    .text-wrapper-name .fade-out {
        opacity: 0;
        z-index: 1;
    }
}
