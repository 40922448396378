.responsive-text {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    font-size: 36px;
    font-weight: 500;
}

@media (max-width: 576px) {
    .responsive-text {
        font-size: 5.5vw;
    }
}

.responsive-highlight-green {
    color: rgb(173, 242, 51);
}

.responsive-highlight-blue {
    color: rgb(36, 59, 226);
}