@font-face {
    font-family: "Saans TRIAL";
    src: url("../assets/font/SaansTRIAL-Bold.eot");
    src: local("Saans TRIAL Bold"), local("SaansTRIAL-Bold"), url("../assets/font/SaansTRIAL-Bold.eot?#iefix") format("embedded-opentype"),
        url("../assets/font/SaansTRIAL-Bold.woff2") format("woff2"), url("../assets/font/SaansTRIAL-Bold.woff") format("woff"),
        url("../assets/font/SaansTRIAL-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Saans TRIAL";
    src: url("../assets/font/SaansTRIAL-SemiBold.eot");
    src: local("Saans TRIAL SemiBold"), local("SaansTRIAL-SemiBold"), url("../assets/font/SaansTRIAL-SemiBold.eot?#iefix") format("embedded-opentype"),
        url("../assets/font/SaansTRIAL-SemiBold.woff2") format("woff2"), url("../assets/font/SaansTRIAL-SemiBold.woff") format("woff"),
        url("../assets/font/SaansTRIAL-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Saans TRIAL";
    src: url("../assets/font/SaansTRIAL-Medium.eot");
    src: local("Saans TRIAL Medium"), local("SaansTRIAL-Medium"), url("../assets/font/SaansTRIAL-Medium.eot?#iefix") format("embedded-opentype"),
        url("../assets/font/SaansTRIAL-Medium.woff2") format("woff2"), url("../assets/font/SaansTRIAL-Medium.woff") format("woff"),
        url("../assets/font/SaansTRIAL-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Mardoto";
    src: url("../assets/font/Mardoto-Medium.woff2") format("woff2"), url("../assets/font/Mardoto-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

.navbar {
    position: fixed;
    top: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    margin: 20px auto 0 auto;
    padding: 20px 30px 20px 30px;
    border: 1px solid #E0E0E0;
    border-radius: 50px;
    max-height: 71px;
    max-width: 1920px;
    z-index: 999;
    font-family: "Saans TRIAL";
}

.navbar-logo {
    z-index: 10001;
}

.navbar-logo svg {
    display: block;
    width: 100%;
    height: auto;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -webkit-user-drag: none;
}

.navbar-links {
    display: flex;
    /* padding: 12px 29px;
    border: 1px solid #e6e6e6;
    border-radius: 30px;
    background-color: #f5f5f5b8; */
    /* backdrop-filter: blur(54px); */
    font-size: 21px;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
}

.navbar-links .nav-link {
    text-decoration: none;
    font-weight: 600;
    color: #8d8d8d;
    transition: color 0.5s ease;
    margin-right: 15px;
}

.nav-link:hover {
    color: black;
    transition: color 0.5s ease;
}

.navbar-links .active-link {
    color: black;
    transition: color 0.5s ease;
}

.navbar-links .nav-link:last-child {
    margin-right: 0;
}

/**/
.navbar-lang {
    position: relative;
    display: inline-block;
}

.lang-button {
    background-color: black;
    color: white;
    border-radius: 30px;
    padding: 14px 23px;
    font-weight: 600;
    font-size: 18px;
    border: 1px solid #e6e6e6;
    cursor: pointer;
    user-select: none;
    text-align: center;
    transition: border-radius 0.3s ease;
}

.lang-button.open {
    border-radius: 30px 30px 0 0;
    border-bottom: none;
}

.lang-button span {
    text-decoration: underline;
}

/* Dropdown */
.lang-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: black;
    color: white;
    border-top: none;
    border-left: 1px solid #e6e6e6;
    border-right: 1px solid #e6e6e6;
    border-bottom: 1px solid #e6e6e6;
    border-radius: 0 0 30px 30px;
    width: 100%;
    overflow: hidden;
    transition: max-height 0.3s ease, opacity 0.5s ease;
    opacity: 0;
    z-index: 1000;
}

.lang-dropdown.open {
    opacity: 1;
}

.lang-option {
    color: white;
    padding: 15px;
    text-align: center;
    cursor: pointer;
    position: relative;

    font-size: 18px;
    font-weight: 600;
    line-height: 20.43px;
}

.lang-option:first-child {
    border-top: none;
    padding: 0 15px 15px 15px;
}

.lang-option span {
    position: relative;
    display: inline-block;
    text-decoration: none;
    /* Remove default underline */
    cursor: pointer;
}

.lang-option span::after {
    content: "";
    /* Creates the underline effect */
    position: absolute;
    left: 0;
    bottom: 0;
    /* Position at the bottom of the text */
    width: 0;
    /* Start with no underline */
    height: 1px;
    /* Thickness of the underline */
    background-color: white;
    /* Underline color */
    transition: width 0.3s ease;
    /* Smooth animation */
}

.lang-option span:hover::after {
    width: 100%;
    /* Full underline on hover */
}

@media (min-width: 576px) and (max-width: 1920px) {
    .navbar {
        padding: 1.04vw 1.56vw 1.04vw 1.56vw;
        max-width: 100vw;
        margin: 1.04vw 2.08vw 0 2.08vw;
        border: 0.05vw solid #E0E0E0;
        border-radius: 2.6vw;
        max-height: 3.69vw;
        width: calc(100% - 4.16vw);
    }

    .navbar-links {
        display: flex;
        /* padding: 0.6vw 1.5vw;
        border-radius: 1.5vw;
        backdrop-filter: blur(2.8vw); */
        border: none;
        font-size: 1vw;
    }

    .navbar-logo svg {
        width: 6.1vw;
        height: auto;
    }

    .navbar-links .nav-link {
        margin-right: 0.7vw;
    }

    .lang-button {
        border-radius: 1.5vw;
        padding: 0.7vw 1.19vw;
        font-size: 0.9vw;
    }

    .lang-button.open {
        border-radius: 1.5vw 1.5vw 0 0;
    }

    /* Dropdown */
    .lang-dropdown {
        border-radius: 0 0 1.5vw 1.5vw;
    }

    .lang-option {
        padding: 0.7vw;
        font-size: 0.9vw;
        line-height: 1vw;
    }

    .lang-option:first-child {
        padding: 0 0.7vw 0.7vw 0.7vw;
    }

    .lang-button {
        border: 0.05vw solid #e6e6e6;
    }
}

.menu-toggle,
.mobile-menu {
    display: none;
}

@media (max-width: 576px) {
    .desktop-navbar {
        display: none;
    }

    .navbar {
        position: fixed;
        top: 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 auto;
        padding: 20px;
        max-width: 576px;
        z-index: 999;
        background-color: #fff;
        border: none;
        border-radius: 0;
    }

    .navbar-logo svg {
        display: block;
        width: 100%;
        height: auto;
        max-height: 20px;
        user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;
        -webkit-user-drag: none;
    }

    .mobile-menu {
        position: fixed;
        top: 60px;
        left: 0;
        width: 100%;
        height: 100dvh;
        background-color: #fff;
        color: white;
        padding: 0 20px 0 20px;
        margin-bottom: 30px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        opacity: 0;
        transform: translateY(-100%);
        transition: transform 0.5s ease, opacity 0.5s ease, height 0.5s ease;
        z-index: 1000;
    }

    .mobile-menu.open {
        height: 100dvh;
        transform: translateY(0%);
        opacity: 1;
        transition: transform 0.5s ease, opacity 0.5s ease, height 0.5s ease;
    }

    /* Menu Button Base Styles */
    .menu-toggle {
        display: block;
        background: none;
        border: none;
        cursor: pointer;
        padding: 0;
        z-index: 1001;
    }

    .menu-icon {
        display: block;
        width: 25px;
        height: 19px;
        position: relative;
    }

    .line {
        transition: transform 0.5s ease, opacity 0.5s ease;
        transform-origin: center;
    }

    .line1 {
        transform: rotate(0deg);
    }

    .line2 {
        transform: rotate(0deg);
    }

    .menu-icon.open .line1 {
        transform: translateY(3px) rotate(45deg);
    }

    .menu-icon.open .line2 {
        transform: translateY(-5.5px) rotate(-45deg);
    }

    /*menu mobile container*/
    .mobile-menu .mobile-menu_container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-color: black;
        border-radius: 10px;
        width: 100%;
        height: 100%;
        padding: 50px 20px 30px 20px;
        margin-top: 30px;
        margin-bottom: 90px;
        opacity: 0;
        transition: opacity 0.5s ease;
        overflow-y: auto;
    }

    .mobile-menu.open .mobile-menu_container {
        opacity: 1;
        transition: opacity 1s ease;
        overflow-y: auto;
    }

    .navbar-links_mobile {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 100%;
    }

    .nav-link-mobile {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 31px;

        /*font*/
        font-size: 21px;
        font-weight: 400;
        line-height: 23.84px;
        text-align: left;
        color: #898989;
        transition: color 0.5s ease;
        text-decoration: none;
    }

    .nav-link-mobile.active-link-mobile {
        color: #fff;
        transition: color 0.5s ease;
    }

    .nav-link-mobile svg {
        width: 14px;
        height: 14px;
    }

    .navbar-footer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: end;
        width: 100%;
    }

    .navbar-footer_contactinfo {
        font-size: 16px;
        font-weight: 500;
        line-height: 18.16px;
        color: #cacaca;
        text-decoration: none;
    }

    .navbar-lang-mobile {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
    }

    .lang-button-mobile {
        padding: 10px 15px;
        border-radius: 30px;
        background: #ffffffe5;
        backdrop-filter: blur(54px);
        transition: border-radius 0.5s ease;
        cursor: pointer;
        user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;
        display: flex;
    }

    .lang-button-mobile span {
        font-size: 16px;
        font-weight: 400;
        line-height: 18.16px;
        text-decoration-line: underline;
        text-decoration-style: solid;
        color: #000;
    }

    .lang-dropdown-mobile {
        width: 0;
        min-width: 0;
        overflow: hidden;
        padding: 0;
        border-radius: 0;
        transition: min-width 0.5s ease-in-out, padding 0.5s ease-in-out, border-radius 0.5s ease-in-out;
        cursor: pointer;
        user-select: none;
        display: flex;
        flex-direction: row;
        position: relative; /* Necessary for pseudo-element positioning */
        z-index: 1; /* Ensure content is above the background */
    }

    .lang-dropdown-mobile.open {
        min-width: max-content;
        padding: 10px 15px 10px 0;
        border-radius: 0 30px 30px 0;
        backdrop-filter: blur(54px);
    }

    /* Pseudo-element for background effect */
    .lang-dropdown-mobile::before {
        content: ""; /* Create a pseudo-element */
        position: absolute;
        top: 0;
        left: 0;
        height: 100%; /* Full height of the parent */
        width: 0; /* Start with zero width */
        background: #ffffffe5; /* Background color for the effect */
        z-index: -1; /* Place it behind the content */
        transition: width 0.5s ease-in-out; /* Smooth animation */
        border-radius: 0 30px 30px 0; /* Match the parent radius */
    }

    .lang-dropdown-mobile.open::before {
        width: 100%; /* Expand to full width */
    }

    /* Button styles inside the dropdown */
    .lang-button-mobile.open {
        display: flex;
        border-radius: 30px 0 0 30px;
        transition: border-radius 0.3s ease;
    }

    /* Styling for text spans inside the dropdown */
    .lang-dropdown-mobile.open span:first-child {
        padding-right: 15px;
    }

    .lang-option-mobile {
        font-size: 16px;
        font-weight: 400;
        line-height: 18.16px;
        color: #000;
    }
}

@media (max-width: 575px) {
    .navbar {
        padding: 4.85vw;
        max-width: 139.81vw;
    }

    .navbar-logo svg {
        max-height: 4.85vw;
        max-width: 19.11vw;
    }

    .mobile-menu {
        top: 13.56vw;
        padding: 0 4.85vw 0 4.85vw;
        margin-bottom: 7.28vw;
    }

    .menu-icon {
        width: 6.07vw;
        height: 4.61vw;
    }

    /*menu mobile container*/
    .mobile-menu .mobile-menu_container {
        border-radius: 2.43vw;
        padding: 12.14vw 4.85vw 7.28vw 4.85vw;
        margin-top: 7.28vw;
        margin-bottom: 21.84vw;
    }

    .nav-link-mobile {
        margin-bottom: 7.52vw;
        /*font*/
        font-size: 5.1vw;
        line-height: 5.79vw;
    }

    .nav-link-mobile svg {
        width: 3.4vw;
        height: 3.4vw;
    }

    .navbar-footer_contactinfo {
        font-size: 3.88vw;
        line-height: 4.41vw;
    }

    .lang-button-mobile {
        padding: 2.43vw 3.64vw;
        border-radius: 7.28vw;
        backdrop-filter: blur(13.11vw);
    }

    .lang-button-mobile span {
        font-size: 3.88vw;
        line-height: 4.41vw;
    }

    .lang-dropdown-mobile.open {
        padding: 2.43vw 3.64vw 2.43vw 0;
        border-radius: 0 7.28vw 7.28vw 0;
        backdrop-filter: blur(13.11vw);
    }

    .lang-button-mobile.open {
        border-radius: 7.28vw 0 0 7.28vw;
    }

    .lang-dropdown-mobile.open span:first-child {
        padding-right: 0;
    }
    /* .lang-dropdown-mobile.open span:first-child {
        padding-right: 3.64vw;
    } */

    .lang-option-mobile {
        font-size: 3.88vw;
        line-height: 4.41vw;
    }
}

.navbar-logo {
    transition: opacity 0.3s ease, transform 0.3s ease;
}

.navbar-logo.hidden {
    opacity: 0;
    transform: translateY(-20px);
    pointer-events: none;
}

.navbar-logo.visible {
    opacity: 1;
    transform: translateY(0);
}

@media (max-width: 576px) {
    .navbar-logo.hidden {
        opacity: 1;
        transform: translateY(0);
        pointer-events: all;
    }

    .navbar-logo.visible {
        opacity: 1;
        transform: translateY(0);
    }
}
