@font-face {
    font-family: "Saans TRIAL";
    src: url("../../assets/font/SaansTRIAL-Bold.eot");
    src: local("Saans TRIAL Bold"), local("SaansTRIAL-Bold"), url("../../assets/font/SaansTRIAL-Bold.eot?#iefix") format("embedded-opentype"),
        url("../../assets/font/SaansTRIAL-Bold.woff2") format("woff2"), url("../../assets/font/SaansTRIAL-Bold.woff") format("woff"),
        url("../../assets/font/SaansTRIAL-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Saans TRIAL";
    src: url("../../assets/font/SaansTRIAL-SemiBold.eot");
    src: local("Saans TRIAL SemiBold"), local("SaansTRIAL-SemiBold"), url("../../assets/font/SaansTRIAL-SemiBold.eot?#iefix") format("embedded-opentype"),
        url("../../assets/font/SaansTRIAL-SemiBold.woff2") format("woff2"), url("../../assets/font/SaansTRIAL-SemiBold.woff") format("woff"),
        url("../../assets/font/SaansTRIAL-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Saans TRIAL";
    src: url("../../assets/font/SaansTRIAL-Medium.eot");
    src: local("Saans TRIAL Medium"), local("SaansTRIAL-Medium"), url("../../assets/font/SaansTRIAL-Medium.eot?#iefix") format("embedded-opentype"),
        url("../../assets/font/SaansTRIAL-Medium.woff2") format("woff2"), url("../../assets/font/SaansTRIAL-Medium.woff") format("woff"),
        url("../../assets/font/SaansTRIAL-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Saans TRIAL";
    src: url("../../assets/font/SaansTRIAL-Regular.eot");
    src: local("Saans TRIAL Regular"), local("SaansTRIAL-Regular"), url("../../assets/font/SaansTRIAL-Regular.eot?#iefix") format("embedded-opentype"),
        url("../../assets/font/SaansTRIAL-Regular.woff2") format("woff2"), url("../../assets/font/SaansTRIAL-Regular.woff") format("woff"),
        url("../../assets/font/SaansTRIAL-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Mardoto";
    src: url("../../assets/font/Mardoto-Medium.woff2") format("woff2"), url("../../assets/font/Mardoto-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

.legal {
    margin: 200px 70px 160px 70px;
    max-width: 1920px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    font-family: "Saans TRIAL";
}

.legal-header,
.legal-header_icons {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.legal-header {
    margin-bottom: 40px;
}

.legal-header span {
    font-size: 21px;
    font-weight: 600;
    line-height: 1.135;
    margin-right: 10px;
}

.legal-header_icons svg {
    animation: rotatePauseSvg 2.5s infinite;
}

@keyframes rotatePauseSvg {
    0% {
        transform: rotate(0);
    }
    20% {
        transform: rotate(0);
    }
    40% {
        transform: rotate(-90deg);
    }
    60% {
        transform: rotate(-90deg);
    }
    80% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(0);
    }
}

.legal-title {
    max-width: 60%;
    margin-bottom: 70px;
}
.legal-title h1 {
    font-size: 72px;
    font-weight: 500;
    line-height: 1.135;
}

.legal-description {
    max-width: 50%;
    margin-bottom: 40px;
}

.legal-description p {
    font-size: 24px;
    font-weight: 500;
    line-height: 1.135;
}

/* table */

.legal-table {
    display: flex;
    flex-direction: column;
    border-top: 1px solid #d9d9d9;
}

.legal-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #d9d9d9;
    padding: 30px 0;
}

.legal-doc-name {
    font-size: 21px;
    font-weight: 600;
    line-height: 23.84px;
    text-align: left;
    flex: 1;
}

.legal-actions {
    display: flex;
}

.legal-action-link:first-child {
    margin-right: 20px;
}

.legal-action-link {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    /**/
    color: #000;
    font-size: 18px;
    font-weight: 700;
    line-height: 20.43px;
    text-align: left;
    text-decoration-line: underline;
    text-decoration-style: solid;
}

.legal-action-link svg {
    width: 18px;
    height: 18px;
    margin-left: 5px;
}

@media (min-width: 576px) and (max-width: 1920px) {
    .legal {
        margin: 10.4vw 3.65vw 8.33vw 3.65vw;
        max-width: 100vw;
    }

    .legal-header {
        margin-bottom: 2.08vw;
    }

    .legal-header span {
        font-size: 1.09vw;
        margin-right: 0.52vw;
    }

    .legal-header_icons svg {
        width: 0.625vw;
        height: 0.625vw;
        animation: rotatePauseSvg 2.5s infinite;
    }

    .legal-title {
        margin-bottom: 3.64vw;
    }
    .legal-title h1 {
        font-size: 3.75vw;
    }

    .legal-description {
        margin-bottom: 2.08vw;
    }

    .legal-description p {
        font-size: 1.25vw;
    }

    /* table */

    .legal-table {
        border-top: 0.05vw solid #d9d9d9;
    }

    .legal-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 0.05vw solid #d9d9d9;
        padding: 1.56vw 0;
    }

    .legal-doc-name {
        font-size: 1.09vw;
        line-height: 1.135;
    }

    .legal-action-link:first-child {
        margin-right: 1.04vw;
    }

    .legal-action-link {
        font-size: 0.937vw;
        line-height: 1.135px;
    }

    .legal-action-link svg {
        width: 0.9375vw;
        height: 0.9375vw;
        margin-left: 0.26;
    }
}

@media (max-width: 576px) {
    .legal {
        margin: 24.27vw 4.85vw 24.27vw 4.85vw;
        max-width: 576px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }

    .legal-header,
    .legal-header_icons {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
    }

    .legal-header {
        margin-bottom: 7.28vw;
    }

    .legal-header span {
        font-size: 4.36vw;
        font-weight: 600;
        line-height: 1.135;
        margin-right: 2.42vw;
    }

    .legal-header_icons svg {
        width: 2.42vw;
        height: 2.2vw;
        animation: rotatePauseSvg 2.5s infinite;
    }

    @keyframes rotatePauseSvg {
        0% {
            transform: rotate(0);
        }
        20% {
            transform: rotate(0);
        }
        40% {
            transform: rotate(-90deg);
        }
        60% {
            transform: rotate(-90deg);
        }
        80% {
            transform: rotate(0);
        }
        100% {
            transform: rotate(0);
        }
    }

    .legal-title {
        max-width: 100%;
        margin-bottom: 9.7vw;
    }
    .legal-title h1 {
        font-size: 10.19vw;
        font-weight: 500;
        line-height: 1.135;
    }

    .legal-description {
        max-width: 100%;
        margin-bottom: 4.85vw;
    }
    .legal-description p {
        font-size: 5vw;
        font-weight: 500;
        line-height: 1.135;
    }

    /* table */

    .legal-table {
        display: flex;
        flex-direction: column;
        border-top: 0.24vw solid #d9d9d9;
    }

    .legal-row {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        border-bottom: 0.24vw solid #d9d9d9;
        padding: 7.28vw 0;
    }

    .legal-doc-name {
        font-size: 4.36vw;
        font-weight: 600;
        line-height: 1.135;
        text-align: left;
        flex: 1;
    }

    .legal-actions {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        margin-top: 4.85vw;
    }

    .legal-action-link:first-child {
        margin-right: 4.85vw;
    }

    .legal-action-link {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        /**/
        color: #000;
        font-size: 4.36vw;
        font-weight: 700;
        line-height: 1.135;
        text-align: left;
        text-decoration-line: underline;
        text-decoration-style: solid;
    }

    .legal-action-link svg {
        width: 18px;
        height: 18px;
        margin-left: 5px;
    }
}
