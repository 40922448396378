@font-face {
    font-family: "Saans TRIAL";
    src: url("../../assets/font/SaansTRIAL-Bold.eot");
    src: local("Saans TRIAL Bold"), local("SaansTRIAL-Bold"), url("../../assets/font/SaansTRIAL-Bold.eot?#iefix") format("embedded-opentype"),
        url("../../assets/font/SaansTRIAL-Bold.woff2") format("woff2"), url("../../assets/font/SaansTRIAL-Bold.woff") format("woff"),
        url("../../assets/font/SaansTRIAL-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Saans TRIAL";
    src: url("../../assets/font/SaansTRIAL-SemiBold.eot");
    src: local("Saans TRIAL SemiBold"), local("SaansTRIAL-SemiBold"), url("../../assets/font/SaansTRIAL-SemiBold.eot?#iefix") format("embedded-opentype"),
        url("../../assets/font/SaansTRIAL-SemiBold.woff2") format("woff2"), url("../../assets/font/SaansTRIAL-SemiBold.woff") format("woff"),
        url("../../assets/font/SaansTRIAL-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Saans TRIAL";
    src: url("../../assets/font/SaansTRIAL-Medium.eot");
    src: local("Saans TRIAL Medium"), local("SaansTRIAL-Medium"), url("../../assets/font/SaansTRIAL-Medium.eot?#iefix") format("embedded-opentype"),
        url("../../assets/font/SaansTRIAL-Medium.woff2") format("woff2"), url("../../assets/font/SaansTRIAL-Medium.woff") format("woff"),
        url("../../assets/font/SaansTRIAL-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Saans TRIAL";
    src: url("../../assets/font/SaansTRIAL-Regular.eot");
    src: local("Saans TRIAL Regular"), local("SaansTRIAL-Regular"), url("../../assets/font/SaansTRIAL-Regular.eot?#iefix") format("embedded-opentype"),
        url("../../assets/font/SaansTRIAL-Regular.woff2") format("woff2"), url("../../assets/font/SaansTRIAL-Regular.woff") format("woff"),
        url("../../assets/font/SaansTRIAL-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Mardoto";
    src: url("../../assets/font/Mardoto-Medium.woff2") format("woff2"), url("../../assets/font/Mardoto-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

.reports {
    margin: 200px 70px 160px 70px;
    max-width: 1920px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    font-family: "Saans TRIAL";
}

.reports-header,
.reports-header_icons {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.reports-header {
    margin-bottom: 40px;
}

.reports-header span {
    font-size: 21px;
    font-weight: 600;
    line-height: 1.135;
    margin-right: 10px;
}

.reports-header_icons svg {
    animation: rotatePauseSvg 2.5s infinite;
}

@keyframes rotatePauseSvg {
    0% {
        transform: rotate(0);
    }
    20% {
        transform: rotate(0);
    }
    40% {
        transform: rotate(-90deg);
    }
    60% {
        transform: rotate(-90deg);
    }
    80% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(0);
    }
}

.reports-title {
    max-width: 60%;
    margin-bottom: 20px;
}
.reports-title h1 {
    font-size: 72px;
    font-weight: 500;
    line-height: 1.135;
}

/*slider*/

.reports-slider {
    position: relative;
}

.reportsSlider {
    margin-top: 40px;
}

/*slider cards*/
.reports-card {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: #f2f2f2;
    padding: 20px;
    width: 100%;
    /* maybe will change back*/
    /* height: 586px; */
    height: auto;
    /**/
    border-radius: 10px;
}

.reports-card_date {
    font-size: 16px;
    font-weight: 500;
    line-height: 18.16px;
    text-align: left;
}

.reports-card_title {
    font-size: 24px;
    font-weight: 500;
    line-height: 27.25px;
    text-align: left;
    margin-top: 10px;
}

.reports-card_image {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 329px;
    margin: 31px 0 33px 0;
}

.reports-card_image img {
    object-fit: contain;
    width: 100%;
}

.reports-card_content {
    font-size: 16px;
    font-weight: 400;
    line-height: 18.16px;
    text-align: left;
}

.reports-card_pdf {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    /**/
    color: #000;
    font-size: 16px;
    font-weight: 500;
    line-height: 18.16px;
    text-align: left;
}

.reports-card_pdf svg {
    width: 24px;
    height: 24px;
}

/*slide navigation buttons*/
.reports-navigation_container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

.reports-navigation_container .reports-arrow:first-child {
    margin-right: 20px;
}

.reports-arrow {
    position: relative;
    width: 42px;
    height: 42px;
    border: 1px solid #000000;
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;
    transition: transform 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    z-index: 10;
}

.reports-arrow::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #e2e2e2;
    top: 100%; /* Start below the button */
    left: 0;
    transition: top 0.5s ease;
    z-index: 0;
}

.reports-arrow:hover::before {
    top: 0;
}

.svg-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.5s ease;
}

.reports-arrow.reports-left-arrow:hover .svg-wrapper {
    transform: translateX(-18px);
}
.reports-arrow.reports-left-arrow .svg-wrapper {
    transform: translateX(18px);
}

.reports-arrow.reports-right-arrow:hover .svg-wrapper {
    transform: translateX(18px);
}

.reports-arrow.reports-right-arrow .svg-wrapper {
    transform: translateX(-18px);
}

.reports-arrow.reports-left-arrow .svg-wrapper:first-child {
    margin-right: 20px;
}
.reports-arrow.reports-right-arrow .svg-wrapper:last-child {
    margin-left: 20px;
}

@media (min-width: 576px) and (max-width: 1920px) {
    .reports {
        margin: 10.4vw 3.65vw 8.33vw 3.65vw;
        max-width: 100vw;
    }

    .reports-header {
        margin-bottom: 2.08vw;
    }

    .reports-header span {
        font-size: 1.09vw;
        margin-right: 0.52vw;
    }

    .reports-header_icons svg {
        width: 0.625vw;
        height: 0.625vw;
        animation: rotatePauseSvg 2.5s infinite;
    }

    .reports-title {
        margin-bottom: 1.04vw;
    }
    .reports-title h1 {
        font-size: 3.75vw;
    }

    /*slider*/
    .reportsSlider {
        margin-top: 2.08vw;
    }

    /*slider cards*/
    .reports-card {
        padding: 1.04vw;
        /* maybe will change back*/
        /* height: 30.5vw; */
        height: auto;
        /**/
        border-radius: 0.52vw;
    }

    .reports-card_date {
        font-size: 0.83vw;
        line-height: 0.94vw;
    }

    .reports-card_title {
        font-size: 1.25vw;
        line-height: 1.419vw;
        margin-top: 0.52vw;
    }

    .reports-card_image {
        height: 17.13vw;
        margin: 1.61vw 0 1.71vw 0;
    }

    .reports-card_content {
        font-size: 0.83vw;
        line-height: 0.94vw;
    }

    .reports-card_pdf {
        margin-top: 1.04vw;
        /**/
        font-size: 0.83vw;
        line-height: 0.94vw;
    }

    .reports-card_pdf svg {
        width: 1.25vw;
        height: 1.25vw;
    }

    /*slide navigation buttons*/
    .reports-navigation_container .reports-arrow:first-child {
        margin-right: 1.04vw;
    }

    .reports-navigation_container svg {
        width: 0.93vw;
        height: 0.93vw;
    }

    .reports-arrow {
        width: 2.19vw;
        height: 2.19vw;
        border: 0.05vw solid #000;
    }

    .reports-arrow.left-reports-arrow:hover .svg-wrapper {
        transform: translateX(-0.94vw);
    }

    .reports-arrow.reports-left-arrow .svg-wrapper {
        transform: translateX(0.94vw);
    }

    .reports-arrow.reports-right-arrow:hover .svg-wrapper {
        transform: translateX(0.94vw);
    }

    .reports-arrow.reports-right-arrow .svg-wrapper {
        transform: translateX(-0.94vw);
    }

    .reports-arrow.reports-left-arrow .svg-wrapper:first-child {
        margin-right: 1.04vw;
    }
    .reports-arrow.reports-right-arrow .svg-wrapper:last-child {
        margin-left: 1.04vw;
    }
}

@media (max-width: 576px) {
    .reports {
        margin: 24.27vw 4.85vw 24.27vw 4.85vw;
        max-width: 576px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }

    .reports-header,
    .reports-header_icons {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
    }

    .reports-header {
        margin-bottom: 7.28vw;
    }

    .reports-header span {
        font-size: 4.36vw;
        font-weight: 600;
        line-height: 1.135;
        margin-right: 2.42vw;
    }

    .reports-header_icons svg {
        width: 2.42vw;
        height: 2.2vw;
        animation: rotatePauseSvg 2.5s infinite;
    }

    @keyframes rotatePauseSvg {
        0% {
            transform: rotate(0);
        }
        20% {
            transform: rotate(0);
        }
        40% {
            transform: rotate(-90deg);
        }
        60% {
            transform: rotate(-90deg);
        }
        80% {
            transform: rotate(0);
        }
        100% {
            transform: rotate(0);
        }
    }

    .reports-title {
        max-width: 100%;
        margin-bottom: 9.7vw;
    }
    .reports-title h1 {
        font-size: 10.19vw;
        font-weight: 500;
        line-height: 1.135;
    }

    /*slider*/

    .reports-slider {
        position: relative;
    }

    .reportsSlider {
        margin-top: 4.85vw;
    }

    /*slider cards*/
    .reports-card {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        background-color: #f2f2f2;
        padding: 4.85vw;
        width: 100%;
        height: 126.21vw;
        border-radius: 2.42vw;
    }

    .reports-card_date {
        font-size: 3.88vw;
        font-weight: 500;
        line-height: 4.4vw;
        text-align: left;
    }

    .reports-card_title {
        font-size: 4.85vw;
        font-weight: 500;
        line-height: 5.51vw;
        text-align: left;
        margin-top: 2.42vw;
    }

    .reports-card_image {
        display: block;
        height: 100%;
        margin: 4.85vw 0 4.85vw 0;
    }

    .reports-card_content {
        font-size: 3.88vw;
        font-weight: 400;
        line-height: 4.4vw;
        text-align: left;
    }

    .reports-card_pdf {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-top: 4.85vw;
        /**/
        color: #000;
        font-size: 3.88vw;
        font-weight: 500;
        line-height: 4.4vw;
        text-align: left;
    }

    .reports-card_pdf svg {
        width: 4.85vw;
        height: 4.85vw;
    }

    /*slide navigation buttons*/
    .reports-navigation_container .reports-arrow:first-child {
        margin-right: 4.85vw;
    }

    .reports-navigation_container svg {
        width: 3.39vw;
        height: 3.39vw;
    }

    .reports-arrow {
        width: 7.76vw;
        height: 7.76vw;
        border: 0.24vw solid #000;
    }

    .reports-arrow.left-reports-arrow:hover .svg-wrapper {
        transform: translateX(-4vw);
    }

    .reports-arrow.reports-left-arrow .svg-wrapper {
        transform: translateX(4vw);
    }

    .reports-arrow.reports-right-arrow:hover .svg-wrapper {
        transform: translateX(4vw);
    }

    .reports-arrow.reports-right-arrow .svg-wrapper {
        transform: translateX(-4vw);
    }

    .reports-arrow.reports-left-arrow .svg-wrapper:first-child {
        margin-right: 4.85vw;
    }
    .reports-arrow.reports-right-arrow .svg-wrapper:last-child {
        margin-left: 4.85vw;
    }
}
